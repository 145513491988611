import React, { Fragment, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getPlaylist, savePlaylist } from "../../redux/apislice/playListSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function SavePlaylist({ title, onClose, videoId }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [playListId, setPlayListId] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const playlist = useSelector((state) => state.playlist);
    const { playList } = playlist
    const userId = Cookies.get("userId");


    const handleSavePlaylist = () => {
        if (playListId && videoId) {
            dispatch(savePlaylist({ playlist_id: +playListId, video_id: videoId, position: 0 })).then(() => {
                setIsOpen(false);
                setTimeout(onClose, 500);
                onClose()
            })
        }
    }
 

    const handleSelectChange = (e) => {
        setPlayListId(e.target.value);
    };

    useEffect(() => {
        setIsOpen(true);
    }, []);

    useEffect(() => {
        dispatch(getPlaylist(userId))
    }, [dispatch, userId])


    return (
        <Fragment>
            <div className="relative h-full flex justify-center items-center w-full">
                <div className={`absolute opacity-15 flex justify-center bg-black items-center h-full w-full `} onClick={() => {
                    setIsOpen(false);
                    setTimeout(onClose, 500);
                }}>
                </div>
                <div className={`text-black h-40 w-3/4 bg-white rounded-md p-2 transition-transform duration-500  ${isOpen ? "translate-x-0" : "translate-x-full"
                    }`}>
                    <div className="flex justify-between items-center border-b-[0.4px] border-[#474747]">
                        <p className="text-black text-[16px] md:text-[14px] line-clamp-1">
                            {title}
                        </p>
                        <IoClose className="text-black text-[24px] cursor-pointer" onClick={() => {
                            setIsOpen(false);
                            setTimeout(onClose, 500);
                        }} />
                    </div>

                    <div className="flex flex-col justify-between gap-2 py-4">
                        <div className="flex items-center justify-between">
                            <p className="text-[16px] md:text-[14px] font-normal">
                                Save to Playlist
                            </p>
                            <button className="text-[10px] text-light-blue-600 font-bold hover:text-light-blue-500 cursor-pointer" onClick={() => navigate("/createplaylist")}>Create New</button>
                        </div>
                        <div className="relative w-full">
                            <select
                                required
                                value={playListId}
                                style={{ maxHeight: '150px', overflowY: 'auto' }}
                                onChange={handleSelectChange}
                                className="w-full bg-[black]  px-2 py-[8px] md:py-[6px] optional:h-4 optional:overflow-y-scroll text-white placeholder:text-[17px] md:placeholder:text-[15px]  border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#bccbbc] appearance-none focus:border-transparent">
                                {playList?.results?.length === 0 ? (
                                    <option value={0}>No Playlist Found</option>
                                ) : (
                                    <>
                                        <option selected>Select Playlist</option>
                                        {playList?.results?.length && playList?.results?.map((item) => (
                                            <option value={item.id}>
                                                {item.title}
                                            </option>
                                        ))}
                                    </>
                                )}
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-black">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                        <div className="flex items-center justify-center gap-10 mt-[2px]"
                        >
                            <button className="text-[16px] md:text-[14px] hover:bg-gray-400 text-white bg-gray-700 rounded-lg px-6 py-[2px]  hover:text-black cursor-pointer"

                                onClick={() => {
                                    setIsOpen(false);
                                    setTimeout(onClose, 500)
                                }}>
                                Close
                            </button>
                            <button onClick={handleSavePlaylist} className="text-[16px] md:text-[14px]   hover:bg-gray-400 hover:text-black text-white bg-black rounded-lg px-6 py-[2px] cursor-pointer">
                                Save
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment >
    )
}
