import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createPodcastComment = createAsyncThunk(
    "createComment",
    async ({ data, podcast_id }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/podcasts/${podcast_id}/comments/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getPodcastComment = createAsyncThunk(
    "getPodcastComment",
    async (podcast_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/podcast/all_comment/${podcast_id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updatePodcastComment = createAsyncThunk(
    "updatePodcastComment",
    async ({ commentId, data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.patch(`${mainUrl}api/podcast/comments/${commentId}/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const deletePodcastComment = createAsyncThunk(
    "deletePodcastComment",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/podcast/comments/${commentId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const likePodcastComment = createAsyncThunk(
    "likePodcastComment",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/comments/${commentId}/like/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const dislikePodcastComment = createAsyncThunk(
    "dislikePodcastComment",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/comments/${commentId}/dislike/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const podcastCommentSlice = createSlice({
    name: "podcastSlice",
    initialState: {
        commentLoading: false,
        podcastComments: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create post
        builder.addCase(createPodcastComment.pending, (state) => {
            state.commentLoading = true;
            state.error = null;
        });
        builder.addCase(createPodcastComment.fulfilled, (state, action) => {
            state.commentLoading = false;
            state.error = null;
        });
        builder.addCase(createPodcastComment.rejected, (state) => {
            state.commentLoading = false;
            state.error = true;
        });
        // Get all  podcast
        builder.addCase(getPodcastComment.pending, (state) => {
            state.commentLoading = true;
            state.error = null;
        });
        builder.addCase(getPodcastComment.fulfilled, (state, action) => {
            state.commentLoading = false;
            state.podcastComments = action.payload;
            state.error = null;
        });
        builder.addCase(getPodcastComment.rejected, (state) => {
            state.commentLoading = false;
            state.error = true;
        });
        // delete all  podcast
        builder.addCase(deletePodcastComment.pending, (state) => {
            state.commentLoading = true;
            state.error = null;
        });
        builder.addCase(deletePodcastComment.fulfilled, (state, action) => {
            state.commentLoading = false;
            state.error = null;
        });
        builder.addCase(deletePodcastComment.rejected, (state) => {
            state.commentLoading = false;
            state.error = true;
        });

    }
});

export default podcastCommentSlice.reducer;