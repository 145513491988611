import axios from "axios";
import getAccessConfig from "../../config";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const saveClips = createAsyncThunk(
    "shareClips",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/save_clip/${clipId}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const getSavedClips = createAsyncThunk(
    "getSavedClips",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/saved_clips/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeSavedClips = createAsyncThunk(
    "removeSavedClips",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/clips/remove_saved_clip/${clipId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const saveClipsSlice = createSlice({
    name: "saveClipsSlice",
    initialState: {
        loading: false,
        savedData: [],
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(saveClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(saveClips.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(saveClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(getSavedClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getSavedClips.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.savedData = action.payload;
        });
        builder.addCase(getSavedClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        builder.addCase(removeSavedClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(removeSavedClips.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(removeSavedClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default saveClipsSlice.reducer;