import React from "react";
import { IoClose } from "react-icons/io5";

const Report = ({
   selectedReports,
   setSelectedReports,
   setIsReported,
   handleReport,
   isReported
}) => {
   const handleReportSubmit = () => {
      setIsReported(true);
   }

   const handleCheckboxChange = (reportType) => {
      setSelectedReports((prev) =>
         prev.includes(reportType)
            ? prev.filter((item) => item !== reportType)
            : [...prev, reportType]
      );
   };

   return (
      <div className={`absolute w-full overflow-y-scroll bottom-[7.1rem] 
         ${isReported ? "h-[22vh]" : "h-[42vh]"} bg-black rounded-t-2xl`}
      >
         <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
            <p className="text-white text-[14px]">Report</p>
            <IoClose
               onClick={handleReport}
               className="text-white text-[20px] cursor-pointer"
            />
         </div>
         {!isReported ? (
            <div className="mt-2 px-2 text-white">
               {["Inappropriate Content",
                  "Spam or Advertising",
                  "Harassment or Hate Speech",
                  "False Information",
                  "Adult Content",
                  "Against Children",
                  "Legal Related",
                  "Support terrorist activities"
               ].map((option) => (
                  <label key={option} className="flex items-center text-[13px] mb-2">
                     <input
                        type="checkbox"
                        className="mr-2 accent-light-blue-600 focus:accent-light-blue-800 cursor-pointer"
                        onChange={() => handleCheckboxChange(option)}
                        checked={selectedReports.includes(option)}
                     />
                     {option}
                  </label>
               ))}
            </div>
         ) : (
            <div className="px-2 my-2 flex flex-col items-center justify-center">
               <p className="text-[25px] uppercase font-bold text-white">
                  Thank You
               </p>
               <p className="text-[14px] lowercase font-thin text-white">
                  Your message has been submitted
               </p>
            </div>
         )}
         <div className="flex items-center justify-center my-3">
            <button
               onClick={isReported ? handleReport : handleReportSubmit}
               disabled={!isReported && selectedReports.length === 0}
               className={`px-3 py-1 text-[12px] rounded-full 
                  ${selectedReports.length === 0 && !isReported ? "bg-gray-500" : "bg-[#ebebeb] hover:bg-[#ffffff]"}`
               }
            >
               {isReported ? "Close" : "Submit"}
            </button>
         </div>
      </div>
   )
}

export default Report
