import React from "react"

const RelatedVideos = ({
    relatedVideoData,
    navigate,
    handleToggle,
    formatTime,
    setSelectedVideoId
}) => {
    return (
        <div className="pt-2">
            {relatedVideoData?.map((data) => (
                <div className="pb-2" key={data.id}>
                    <img
                        alt="thumbnail"
                        src={data.thumbnail}
                        onClick={() => navigate(`/video/${data.id}`)}
                        className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                    />
                    <div className="grid grid-cols-12 mt-1 px-2">
                        <div className="col-span-2">
                            <img
                                alt="profile"
                                className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                src={data.author.profile_picture}
                            />
                            <div className="flex flex-col items-center justify-start ml-[-12px]">
                                <p className="text-white text-[13px]">{data.views_count}</p>
                                <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                            </div>
                        </div>
                        <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                            <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            <p className="text-[#8d8d8d] text-[12px]">{data.author.handle} - {formatTime(data.updated_at)}</p>
                        </div>
                        <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                            <p
                                onClick={() => {
                                    handleToggle(data?.id);
                                    setSelectedVideoId(data?.id)
                                }}
                                className="text-white text-[14px] cursor-pointer"
                            >
                                : :
                            </p>

                        </div>
                    </div>
                </div>
            ))}
            {relatedVideoData?.length === 0 && (
                <p className="text-[#4d4d4d] px-1 text-[14px] mt-2 flex justify-center items-center">No Related Videos</p>)}
        </div>
    )
}

export default RelatedVideos