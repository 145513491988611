import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL
// addWatchList list api
export const addWatchList = createAsyncThunk(
    "addWatchList",
    async (postId) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/video/watch-later/add/${postId}/`, {}, config);
        const response = await request.data;
        return response;
    }
);
export const getWatchList = createAsyncThunk(
    "getWatchList",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/video/watch-later/`, config);
        const response = await request.data;
        return response;
    }
);
export const removeWatchLaterVideo = createAsyncThunk(
    "removeWatchLaterVideo",
    async (postId) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/video/watch-later/remove/${postId}/`, config);
        const response = await request.data;
        return response;
    }
);

const watchLaterListSlice = createSlice({
    name: "watchLaterList",
    initialState: {
        isLoading: false,
        watchLaterData: [],
        error: null,
    },
    extraReducers: (builder) => {
        //add watch later
        builder.addCase(addWatchList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(addWatchList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(addWatchList.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //get watch later list
        builder.addCase(getWatchList.pending, (state) => {
            state.isLoading = true;
            state.error = false
        });
        builder.addCase(getWatchList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.watchLaterData = action.payload;
            state.error = null;
        });
        builder.addCase(getWatchList.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
        //remove watch later list
        builder.addCase(removeWatchLaterVideo.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(removeWatchLaterVideo.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(removeWatchLaterVideo.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export default watchLaterListSlice.reducer;
