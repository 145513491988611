import React, { useState } from "react";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { recoveryPass } from "../redux/apislice/recoveryPasswordSlice";
import { useDispatch, useSelector } from "react-redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const RecoverPass = () => {
    const lable = "KV / FE / RECOVER PASSWORD / V1 / 27 July, 2024";
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.recoveryPass);

    const navigate = useNavigate();

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await dispatch(recoveryPass({ email }))
            if (res.payload) {
                toast.success(res.payload.detail)
                const engryptedEmail = encryptData(email);
                Cookies.set("email_id", engryptedEmail);
                setTimeout(() => {
                    navigate("/enterotp")
                }, [2000])
            } else {
                toast.error(res?.error)
            }
        } catch (error) {
            toast.error("Email not found")
        }
    }

    // Encrypt data using AES encryption
    const encryptData = (data) => {
        return CryptoJS.AES.encrypt(data, "secret_key").toString();
    }



    return (
        <div className="h-full bg-[#2C672E] relative">
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>

            <h1 className="px-3 flex justify-between text-[3rem] font-semibold uppercase text-white">
                <p>
                    kv
                </p>
                <p>
                    {loading &&
                        <ScaleLoader
                            color={"#fff"}
                            loading={loading}
                            size={6}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />}
                </p>
            </h1>

            <form className="px-6 mt-14" onSubmit={handleSubmit} >
                <p className="text-white font-semibold text-[28px] md:text-[24px] text-center">
                    Recover Password
                </p>
                <div className="mt-4">
                    <label className="block text-[#D9D9D9] font-normal text-[20px] md:text-[14px]" htmlFor="email">
                        Email
                    </label>
                    <input
                        required
                        value={email}
                        onChange={handleEmail}
                        type="email"
                        placeholder="Enter your email address"
                        className="w-full px-3 py-3 md:py-2 mt-1 text-[18px] md:text-[14px] text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                    />
                </div>
                <div className="mt-2 flex items-center justify-end">
                    <p className="cursor-pointer text-[16px] md:text-[12px] text-[#0DD315] font-semibold">
                        Lost my mail
                    </p>
                </div>
                <div className="flex items-center justify-between mt-2">
                    <div
                        onClick={() => navigate(-1)}
                        className="w-[6rem] md:w-[5rem] cursor-pointer text-center py-[4px] font-semibold text-[16px] md:text-[12px] text-white bg-[#0DD315] rounded-md focus:outline-none"
                    >
                        Back
                    </div>
                    <button
                        disabled={loading}
                        type="submit"
                        className="w-[6rem] md:w-[5rem] disabled:bg-[rgb(134,227,138)] cursor-pointer text-center py-[4px] font-semibold text-[16px] md:text-[12px] text-white bg-[#0DD315] rounded-md focus:outline-none"
                    >
                        Send OTP
                    </button>
                </div>
                <div className="flex items-center justify-start gap-1 mt-4">
                    <p className="text-[16px] md:text-[12px] text-white">
                        Can’t Login?
                    </p>
                    <p className="text-[#0DD315] text-[16px] md:text-[12px] font-bold cursor-pointer" onClick={() => navigate("/gethelp")} >Get help</p>
                </div>
            </form>

            <div className="absolute bottom-[10rem] w-full px-14">
                <div className="flex items-center justify-evenly">
                    <div className="w-8 bg-black h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                    <div className="w-8 bg-white h-[3px]" />
                </div>
            </div>
        </div>
    )
}

export default RecoverPass