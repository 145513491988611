import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL;

export const fetchLikedVideos = createAsyncThunk(
    "fetchLikedVideos",
    async (_) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/videos/-------/`, config);
        const response = await request.data;
        return response;
    }
);


const videoDetailsSlice = createSlice({
    name: "videoDetailsSlice",
    initialState: {
        isLoading: false,
        LikedVIdeos: null,
        error: null,
    },
    extraReducers: (builder) => {
        //videoListSlice
        builder.addCase(fetchLikedVideos.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchLikedVideos.fulfilled, (state, action) => {
            state.isLoading = false
            state.LikedVIdeos = action.payload;
            state.error = null;
        });
        builder.addCase(fetchLikedVideos.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });

    }
});

export default videoDetailsSlice.reducer;
