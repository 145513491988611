import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoClose, IoSend } from "react-icons/io5";
import { IoMdHeart, IoMdHeartDislike } from "react-icons/io";
import { createComment, deleteCommentsById, getCommentReply, getCommentsById, createCommentReply, updateCommentsById, deleteCommentReply, updateCommentReply } from "../../redux/apislice/clips/clipsCommentSlice";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import Cookies from "js-cookie";
import { createCommentDislike, createCommentLike, createCommentRemoveDislike, createCommentUnlike } from "../../redux/apislice/clips/clipsCommentLikeSlice";

const Comment = ({ handleComment, clipsId }) => {
    const dispatch = useDispatch();
    const [openReplyId, setOpenReplyId] = useState(null);
    const [comment, setComment] = useState("");
    const { loading, commentsData, isReplyLoading, replyData } = useSelector((state) => state.clipsComment);
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile
    const userId = Cookies.get("userId");
    const [reply, setReply] = useState("");
    const [commentId, setCommentId] = useState(null);
    const [replyInput, setReplyInput] = useState(null);
    const [commentReply, setCommentReply] = useState(null);
    const [replyUpdate, setReplyUpdate] = useState(null);
    const [updateComment, setUpdateComment] = useState(false);
    const [updateReply, setUpdateReply] = useState(false);
    const [replyId, setReplyId] = useState(null);
    const [commentLikes, setCommentLikes] = useState([])
    const [commentDislikes, setCommentDislikes] = useState([])
    const replyMenuRef = useRef()

    const toggleReplies = async (commentId) => {
        await dispatch(getCommentReply(commentId))
        setOpenReplyId(commentId);
    };
    const close = () => {
        setCommentReply(null)
        setReplyInput(null)
        setUpdateComment(false)
        setCommentId(null)
        setComment('')
        setReply('')
        setUpdateReply(null)
    }

    const handleReply = (id) => {
        setReplyInput(true);
        setUpdateReply(false)
        setCommentId(id)
    }

    const handleUpdateComment = (id) => {
        setReplyInput(false)
        setUpdateComment(true)
        setCommentId(id)
        setComment(commentsData?.filter((data) => data.id === id)[0].body)
    }

    const handleUpdateCommentReply = async (id) => {
        setReplyInput(true)
        setUpdateReply(true)
        setUpdateComment(false)
        setReplyId(id)
        setReply(replyData?.filter((data) => data.id === id)[0].body)
    }

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (updateComment) {
            return handleUpdateCommentSubmit(commentId)
        }
        if (comment.trim()) {
            const result = await dispatch(createComment({ clipId: clipsId, body: comment }));
            if (result.payload) {
                dispatch(getCommentsById(clipsId))
                setComment("");
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleCommentSubmit(e);
        }

    };

    const handleKeyPressReply = (e) => {
        if (e.key === 'Enter') {
            handleCreateCommentReply(e);
        }

    };
    const fetchComments = useCallback(async () => {
        await dispatch(getCommentsById(clipsId));
    }, [dispatch, clipsId]);

    const handleDeleteComment = async (commentId) => {
        await dispatch(deleteCommentsById(commentId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                dispatch(getCommentsById(clipsId));
                setOpenReplyId(null)
                setCommentReply(null)
                setUpdateComment(false)
                setReplyInput(false)
            } else {
                console.log("Error Deleting Comment")
            }
        })

    }

    const handleUpdateCommentSubmit = async (commentId) => {
        await dispatch(updateCommentsById({ commentId, body: comment })).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                dispatch(getCommentsById(clipsId));
                setUpdateComment(false)
                setComment('')
                setCommentId(null)
            } else {
                console.log("Error Deleting Comment")
            }
        })

    }

    const handleCreateCommentReply = async (e) => {
        e.preventDefault();
        if (reply.trim()) {
            const result = await dispatch(createCommentReply({ commentId, body: reply }));
            if (result.payload) {
                dispatch(getCommentReply(commentId))
                setReply("");
                setReplyInput(false)
            }
        }
    };

    const handleDeleteCommentReply = async (replyId) => {
        await dispatch(deleteCommentReply(replyId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                dispatch(getCommentReply(openReplyId));
            } else {
                console.log("Error Deleting Comment")
            }
        })
    }

    const handleUpdateCommentReplySubmit = async () => {
        await dispatch(updateCommentReply({ replyId, body: reply })).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                dispatch(getCommentReply(openReplyId));
                setReply("");
                setUpdateReply(false)
                setReplyInput(false)
            } else {
                console.log("Error Deleting Comment")
            }
        })

    }

    // comment like and dislike section

    const handleCommentLike = async (commentId) => {
        await dispatch(createCommentLike(commentId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
            } else {
                console.log("Error Deleting Comment")
            }
        })
    }
    const handleCommentUnlike = async (commentId) => {
        await dispatch(createCommentUnlike(commentId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
            } else {
                console.log("Error Deleting Comment")
            }
        })
    }

    const handleCommentDislike = async (commentId) => {
        await dispatch(createCommentDislike(commentId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
            } else {
                console.log("Error Deleting Comment")
            }
        })
    }

    const handleCommentRemoveDislike = async (commentId) => {
        await dispatch(createCommentRemoveDislike(commentId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
            } else {
                console.log("Error Deleting Comment")
            }
        })
    }

    // const handlegetLikesComment = async (id) => {
    //     dispatch(getCommentlikes(id)).then((result) => {
    //         if (result.meta.requestStatus === "fulfilled") {
    //         }
    //     })
    // }

    const handleLike = (id) => {
        if (commentLikes.includes(id)) {
            setCommentLikes((prev) => prev.filter((item) => item !== id))
            handleCommentUnlike(id)
        } else {
            setCommentLikes((prev) => ([...prev, id]))
            handleCommentLike(id)
        }
    }

    const handleDislike = (id) => {
        if (commentDislikes.includes(id)) {
            setCommentDislikes((prev) => prev.filter((item) => item !== id))
            handleCommentRemoveDislike(id)
        } else {
            setCommentDislikes((prev) => ([...prev, id]))
            handleCommentDislike(id)
        }
    }


    useEffect(() => {
        fetchComments()
    }, [fetchComments])

    useEffect(() => {
        if (commentsData?.length) {
            const replyComment = commentsData?.filter((data) => data.id === commentId)
            const { created_by, body } = replyComment[0] || {}
            setCommentReply({ created_by, body })
        }
    }, [commentId, commentsData])

    useEffect(() => {
        if (replyData?.length) {
            const updateReplyComment = replyData?.filter((data) => data.id === replyId)
            const { created_by, body } = updateReplyComment[0] || {}
            setReplyUpdate({ created_by, body })
        }
    }, [replyData, replyId])

    return (
        <div className="absolute w-full bottom-[7.6rem] md:bottom-[7.1rem] h-[65vh] md:h-[60vh]">
            <div className="h-full overflow-y-scroll pb-14 bg-black rounded-t-2xl">
                <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                    <div className="flex items-center gap-2">
                        <p className="text-white text-[14px]">Comments</p>
                        <p className="text-[#727272] text-[12px]">
                            {/* {videoData?.comments_count} */}
                            {commentsData?.length}

                        </p>
                    </div>
                    <IoClose
                        onClick={handleComment}
                        className="text-white text-[22px] md:text-[20px] cursor-pointer"
                    />
                </div>
                {loading ? (
                    [1, 2, 3, 4, 5, 6].map(() => (
                        <div className="mx-1 my-1">
                            <Skeleton baseColor="#202020" className="col-span-12" style={{ width: "100%", height: "3.5rem" }} highlightColor="#444" />
                        </div>
                    ))
                ) : commentsData && commentsData.length > 0 ? (
                    commentsData.map((data) => {
                        if (data.id) {
                            // handlegetLikesComment(data.id)
                        }
                        return (
                            <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-1 mx-1" key={data.id} ref={replyMenuRef} >
                                <div className="col-span-2">
                                    <img
                                        alt={data?.created_by?.full_name}
                                        src={data?.created_by?.profile_picture}
                                        className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-10 -ml-3">
                                    <div className="flex justify-between">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.created_by.full_name}</p>
                                        {userId === data?.created_by?.id && <div className="flex justify-end items-center gap-2">
                                            <RiDeleteBin5Fill className="h-3 w-3 ml-2 text-white cursor-pointer hover:text-red-600" onClick={() => handleDeleteComment(data.id)} />
                                            <FaEdit className="h-3 w-3 ml-2 text-white cursor-pointer hover:text-red-600" onClick={() => handleUpdateComment(data.id)} />
                                        </div>}
                                    </div>

                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                        {data.body}
                                    </p>
                                    <div className="flex items-center mt-1">
                                        <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer" onClick={() => handleReply(data.id)}>Reply</p>
                                        <div className="flex ml-4 gap-2">
                                            <IoMdHeart className={`h-3 w-3  cursor-pointer ${commentLikes.includes(data.id) ? "text-lime-500" : "text-white"} hover:text-lime-500`} onClick={() => handleLike(data.id)} />
                                            <IoMdHeartDislike className={`h-3 w-3 cursor-pointer hover:text-red-600 ${commentDislikes.includes(data.id) ? "text-red-600" : "text-white"}`} onClick={() => handleDislike(data.id)} />
                                        </div>
                                        {openReplyId === data.id ? <p
                                            onClick={() => toggleReplies(data.id)}
                                            className="text-light-blue-900 hover:text-light-blue-800 text-[10px] ml-6  font-semibold cursor-pointer"
                                        >
                                            Hide Replies
                                        </p>
                                            :
                                            <p
                                                onClick={() => toggleReplies(data.id)}
                                                className="text-light-blue-900 hover:text-light-blue-800 text-[10px] ml-6  font-semibold cursor-pointer"
                                            >
                                                View Replies
                                            </p>}

                                    </div>
                                    {openReplyId === data.id &&
                                        <>
                                            {isReplyLoading ?
                                                (<p className="text-white text-[12px] text-center mt-4">Loading...</p>) :
                                                <>
                                                    {replyData?.length === 0 && !isReplyLoading ? <p className="text-white text-[12px] text-center mt-4">No replies yet.</p> : null}
                                                    {replyData?.map((items) => (
                                                        <div className="grid grid-cols-12 mt-2" key={items.id}>
                                                            <div className="col-span-2">
                                                                <img
                                                                    alt={items?.created_by?.full_name}
                                                                    src={items?.created_by?.profile_picture}
                                                                    className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                                                />
                                                            </div>
                                                            <div className="col-span-8">
                                                                <div className="flex items-center gap-x-4">
                                                                    <p className="text-white text-[12px] font-semibold mt-[-2px]">
                                                                        {items?.created_by.full_name}
                                                                    </p>
                                                                    <p className="text-[10px] text-[#636363]">
                                                                        {new Date(items?.created_at).toLocaleDateString()}
                                                                    </p>

                                                                </div>
                                                                <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                                                    {items?.body}
                                                                </p>
                                                            </div>
                                                            {userId === items?.created_by?.id && <div className=" col-span-2 flex justify-end items-center gap-1">
                                                                <RiDeleteBin5Fill className="text-[0.6rem] ml-2 text-white cursor-pointer hover:text-red-600" onClick={() => handleDeleteCommentReply(items.id)} />
                                                                <FaEdit className="text-[0.6rem] ml-2 text-white cursor-pointer hover:text-red-600" onClick={() => handleUpdateCommentReply(items.id)} />
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    }
                    )
                ) : (
                    <p className="text-white text-center mt-4">No comments yet.</p>
                )}
                {!replyInput && <div className="absolute bottom-0 w-full px-2 py-2 border-t-[0.4px] bg-black border-[#262626]">


                    {updateComment && <>
                        <div className="flex justify-between">
                            <p className="text-white text-[12px] font-semibold">Edit your Comment</p>
                            <p className="text-white text-[12px] cursor-pointer" onClick={() => close()}>
                                cancel
                            </p>
                        </div>
                        <div className="grid grid-cols-12 py-2">
                            <div className="col-span-2"></div>

                            <div className="col-span-1">
                                <img
                                    alt={commentReply?.created_by?.full_name}
                                    src={commentReply?.created_by?.profile_picture}
                                    className="h-4 w-4 rounded-full object-cover cursor-pointer"
                                />
                            </div>
                            <div className="col-span-6">
                                <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                    {commentReply?.body}
                                </p>
                            </div>
                        </div>
                    </>}

                    <form className="flex items-center justify-between gap-2" onSubmit={handleCommentSubmit}>
                        <img
                            alt="account"
                            className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                            src={userDetails?.profile_picture}
                        />
                        <input
                            type="text"
                            value={comment}
                            placeholder="Add a comment..."
                            onChange={(e) => setComment(e.target.value)}
                            onKeyPress={handleKeyPress}
                            className="w-full bg-[#1e1e1e] text-white text-[14px] px-2 py-[9px] md:py-[6px] rounded focus:outline-none placeholder:text-[15px] md:placeholder:text-[12px]"
                        />
                        {comment.length > 0 && (
                            <IoSend
                                type="submit"
                                className="text-white text-[30px] md:text-[25px] cursor-pointer"
                                onClick={handleCommentSubmit}
                            />
                        )}
                    </form>
                </div>
                }

                {replyInput &&
                    <div className="absolute bottom-0 w-full px-2 py-2 border-t-[0.4px] bg-black border-[#262626]">

                        {!updateReply && <>
                            <div className="flex justify-between">
                                <p className="text-white text-[12px] font-semibold">Replying to {commentReply?.created_by?.full_name}</p>
                                <p className="text-white text-[12px] cursor-pointer" onClick={() => close()}>
                                    cancel
                                </p>
                            </div>
                            <div className="grid grid-cols-12 py-2">
                                <div className="col-span-2"></div>

                                <div className="col-span-1">
                                    <img
                                        alt={commentReply?.created_by?.full_name}
                                        src={commentReply?.created_by?.profile_picture}
                                        className="h-4 w-4 rounded-full object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-6">
                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                        {commentReply?.body}
                                    </p>
                                </div>
                            </div>
                        </>}


                        {updateReply && <>
                            <div className="flex justify-between">
                                <p className="text-white text-[12px] font-semibold">Edit your Reply</p>
                                <p className="text-white text-[12px] cursor-pointer" onClick={() => close()}>
                                    cancel
                                </p>
                            </div>
                            <div className="grid grid-cols-12 py-2">
                                <div className="col-span-2"></div>

                                <div className="col-span-1">
                                    <img
                                        alt={replyUpdate?.created_by?.full_name}
                                        src={replyUpdate?.created_by?.profile_picture}
                                        className="h-4 w-4 rounded-full object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-6">
                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                        {replyUpdate?.body}
                                    </p>
                                </div>
                            </div>
                        </>}

                        <form className="flex items-center justify-between gap-2" onSubmit={handleCreateCommentReply} >
                            <img
                                alt="account"
                                className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                                src={userDetails?.profile_picture}
                            />
                            <input
                                type="text"
                                value={reply}
                                placeholder="Add a reply..."
                                onChange={(e) => setReply(e.target.value)}
                                onKeyPress={handleKeyPressReply}
                                className="w-full bg-[#1e1e1e] text-white text-[14px] px-2 py-[6px] rounded focus:outline-none placeholder:text-[12px]"
                            />
                            {reply.length > 0 && (
                                <IoSend
                                    type="submit"
                                    onClick={updateReply ? handleUpdateCommentReplySubmit : handleCreateCommentReply}
                                    className="text-white text-[25px] cursor-pointer"
                                />
                            )}
                        </form>
                    </div>
                }
            </div>
        </div>
    );
};

export default Comment;