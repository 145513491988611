import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

// all articles list
export const helpAllArticlesList = createAsyncThunk(
    "helpAllArticlesList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/help/articles/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// all categories
export const helpAllCategories = createAsyncThunk(
    "helpAllCategories",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/help/categories/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// article by category
export const helpFilterArticlesByCategory = createAsyncThunk(
    "helpFilterArticlesByCategory",
    async (categoryId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/help/categories/${categoryId}/articles/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// search articles
export const helpSearchArticles = createAsyncThunk(
    "helpSearchArticles",
    async (searchText, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/help/articles/search/?q=${searchText}`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// like & unlike article
export const likeUnlikeArticle = createAsyncThunk(
    "likeUnlikeArticle",
    async (articleId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/help/articles/${articleId}/like/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// dislike & remove dislike article
export const dislikeRemovedislikeArticle = createAsyncThunk(
    "dislikeRemovedislikeArticle",
    async ({ articleId, dislikeData }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(
                `${mainUrl}api/help/articles/${articleId}/dislike/`,
                dislikeData ? dislikeData : {},
                config
            );
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// add feedback
export const addFeedback = createAsyncThunk(
    "addFeedback",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/help/feedback/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const helpSlice = createSlice({
    name: "help",
    initialState: {
        loading: false,
        data: [],
        categories: [],
        error: null,
    },
    extraReducers: (builder) => { 
        // all articles list
        builder.addCase(helpAllArticlesList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(helpAllArticlesList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(helpAllArticlesList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // all categories
        builder.addCase(helpAllCategories.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(helpAllCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.categories = action.payload;
        });
        builder.addCase(helpAllCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // article by category
        builder.addCase(helpFilterArticlesByCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(helpFilterArticlesByCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(helpFilterArticlesByCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // search articles
        builder.addCase(helpSearchArticles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(helpSearchArticles.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(helpSearchArticles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // like & unlike article
        builder.addCase(likeUnlikeArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(likeUnlikeArticle.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(likeUnlikeArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // dislike & removeDlike article
        builder.addCase(dislikeRemovedislikeArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(dislikeRemovedislikeArticle.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(dislikeRemovedislikeArticle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // add feedback
        builder.addCase(addFeedback.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addFeedback.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(addFeedback.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default helpSlice.reducer;