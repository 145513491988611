import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

export const updateProfile = createAsyncThunk(
    "updateProfile",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.put(`${mainUrl}api/user/update/`, data , config);
        const response = await request.data;
        return response;
    }
);

const updateProfileSlice = createSlice({
    name: "updateProfileSlice",
    initialState: {
        loading: false,
        userDetails: null,
        error: null,
    },
    extraReducers: (builder) => {
        //updateProfile
        builder.addCase(updateProfile.pending, (state) => {
            state.loading = true;
            state.userDetails = null;
            state.error = null;
        });
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.userDetails = action.payload;
            state.error = null;
        });
        builder.addCase(updateProfile.rejected, (state) => {
            state.loading = false;
            state.userDetails = null;
            state.error = true;
        });
    }
});

export default updateProfileSlice.reducer;
