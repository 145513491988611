import React, { useRef, useEffect, useState, Fragment } from "react";
import { copyright } from "../../utils/copyright";

import { useNavigate } from "react-router-dom";
import { Switch, Typography } from "@material-tailwind/react";

import { PiTextAaFill } from "react-icons/pi";
import { FaRegImages } from "react-icons/fa6";
import { LuImagePlus } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { createVideo } from "../../redux/apislice/createSlice";
import toast, { Toaster } from "react-hot-toast";
import { Line } from 'rc-progress';

const CreateVideo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const create = useSelector((state) => state.createVideo);

    const fileInputRef = useRef(null);
    const isFileInputClicked = useRef(false);

    const [videoSrc, setVideoSrc] = useState("");
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailSrc, setThumbnailSrc] = useState("");
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const [isTextClicked, setIsTextClicked] = useState(true);
    const [isThumbnailClicked, setIsThumbnailClicked] = useState(false);

    const handleTextClick = () => {
        setIsTextClicked(true);
        setIsThumbnailClicked(false);
    }

    const handleThumbnailClick = () => {
        setIsTextClicked(false);
        setIsThumbnailClicked(true);
    }

    useEffect(() => {
        if (!isFileInputClicked.current && fileInputRef.current) {
            fileInputRef.current.click();
            isFileInputClicked.current = true;
        }
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (file.type === "video/mp4" && file.size <= 1024 * 1024 * 1024) { // 1GB max
                setVideoFile(file);
                const videoURL = URL.createObjectURL(file);
                setVideoSrc(videoURL);
            } else {
                navigate("/home");
            }
        } else {
            navigate("/home");
        }
    };

    const handleFileInputClick = () => {
        fileInputRef.current.value = null;
    };

    const handleThumbnailSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            // if (file.type === "image/jpeg" || file.type === "image/png") {
            const imageURL = URL.createObjectURL(file);
            setThumbnailFile(file);
            setThumbnailSrc(imageURL);

            // } else {
            //     toast.error("Please select a valid image file");
            // }
        }
    };

    const handleCreate = async () => {
        if (videoFile && thumbnailFile && description && title) {
            const formData = new FormData();
            formData.append("video", videoFile);
            formData.append("thumbnail", thumbnailFile);
            formData.append("title", title);
            formData.append("description", description);
            await dispatch(createVideo(formData)).then((res) => {
                if (res.payload) {
                    setTimeout(() => {
                        navigate("/home");
                    }, 2000);
                    toast.success("Video uploaded successfully");
                } else {
                    toast.error("Failed to upload video")
                }
            })
        } else {
            toast.error("Please add description to your video");
        }
    }

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center sticky top-0">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Video / V2 / 03 Sep, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <p className="text-center text-[16px] mt-[2px] font-bold text-[#ffffff]">
                Create Video
            </p>

            {/* Video Preview */}
            <div className="px-2">
                {!videoSrc &&
                    <input
                        type="file"
                        accept="video/mp4"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        onClick={handleFileInputClick}
                        className="relative mt-2 m-0 block w-full min-w-0 text-white flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3 file:py-[0.32rem] file:text-surface file:text-white focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none"

                    />
                }
                {videoSrc && (
                    <div className=" relative">
                        <video
                            loop
                            muted
                            autoPlay
                            src={videoSrc}
                            disablePictureInPicture
                            className="w-full h-auto max-h-[15rem] mt-2 rounded object-cover "
                        />
                        <div className="h-6 w-6 rounded-full cursor-pointer absolute top-0 flex justify-center  hover:border-[1px] hover:border-white items-center right-0 mr-2 mt-2 bg-none hover:bg-black">
                            <LuImagePlus onClick={() => document.getElementById('videoInput').click()}
                                className="text-[0.7rem]  text-[#ffffff] "
                            />
                        </div>

                        <input
                            type="file"
                            id="videoInput"
                            accept="video/*"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </div>
                )}
            </div>

            {videoSrc &&
                <div className="px-2">
                    {/* Add Text */}
                    {isTextClicked &&

                        <div className="mt-2">
                            <div className="flex flex-col">
                                <p className="text-[12px] text-white font-semibold">Add Title</p>
                                <input
                                    type="text"
                                    value={title}
                                    required
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Add title to your video"
                                    className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[13px] text-[13px] rounded border-none bg-[#1f1f1f] focus:outline-none"
                                />
                            </div>
                            <div className="flex flex-col mt-2">
                                <p className="text-[12px] text-white font-semibold">Add text</p>
                                <textarea
                                    type="text"
                                    value={description}
                                    required
                                    onChange={(e) => setDescription(e.target.value)}
                                    rows={3}
                                    placeholder="Add text to your video"
                                    className="w-full mt-[2px] px-2 py-2 resize-none text-white placeholder:text-[13px] text-[13px] rounded border-none bg-[#1f1f1f] focus:outline-none"
                                />
                            </div>
                            <div className="flex items-center justify-between my-2">
                                <p className="text-[12px] text-white">
                                    Private
                                </p>
                                <Switch
                                    ripple={false}
                                    className="h-4 w-[28.5px] checked:bg-[#0DD315] bg-[#636363]"
                                    containerProps={{
                                        className: "w-8 h-4",
                                    }}
                                    circleProps={{
                                        className: "before:hidden left-0.5 border-none w-3 h-3",
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {/* Add Thumbnail */}
                    {isThumbnailClicked &&
                        <div className="my-2">
                            <p className="text-[12px] text-white font-semibold">Add Thumbnail</p>
                            <div className="flex items-center justify-center">
                                {!thumbnailSrc &&
                                    <div className="flex items-center mt-1 justify-center w-full h-[140px] bg-[#1f1f1f] rounded">
                                        <LuImagePlus
                                            onClick={() => document.getElementById('thumbnailInput').click()}
                                            className="text-[20px] cursor-pointer text-[#ffffff]"
                                        />
                                    </div>
                                }
                                <input
                                    type="file"
                                    id="thumbnailInput"
                                    accept="image/*"
                                    className="hidden"
                                    onChange={handleThumbnailSelect}
                                />
                                {thumbnailSrc &&
                                    <div className="flex flex-col w-full">
                                        <img
                                            alt="thumbnail"
                                            src={thumbnailSrc}
                                            className="w-full h-[150px] rounded object-cover mt-2"
                                        />
                                        <button
                                            onClick={() => setThumbnailSrc("")}
                                            className="text-[12px] rounded px-3 mt-1 py-1 bg-[#e72e2e] text-white whitespace-nowrap"
                                        >
                                            remove
                                        </button>
                                    </div>

                                }
                            </div>
                        </div>
                    }

                    <div className="bg-black flex items-center justify-center my-3">
                        <div className="inline-flex items-center justify-center gap-4 bg-white rounded-[8px] px-3 py-[5px]">
                            <PiTextAaFill
                                onClick={handleTextClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isTextClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                            <FaRegImages
                                onClick={handleThumbnailClick}
                                className={`h-[20px] w-[20px] cursor-pointer ${isThumbnailClicked ? "text-[#0DD315]" : "text-[#636363]"}`}
                            />
                        </div>
                    </div>
                    {create.progress > 0 && create?.loading && <div className="w-full">
                        <div className="mb-2 flex items-center justify-between gap-4">
                            <Typography className="text-white" color="blue-gray" variant="h6">
                                Completed
                            </Typography>
                            <Typography className="text-white" color="blue-gray" variant="h6">
                                {create.progress}%
                            </Typography>
                        </div>
                        <Line percent={create.progress} strokeWidth={4} strokeLinecap="round" strokeColor="rgb(67,60,187)" trailColor="#D9D9D9" style={{ height: "10px", width: "100%" }} />
                    </div>}

                    {!create?.loading &&
                        <div className="flex items-center justify-between">
                            <p
                                onClick={() => navigate(-1)}
                                className={`text-[14px] text-[#ffffff] font-bold cursor-pointer hover:text-red-500 ${create.progress && "mt-4"}`}
                            >
                                Cancel
                            </p>
                            <p
                                onClick={() => handleCreate()}
                                className="text-[14px] text-[#0DD315] font-bold cursor-pointer"
                            >
                                Upload
                            </p>
                        </div>
                    }

                    {!create.loading && <div className="flex items-center justify-center mt-3 mb-3">
                        <button
                            onClick={() => navigate(-1)}
                            className="text-[14px] px-4 py-[3px] tracking-[1px] rounded bg-[#333333] hover:bg-[#3e3e3e] text-[#ffffff]"
                        >
                            Draft
                        </button>
                    </div>}

                </div>
            }
        </Fragment>
    );
};

export default CreateVideo;