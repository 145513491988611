import React from "react";
import { IoClose } from "react-icons/io5";
import { downloadClips } from "../../redux/apislice/clips/downloadClipsSlice";
import { useDispatch } from "react-redux";

const Download = ({
    videoData,
    isSubscribed,
    setIsSubscribed,
    handleDownload,
}) => {
    const dispatch = useDispatch();
    const { created_by, id, video } = videoData[0] || {};
    const videoUrl = video;
    const downloadVideo = async () => {
        if (!videoUrl) {
            alert("Video URL not available.");
            return;
        }

        try {
            await dispatch(downloadClips(id))
            const response = await fetch(videoUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `kindviewer_${created_by?.full_name || 'kindviewer_video'}.mp4`; // Custom file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading the video:", error);
            alert("Failed to download the video.");
        }
    };

    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.5rem] md:bottom-[7.1rem] h-[25vh] md:h-[20vh] bg-black rounded-t-2xl">
            <div className="px-3 md:px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-3 md:pt-2 pb-2 md:pb-1 border-b-[0.4px] border-[#262626]">
                <p className="text-white text-[16px] md:text-[14px]">Download</p>
                <IoClose
                    onClick={handleDownload}
                    className="text-white text-[22px] md:text-[20px] cursor-pointer"
                />
            </div>
            <div className="flex px-3 md:px-2 pt-2 items-center justify-between">
                <div className="flex items-center">
                    <img
                        alt="account"
                        className="h-12 w-12 md:h-9 md:w-9 rounded-full object-cover bg-black cursor-pointer"
                        src={created_by?.profile_picture}
                    />
                    <div className="flex flex-col ml-2">
                        <p className="text-white text-[15px] md:text-[12px] font-semibold">
                            {created_by?.full_name}
                        </p>
                        <p className="text-[#717171] text-[13px] md:text-[10px]">
                            100
                        </p>
                    </div>
                </div>
                <button
                    onClick={() => setIsSubscribed(!isSubscribed)}
                    className="text-[14px] md:text-[12px] text-white border border-[#ffffff] px-3 md:px-2 py-[4px] md:py-[2px] rounded-full"
                >
                    {isSubscribed ? "Unsubscribe" : "Subscribe"}
                </button>
            </div>
            <div className="flex items-center justify-center mt-5 pb-4">
                <button
                    onClick={downloadVideo}
                    className="px-3 py-1 text-[14px] md:text-[12px] rounded-full bg-[#ebebeb] hover:bg-[#ffffff] outline-none border-none"
                >
                    Download Video
                </button>
            </div>
        </div>
    );
};

export default Download;