import React, { useState, useRef, useEffect } from "react";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import { IoPlaySkipBack, IoPlaySkipForward } from "react-icons/io5";
import { IoPause, IoPlay, IoVolumeMute, IoVolumeHigh } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { savedMusicListForUser, saveUnsaveMusicById } from "../../redux/apislice/music/musicSlice";

const BottomPlayer = ({
    currentMusic,
    isPlaying,
    isMuted,
    volume,
    progress,
    currentTime,
    duration,
    handlePlayPause,
    handleNext,
    handlePrevious,
    handleMute,
    handleVolumeChange,
    handleSeek,
    formatTime,
}) => {
    const progressBarRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const savedMusicList = useSelector((state) => state.musicList.savedMusicList);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(savedMusicListForUser());
    }, [dispatch]);

    useEffect(() => {
        setIsSaved(savedMusicList.some((music) => music.id === currentMusic.id));
    }, [savedMusicList, currentMusic]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        handleSeek(e, progressBarRef.current);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            handleSeek(e, progressBarRef.current);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleSaveUnsave = async () => {
        try {
            await dispatch(saveUnsaveMusicById(currentMusic.id)).unwrap();
            setIsSaved((prev) => !prev);
        } catch (error) {
            console.error("Failed to save/unsave music:", error);
        }
    };

    return (
        <div className="absolute w-full bottom-[5.6rem] left-0 right-0 px-2 py-2 bg-[#3e3e3e] text-white">
            <div className="grid grid-cols-12 gap-x-2 items-start">
                <div className="col-span-2">
                    <img
                        alt={currentMusic.singer}
                        src={currentMusic.image === null ?
                            "https://cdn.pixabay.com/photo/2021/01/29/08/10/musician-5960112_1280.jpg"
                            : currentMusic.image
                        }
                        className={`w-12 md:w-10 h-12 md:h-10 rounded-full object-cover ${isPlaying ? "rotate" : ""}`}
                    />
                </div>
                <div className="col-span-10 flex flex-col items-start">
                    <div className="flex w-full items-start justify-between gap-x-2">
                        <div className="flex-col">
                            <p className="text-[15px] md:text-[12px] text-white line-clamp-1">
                                {currentMusic.title}
                            </p>
                            <p className="text-[13px] md:text-[10px] font-bold text-[#959595]">
                                {currentMusic.singer}
                            </p>
                        </div>
                        <div className="flex items-center gap-x-2 mt-[2px]">
                            {isSaved ? (
                                <IoBookmark
                                    onClick={handleSaveUnsave}
                                    className="text-[20px] md:text-[16px] cursor-pointer"
                                />
                            ) : (
                                <IoBookmarkOutline
                                    onClick={handleSaveUnsave}
                                    className="text-[20px] md:text-[16px] cursor-pointer"
                                />
                            )}
                        </div>
                    </div>

                    <div className="flex w-full items-center justify-between mt-1">
                        <div className="flex items-center gap-x-3">
                            <IoPlaySkipBack onClick={handlePrevious} className="text-[20px] md:text-[16px] cursor-pointer" />
                            {isPlaying ? (
                                <IoPause onClick={() => handlePlayPause(currentMusic.id, currentMusic)} className="text-[20px] md:text-[16px] cursor-pointer" />
                            ) : (
                                <IoPlay onClick={() => handlePlayPause(currentMusic.id, currentMusic)} className="text-[20px] md:text-[16px] cursor-pointer" />
                            )}
                            <IoPlaySkipForward onClick={handleNext} className="text-[20px] md:text-[16px] cursor-pointer" />
                        </div>

                        <div className="flex items-center gap-x-3">
                            {isMuted ? (
                                <IoVolumeMute onClick={handleMute} className="text-[20px] md:text-[16px] cursor-pointer" />
                            ) : (
                                <IoVolumeHigh onClick={handleMute} className="text-[20px] md:text-[16px] cursor-pointer" />
                            )}
                            <input
                                type="range"
                                min="0"
                                max="100"
                                value={volume}
                                onChange={handleVolumeChange}
                                className="w-20 mx-2 h-[5px] cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="w-full bg-[#b3b3b3] rounded-full h-1 mt-2 relative"
                ref={progressBarRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div
                    style={{ width: `${progress}%` }}
                    className="bg-[#27b129] h-1 rounded-full cursor-pointer"
                />
            </div>

            <div className="flex justify-between text-[12px] md:text-[10px] text-[#959595] mt-1">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
            </div>
        </div>
    );
};

export default BottomPlayer;