import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


// currently this api is not used in the project
export const getTagsClips = createAsyncThunk(
    "getTagsClips",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/tags/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


const clipsTagsSlice = createSlice({
    name: "clipsTagsSlice",
    initialState: {
        loading: false,
        error: null,
        tags: [],
    },
    extraReducers: (builder) => {
        // getTags clips
        builder.addCase(getTagsClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getTagsClips.fulfilled, (state, action) => {
            state.loading = false;
            state.tags = action.payload;
            state.error = null;
        });
        builder.addCase(getTagsClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default clipsTagsSlice.reducer;