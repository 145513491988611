import React from 'react'
import { CiSearch } from 'react-icons/ci'
import { IoMdArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'


export default function PlaylistHeader() {
    const navigate = useNavigate()
    return (
        <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
            <div className="col-span-6 flex items-center justify-start gap-3">
                <IoMdArrowBack
                    onClick={() => navigate(-1)}
                    className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                />
                <p className="text-white text-[16px] font-medium">Your Playlist</p>
            </div>
            <div className="col-span-6 flex items-center justify-end gap-3 ">
                <CiSearch
                    onClick={() => navigate("/search")}
                    className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                />
            </div>
        </div>
    )
}
