import React from "react";
import { IoClose } from "react-icons/io5";
import Cookies from 'js-cookie';

const VideoDetails = ({
    handleVideoDetails,
    videoData,
    currentIndex,
    isSubscribed,
    setIsSubscribed,
    navigate
}) => {
    const { created_by, description, created_at, view_count, likes_count } = videoData[0] || {};
    const userId = Cookies.get("userId");
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };


    const handleNavigate = () => {
        if (created_by?.id === userId) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${created_by?.id}`)
        }
    }


    const words = description.split(' ');
    const hashtags = [];
    let plainText = '';

    words.forEach(word => {
        if (word.startsWith('#')) {
            hashtags.push(word);
        } else {
            plainText += word + ' ';
        }
    });

    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.5rem] md:bottom-[7.1rem] h-[40vh] md:h-[35vh] bg-black rounded-t-2xl">
            <div className="px-3 md:px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-3 md:pt-2 pb-2 md:pb-1 border-b-[0.4px] border-[#262626]">
                <p className="text-white text-[16px] md:text-[14px]">Description</p>
                <IoClose
                    onClick={handleVideoDetails}
                    className="text-white text-[22px] md:text-[20px] cursor-pointer"
                />
            </div>
            <div className="px-3 md:px-2 pt-2 flex items-center justify-between">
                <div className="flex items-center">
                    <img
                        alt="account"
                        src={created_by?.profile_picture}
                        onclick={handleNavigate}
                        className="h-12 w-12 md:h-9 md:w-9 rounded-full object-cover bg-black cursor-pointer"
                    />
                    <div className="flex flex-col ml-2">
                        <p className="text-white text-[15px] md:text-[12px] font-semibold">
                            {created_by?.full_name}
                        </p>
                        <p className="text-[#717171] text-[13px] md:text-[10px]">
                            100
                        </p>
                    </div>
                </div>
                <button
                    onClick={() => setIsSubscribed(!isSubscribed)}
                    className="text-[14px] md:text-[12px] text-white border border-[#ffffff] px-3 md:px-2 py-[4px] md:py-[2px] rounded-full"
                >
                    {isSubscribed ? "Unsubscribe" : "Subscribe"}
                </button>
            </div>
            <div className="flex items-center justify-evenly pt-4">
                <div className="flex flex-col items-center leading-[20px] md:leading-[14px]">
                    <p className="text-[16px] md:text-[12px] text-[#ffffff]">{likes_count}</p>
                    <p className="text-[16px] md:text-[12px] text-[#717171]">Likes</p>
                </div>
                <div className="flex flex-col items-center leading-[20px] md:leading-[14px]">
                    <p className="text-[16px] md:text-[12px] text-[#ffffff]">{view_count}</p>
                    <p className="text-[16px] md:text-[12px] text-[#717171]">Views</p>
                </div>
                <div className="flex flex-col items-center leading-[20px] md:leading-[14px]">
                    <p className="text-[16px] md:text-[12px] text-[#ffffff]">{formatDate(created_at)}</p>
                    <p className="text-[16px] md:text-[12px] text-[#717171]">Uploaded</p>
                </div>
            </div>
            <p className="text-[16px] md:text-[12px] leading-[20px] md:leading-[14px] text-white px-3 md:px-2 pt-4 pb-2">
                {plainText.trim()}
            </p>
            <div className="flex flex-wrap items-center gap-x-2 px-3 md:px-2 pb-5">
                {hashtags?.map((data, index) => (
                    <p key={index} className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[16px] md:text-[12px]">
                        {data}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default VideoDetails;