import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const securityLogin = createAsyncThunk(
    "security/login",
    async (loginDetails) => {
        const request = await axios.post(`${mainUrl}api/security_login/`, loginDetails);
        const response = await request.data;

        localStorage.setItem("securityAccessToken", response.access);
        return response;
    }
);

const securitySlice = createSlice({
    name: "security",
    initialState: {
        loading: false,
        user: null,
        error: null,
    },
    extraReducers:(builder) => {
        builder.addCase(securityLogin.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(securityLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(securityLogin.rejected, (state, action) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
    }
});

export default securitySlice.reducer;
