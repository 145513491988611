import React, { Fragment } from "react"
import MainRouter from "./router/MainRouter"

const App = () => {

   // only for development
   // useEffect(() => {
   //    document.onkeydown = function (e) {
   //       // Disable F12 (DevTools), Ctrl+Shift+I (Inspect), Ctrl+Shift+J (Console), Ctrl+U (View Source)
   //       if (e.keyCode === 123 || // F12
   //          (e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
   //          (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J
   //          (e.ctrlKey && e.keyCode === 85)) { // Ctrl+U
   //          e.preventDefault();
   //          return false;
   //       }
   //    };
   // }, [])

   return (
      <Fragment>
         <MainRouter />
      </Fragment>
   )
}

export default App