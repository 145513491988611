import React, { Fragment, useEffect } from 'react'
import { getClipsList } from '../../redux/apislice/clips/postedClipsSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Clips({ userId }) {
    const dispatch = useDispatch();
    const clipsList = useSelector((state) => state.postedClips);
    const { postedClips } = clipsList;

    console.log(postedClips, "postedClips")

    useEffect(() => {
        dispatch(getClipsList(userId));
    }, [dispatch, userId]);

    return (
        <Fragment>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-6'>


                </div>
            </div>
        </Fragment>
    )
}
