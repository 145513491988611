import React, { Fragment } from "react";
import { IoPlay } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { MdOutlineForward10, MdOutlineReplay10 } from "react-icons/md";
import { HiMiniSpeakerWave, HiMiniSpeakerXMark } from "react-icons/hi2";

const MainVideoControls = ({
    handleReplay,
    handlePlayPause,
    isPlaying,
    handleForward,
    handleSeek,
    progress,
    currentTime,
    duration,
    formatTime,
    mute,
    setMute,
    isFullScreen,
    setIsFullScreen
}) => {
    return (
        <Fragment>
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                <div className="flex items-center gap-3">
                    <div className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center">
                        <MdOutlineReplay10
                            onClick={handleReplay}
                            className="text-white text-[20px]"
                        />
                    </div>
                    <div
                        onClick={handlePlayPause}
                        className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center"
                    >
                        {isPlaying ? (
                            <FaPause className="text-white text-[16px]" />
                        ) : (
                            <IoPlay className="text-white text-[16px]" />
                        )}
                    </div>
                    <div className="bg-[#00000043] h-[30px] w-[30px] rounded-full cursor-pointer flex items-center justify-center">
                        <MdOutlineForward10
                            onClick={handleForward}
                            className="text-white text-[20px]"
                        />
                    </div>
                </div>
            </div>

            <div className="absolute bottom-[30px] left-0 w-full flex justify-center opacity-0 group-hover:opacity-100">
                <div
                    onClick={handleSeek}
                    className="w-[80%] bg-[#b3b3b3] rounded-full h-1 mt-2 cursor-pointer"
                >
                    <div
                        style={{ width: `${progress}%` }}
                        className="bg-[#27b129] h-1 rounded-full cursor-pointer"
                    />
                </div>
            </div>

            <div className="absolute bottom-[5px] left-0 w-full px-3 pt-1 pb-1 bg-gradient-to-t from-[#000000] opacity-0 group-hover:opacity-100">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1">
                        <p className="text-white text-[10px]">{formatTime(currentTime)}</p>
                        <p className="text-white text-[10px]">/</p>
                        <p className="text-white text-[10px]">{formatTime(duration)}</p>
                    </div>
                    <div className="flex items-center gap-1">
                        {mute ? (
                            <HiMiniSpeakerXMark
                                className="text-[14px] text-white cursor-pointer"
                                onClick={() => setMute(!mute)}
                            />
                        ) : (
                            <HiMiniSpeakerWave
                                onClick={() => setMute(!mute)}
                                className="text-[14px] text-white cursor-pointer"
                            />
                        )}
                        <IoIosSettings className="text-[14px] text-white cursor-pointer transition-all duration-500 hover:rotate-180" />
                        {isFullScreen ? (
                            <MdFullscreenExit
                                onClick={() => setIsFullScreen(false)}
                                className="text-[14px] text-white cursor-pointer"
                            />
                        ) : (
                            <MdFullscreen
                                onClick={() => setIsFullScreen(true)}
                                className="text-[14px] text-white cursor-pointer"
                            />
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MainVideoControls;