import React from "react";
import { CiStreamOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMusicalNotes } from "react-icons/io5";
import { MdWorkspacePremium } from "react-icons/md";
import { FaPodcast, FaRegNewspaper, FaUserCheck, FaRegCalendarAlt } from "react-icons/fa";

const SecondMenu = () => {
    const navigate = useNavigate();

    // menu items
    const menuItems = [
        { icon: MdWorkspacePremium, pathName: "/sponsore" },
        { icon: FaPodcast, pathName: "/podcasts" },
        { icon: FaRegNewspaper, pathName: "/live/news" },
        { icon: CiStreamOn, pathName: "/livestream" },
        { icon: IoMusicalNotes, pathName: "/music" },
        { icon: FaUserCheck, pathName: "/subscribed" },
        { icon: FaRegCalendarAlt, pathName: "/calendar" },
    ];

    return (
        <div className="bg-black flex items-center justify-center py-1">
            <div className="inline-flex items-center justify-center gap-4 border border-gray-500 bg-white text-[#6d6d6d] rounded-full px-3 py-1">
                {menuItems.map((item, index) => (
                    <item.icon 
                        key={index}
                        onClick={() => navigate(item.pathName)} 
                        className="h-5 w-5 md:h-4 md:w-4 cursor-pointer" 
                    />
                ))}
            </div>
        </div>
    )
}

export default SecondMenu