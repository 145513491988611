import axios from "axios";
import getAccessConfig from "../../config";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const likeClips = createAsyncThunk(
    "likeClips",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/like/${clipId}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const likeRemove = createAsyncThunk(
    "likeRemove",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/unlike/${clipId}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const dislikeClips = createAsyncThunk(
    "dislikeClips",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/dislike/${clipId}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const dislikeRemove = createAsyncThunk(
    "dislikeRemove",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/remove_dislike/${clipId}/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllLikesList = createAsyncThunk(
    "getAllLikesList",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/${clipId}/likes/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getAllDislikesList = createAsyncThunk(
    "getAllDislikesList",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/${clipId}/dislikes/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const clipsLikeSlice = createSlice({
    name: "clipsLike",
    initialState: {
        loading: false,
        likesCount: 0,
        LikeData: null,
        dislikesCount: 0,
        dislikeData: null,
        data: null,
        error: null,
    },
    extraReducers: (builder) => {
        // Like clips
        builder.addCase(likeClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(likeClips.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(likeClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Remove like
        builder.addCase(likeRemove.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(likeRemove.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(likeRemove.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Dislike clips
        builder.addCase(dislikeClips.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(dislikeClips.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(dislikeClips.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Remove dislike
        builder.addCase(dislikeRemove.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(dislikeRemove.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        });
        builder.addCase(dislikeRemove.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Get all likes list
        builder.addCase(getAllLikesList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllLikesList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.likesCount = action.payload.count;
            state.LikeData = action.payload;
        });
        builder.addCase(getAllLikesList.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

        // Get all dislikes list
        builder.addCase(getAllDislikesList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllDislikesList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.dislikesCount = action.payload.count;
            state.dislikeData = action.payload.dislikes;
        });
        builder.addCase(getAllDislikesList.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default clipsLikeSlice.reducer;