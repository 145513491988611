import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

const isAuthenticated = () => {
    const securityAccessToken = localStorage.getItem("securityAccessToken");
    const accessToken = Cookies.get("accessToken");
    return securityAccessToken && accessToken;
};

const PrivateRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/');
        }
    }, [navigate]);

    return isAuthenticated() ? <Outlet /> : null;
};

export default PrivateRoute;