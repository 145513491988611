import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;


/* comment section */
export const createComment = createAsyncThunk(
    "createComment",
    async ({ clipId, body }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${clipId}/`, { body }, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCommentsById = createAsyncThunk(
    "getCommentsById",
    async (clipId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/${clipId}/comments/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteCommentsById = createAsyncThunk(
    "deleteCommentsById",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/clips/delete_comment/${commentId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateCommentsById = createAsyncThunk(
    "updateCommentsById",
    async ({ commentId, body }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.patch(`${mainUrl}api/clips/update_comment/${commentId}/`, { body }, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

/* reply section */
export const getCommentReply = createAsyncThunk(
    "getCommentReply",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/comments/${commentId}/replies/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createCommentReply = createAsyncThunk(
    "createCommentReply",
    async ({ commentId, body }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${commentId}/reply/`, { body }, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteCommentReply = createAsyncThunk(
    "deleteCommentReply",
    async (replyId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/clips/comment/${replyId}/delete_reply/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateCommentReply = createAsyncThunk(
    "updateCommentReply",
    async ({ replyId, body }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.patch(`${mainUrl}api/clips/comment/${replyId}/reply_update/`, { body }, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const clipsCommentSlice = createSlice({
    name: "clipsComment",
    initialState: {
        loading: false,
        isReplyLoading: false,
        commentsData: [],
        replyData: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create comment
        builder.addCase(createComment.pending, (state) => {
            state.error = null;
        });
        builder.addCase(createComment.fulfilled, (state) => {
            state.error = null;
        });
        builder.addCase(createComment.rejected, (state) => {
            state.error = true;
        });

        // Get comments by ID
        builder.addCase(getCommentsById.pending, (state) => {
            state.loading = true;
            state.commentsData = null;
            state.error = null;
        });
        builder.addCase(getCommentsById.fulfilled, (state, action) => {
            state.loading = false;
            state.commentsData = action.payload;
            state.error = null;
        });
        builder.addCase(getCommentsById.rejected, (state) => {
            state.loading = false;
            state.commentsData = null;
            state.error = true;
        });
        // delete comments by ID
        builder.addCase(deleteCommentsById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteCommentsById.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deleteCommentsById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // update comments by ID
        builder.addCase(updateCommentsById.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(updateCommentsById.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updateCommentsById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });


        // GET REPLY BY COMMENT ID
        builder.addCase(getCommentReply.pending, (state) => {
            state.isReplyLoading = true;
            state.error = null;
        });
        builder.addCase(getCommentReply.fulfilled, (state, action) => {
            state.isReplyLoading = false;
            state.replyData = Array.isArray(action.payload) ? action.payload : [];
            state.error = null;
        });
        builder.addCase(getCommentReply.rejected, (state) => {
            state.isReplyLoading = false;
            state.error = true;
        });
        // CREATE REPLY BY COMMENT ID
        builder.addCase(createCommentReply.pending, (state) => {
            state.isReplyLoading = true;
            state.error = null;
        });
        builder.addCase(createCommentReply.fulfilled, (state) => {
            state.isReplyLoading = false;
            state.error = null;
        });
        builder.addCase(createCommentReply.rejected, (state) => {
            state.isReplyLoading = false;
            state.error = true;
        });
        // DELETE  REPLY BY COMMENT ID
        builder.addCase(deleteCommentReply.pending, (state) => {
            state.isReplyLoading = true;
            state.error = null;
        });
        builder.addCase(deleteCommentReply.fulfilled, (state) => {
            state.isReplyLoading = false;
            state.error = null;
        });
        builder.addCase(deleteCommentReply.rejected, (state) => {
            state.isReplyLoading = false;
            state.error = true;
        });
        // UPDATE  REPLY BY COMMENT ID
        builder.addCase(updateCommentReply.pending, (state) => {
            state.isReplyLoading = true;
            state.error = null;
        });
        builder.addCase(updateCommentReply.fulfilled, (state) => {
            state.isReplyLoading = false;
            state.error = null;
        });
        builder.addCase(updateCommentReply.rejected, (state) => {
            state.isReplyLoading = false;
            state.error = true;
        });
    }
});

export default clipsCommentSlice.reducer;