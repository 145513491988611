import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL


export const createVideo = createAsyncThunk(
    "createVideo",
    async (data, { dispatch }) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/videos/`, data, {
            headers: config.headers,
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch(setUploadProgress(percentCompleted));
            },
        });
        const response = await request.data;
        return response;
    }
);

const createVideoSlice = createSlice({
    name: "createVideoSlice",
    initialState: {
        loading: false,
        videoData: null,
        error: null,
        progress: 0,
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
    },
    extraReducers: (builder) => {
        //createVideo
        builder.addCase(createVideo.pending, (state) => {
            state.loading = true;
            state.videoData = null;
            state.error = null;
            state.progress = 0;
        });
        builder.addCase(createVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.videoData = action.payload;
            state.error = null;
            state.progress = 100;
        });
        builder.addCase(createVideo.rejected, (state) => {
            state.loading = false;
            state.videoData = null;
            state.error = true;
            state.progress = 0;
        });
    }
});

export const { setUploadProgress } = createVideoSlice.actions;
export default createVideoSlice.reducer;
