import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import { MdDelete } from "react-icons/md";
import BeatLoader from "react-spinners/BeatLoader";
import { IoNotificationsCircle } from "react-icons/io5";

import {
    getNotifications,
    deleteNotification
} from "../../redux/apislice/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";

const Subscribers = () => {
    const dispatch = useDispatch();
    const [subscribeData, setSubscribeData] = useState([]);
    const { data, loading } = useSelector((state) => state.notification);

    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(data)) {
            setSubscribeData(data);
        }
    }, [data]);

    const handleDeleteNotification = (id) => {
        setSubscribeData(prevData => prevData.filter(item => item.id !== id));
        dispatch(deleteNotification(id));
    };

    return (
        <div className="pt-1">
            <div className="flex items-center gap-2 mb-3">
                <IoNotificationsCircle className="text-white h-6 w-6" />
                <p className="text-white font-semibold text-[20px]">Subscribers</p>
            </div>
            {loading &&
                <div className="flex items-center justify-center">
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            }
            {Array.isArray(subscribeData) && subscribeData.map((data) => (
                <div className="grid grid-cols-12 gap-1 mb-2" key={data.id}>
                    <div className="col-span-2">
                        <img
                            alt="img"
                            src={data.recipient.profile_picture}
                            className="w-[3.6rem] md:w-[2.8rem] h-[3.6rem] md:h-[2.8rem] bg-[#313131] object-cover rounded-full cursor-pointer"
                        />
                    </div>
                    <div className="col-span-9 pl-[8px] md:pl-[4px]">
                        <p className="text-white text-[14px] md:text-[10px] font-semibold line-clamp-1">
                            {data.recipient.full_name}
                        </p>
                        <p className="text-[#6e6e6e] text-[14px] md:text-[10px] leading-[16px] md:leading-[12px] font-semibold line-clamp-2">
                            {data.message}
                        </p>
                        <p className="text-cyan-600 text-[14px] md:text-[10px] mt-1">
                            {format(new Date(data.created_at), "PPpp")}
                        </p>
                    </div>
                    <div className="col-span-1 flex flex-col items-end">
                        <MdDelete
                            onClick={() => handleDeleteNotification(data.id)}
                            className="text-[#fff] hover:text-[#f64e4e] text-[20px] md:text-[16px] cursor-pointer mt-[0px]"
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Subscribers;