import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

const VideoHeader = ({
    title,
    author,
    showTitle,
    setShowTitle,
    menu,
    setMenu,
    navigate,
    downloadVideo,
    video_id
}) => {
    return (
        <div className="grid grid-cols-12 px-2 bg-black">
            <div className="col-span-2 flex justify-start pt-2">
                <IoMdArrowRoundBack
                    onClick={() => navigate(-1)}
                    className="text-white text-[18px] cursor-pointer" />
            </div>
            <div className="col-span-8 flex flex-col items-center">
                <p
                    onClick={() => setShowTitle(!showTitle)}
                    className={`${showTitle ? "line-clamp-none leading-[15px]" : "line-clamp-1"} text-[#ffffff] text-[12px] text-center cursor-pointer`}
                >
                    {title}
                </p>
                <p className="text-[#9b9b9b] text-[12px]">{author}</p>
            </div>
            <div className="col-span-2 flex justify-end pt-2 relative">
                {menu ?
                    <p
                        onClick={() => setMenu(false)}
                        className="text-[#ffffff] text-[12px] font-medium hover:text-red-500 cursor-pointer"
                    >
                        Close
                    </p>
                    :
                    <HiOutlineDotsHorizontal
                        onClick={() => setMenu(!menu)}
                        className="text-white text-[18px] cursor-pointer"
                    />
                }
                {menu &&
                    <div className="absolute top-8 right-0 bg-white py-1 rounded-lg z-10">
                        <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">
                            Save
                        </p>
                        <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">
                            Share
                        </p>
                        <p className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500 border-b border-[#c8c8c8]">
                            Report
                        </p>
                        <p onClick={() => downloadVideo(video_id)} className="text-[11px] font-medium px-3 py-[2px] cursor-pointer hover:text-red-500">
                            Download
                        </p>
                    </div>
                }
            </div>
        </div>
    )
}

export default VideoHeader