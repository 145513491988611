import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const blogList = createAsyncThunk(
    "blogList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/blog/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const blogDetail = createAsyncThunk(
    "blogDetail",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/blog/${id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const blogSlice = createSlice({
    name: "blog",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => { 
        // Blog List
        builder.addCase(blogList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(blogList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(blogList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Blog Detail
        builder.addCase(blogDetail.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(blogDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(blogDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default blogSlice.reducer;