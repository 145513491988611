import React, { Fragment, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { copyright } from "../../utils/copyright";
import { getSavedClips } from "../../redux/apislice/clips/clipsSaveSlice";

const SavedClips = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clipslist = useSelector((state) => state.clipsSaved);
    const { savedData } = clipslist;

    useEffect(() => {
        dispatch(getSavedClips())
    }, [dispatch])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / SAVED CLIPS LIST/ V1 / OCT 14, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Your Clipslist</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 ">
                    <CiSearch
                        onClick={() => navigate("/search")}
                        className="text-white h-5 w-5 cursor-pointer mr-[-2px]"
                    />
                </div>
            </div>

            {/* Saved data will be displayed here */}
            <div className="mt-2 px-2">
                <div className="flex items-center justify-between text-white">
                    <div className="flex items-center gap-2">
                        <p className="text-[14px] font-medium">Total Clipslist</p>
                        <p className="text-[14px] font-bold">{savedData?.saved_clips_count}</p>
                    </div>
                </div>

                <div className="grid grid-cols-12 my-4 gap-2">
                    {savedData?.saved_clips?.map((data, index) => (
                        <div className="col-span-4" key={index}>
                            <video
                                onClick={() => navigate(`/underconstruction`)}
                                disablePictureInPicture autoPlay muted src={data?.video}
                                className="w-full h-[12rem] object-cover rounded-[6px] cursor-pointer" />
                            <div className="flex items-center gap-2 py-1">
                                <img alt="profile" src={data?.created_by?.profile_picture} className="h-[1rem] w-[1rem] rounded-full" />
                                <p className="text-[10px] text-white line-clamp-1">{data?.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default SavedClips