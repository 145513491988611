import { Input, Switch } from "@material-tailwind/react";
import { copyright } from "../../utils/copyright";
import React, { Fragment, useRef, useState } from "react";
import { MdFlipCameraAndroid } from "react-icons/md";

const CreateNews = () => {
    const videoRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [timer, setTimer] = useState(0);
    const timerRef = useRef(null);

    const [newsHeading, setNewsHeading] = useState("");
    const [newsCategory, setNewsCategory] = useState("");
    const [countdown, setCountdown] = useState(0);
    const countdownRef = useRef(null);

    const handleStart = () => {
        if (!newsHeading) return;

        setCountdown(3);
        countdownRef.current = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    clearInterval(countdownRef.current);
                    beginRecording();
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const handleStop = () => {
        setIsRecording(false);
        if (videoRef.current) {
            videoRef.current.pause();
        }
        clearInterval(timerRef.current);
        setTimer(0);
        stopCamera();  // Stop the camera access
    };

    const stopCountdown = () => {
        clearInterval(countdownRef.current);
        setCountdown(0);
    };

    const beginRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play().catch((error) => console.error("Error playing the video: ", error));
                };
            }
            setIsRecording(true);
            timerRef.current = setInterval(() => {
                setTimer((prevTime) => prevTime + 1);
            }, 1000);
        } catch (err) {
            console.error("Error accessing the camera: ", err);
        }
    };

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            console.log("Stopping camera...");
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();

            // Stop each track
            tracks.forEach((track) => {
                try {
                    track.stop();
                    console.log("Track stopped: ", track);
                } catch (error) {
                    console.error("Error stopping track: ", error);
                }
            });

            // Remove stream from video element
            videoRef.current.srcObject = null;

            // Explicitly stop video element to release media resources
            videoRef.current.pause();
            videoRef.current.srcObject = null;

            console.log("Camera stopped successfully.");
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center sticky top-0">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / create news / V1 / 23 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            {!isRecording && countdown === 0 && (
                <p className="text-[25px] text-center font-[500] text-white mt-8">
                    Create News
                </p>
            )}

            {!isRecording && countdown === 0 && (
                <form className="px-4 w-full">
                    <div className="mb-3 mt-14">
                        <Input
                            required
                            label="Add News Heading"
                            color="white"
                            className="text-[14px]"
                            value={newsHeading}
                            onChange={(e) => setNewsHeading(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        {/* <Input
                            label="Add News Category"
                            color="white"
                            className="text-[14px]"
                            value={newsCategory}
                            onChange={(e) => setNewsCategory(e.target.value)}
                        /> */}
                        <div className="relative w-full">
                            <select
                                required
                                onChange={(e) => setNewsCategory(e.target.value)}
                                className="w-full bg-[#000000] px-3 py-[8px] md:py-[8px] text-white text-[14px] border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffffff] appearance-none focus:border-transparent"
                            >
                                <option value={""}>Select Category</option>
                                <option value="Economics">Economics</option>
                                <option value="Crime">Crime</option>
                                <option value="Sports">Sports</option>
                                <option value="Business">Business</option>
                                <option value="Education">Education</option>
                                <option value="Science">Science</option>
                                <option value="Lifestyle">Lifestyle</option>
                                <option value="Share market">Share market</option>
                                <option value="Entertainment">Entertainment</option>
                            </select>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.2" stroke="currentColor" className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700 text-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-white text-[13px]">Private</p>

                        <Switch
                            ripple={true}
                            className="h-4 w-[28.5px] checked:bg-[#0DD315] bg-gray-500"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            type="button"
                            onClick={handleStart}
                            className={`bg-[#F80000] text-white h-[6rem] w-[6rem] rounded-full text-[14px] mt-[5rem] flex items-center justify-center cursor-pointer ${!newsHeading && "opacity-50 cursor-not-allowed"}`}
                            disabled={!newsHeading}
                        >
                            Start
                        </button>
                    </div>
                </form>
            )}

            {countdown > 0 && (
                <div className="flex flex-col items-center justify-center mt-[10rem]">
                    <p className="text-white text-[50px] font-bold">{countdown}</p>
                    <button
                        type="button"
                        onClick={stopCountdown}
                        className="bg-[#F80000] opacity-50 hover:opacity-100 text-white h-[2rem] w-[6rem] rounded text-[14px] flex items-center justify-center cursor-pointer transition-opacity duration-300 ease-in-out"
                    >
                        Cancel
                    </button>
                </div>
            )}

            {isRecording && (
                <div className="relative w-full h-screen md:h-[72.5vh]">
                    <video
                        muted
                        ref={videoRef}
                        disablePictureInPicture
                        className="object-cover h-full w-full"
                    />
                    <div className="absolute w-full bg-black opacity-50 flex flex-col items-center justify-center top-0 px-2">
                        <p className="text-[12px] text-[#b4b4b4]">{newsCategory}</p>
                        <p className="text-[16px] font-[500] text-[#ffffff] mt-[-2px]">{newsHeading}</p>
                    </div>

                    <div className="absolute w-full bottom-3 left-0 flex items-center justify-between px-2">
                        <div className="flex items-center">
                            <div className="h-2 w-2 rounded-full bg-[#F80000] mr-1 animate-blink" />
                            <p className="text-white text-[14px] mr-4">{formatTime(timer)}</p>
                            <button
                                type="button"
                                onClick={handleStop}
                                className="bg-[#F80000] opacity-50 hover:opacity-100 text-white h-[1.5rem] w-[4.5rem] rounded-full text-[12px] flex items-center justify-center cursor-pointer transition-opacity duration-300 ease-in-out"
                            >
                                Stop
                            </button>
                        </div>
                        <MdFlipCameraAndroid className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default CreateNews;
