import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

export const createPlaylist = createAsyncThunk(
    "createPlaylist",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/playlists/`, data, config);
        const response = await request.data;
        return response;
    }
);

export const savePlaylist = createAsyncThunk(
    "savePlaylist",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/playlists-video/`, data, config);
        const response = await request.data;
        return response;
    }
);

export const getPlaylist = createAsyncThunk(
    "getPlaylist",
    async (Id) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/user-playlists/${Id}/`, config);
        const response = await request.data;
        return response;
    }
);

export const getAllPlaylist = createAsyncThunk(
    "getAllPlaylist",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/playlists/`, config);
        const response = await request.data;
        return response;
    }
);

export const updatePlaylist = createAsyncThunk(
    "updatePlaylist",
    async (data) => {
        const config = getAccessConfig();
        const id = data.playlistId;
        const updated = { title: data.title, status: data.status };
        const request = await axios.patch(`${mainUrl}api/playlists/${id}/`, updated, config);
        const response = await request.data;
        return response;
    }
);

export const deletePlaylist = createAsyncThunk(
    "deletePlaylist",
    async (playlistId) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/playlists/${playlistId}/`, config);
        const response = await request.data;
        return response;
    }
);

export const getPlaylistDetails = createAsyncThunk(
    "getPlaylistDetails",
    async (id) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/playlists/${id}/`, config);
        const response = await request.data;
        return response;
    }
);

export const deletePlaylistVideo = createAsyncThunk(
    "deletePlaylistVideo",
    async (id) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/playlists-video/delete/${id}/`, config);
        const response = await request.data;
        return response;
    }
);

const playListSlice = createSlice({
    name: "playlist",
    initialState: {
        loading: false,
        playList: null,
        error: null,
    },
    extraReducers: (builder) => {
        //profileDetails
        builder.addCase(createPlaylist.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(createPlaylist.fulfilled, (state, action) => {
            state.loading = false;
            state.playList = action.payload;
            state.error = null;
        });
        builder.addCase(createPlaylist.rejected, (state) => {
            state.loading = false;
            state.playList = null;
            state.error = true;
        });
        //getPlaylist
        builder.addCase(getPlaylist.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(getPlaylist.fulfilled, (state, action) => {
            state.loading = false;
            state.playList = action.payload;
            state.error = null;
        });
        builder.addCase(getPlaylist.rejected, (state) => {
            state.loading = false;
            state.playList = null;
            state.error = true;
        });

        //getPlaylistDetails
        builder.addCase(getPlaylistDetails.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(getPlaylistDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.playList = action.payload;
            state.error = null;
        });
        builder.addCase(getPlaylistDetails.rejected, (state) => {
            state.loading = false;
            state.playList = null;
            state.error = true;
        });
        //savePlaylist
        builder.addCase(savePlaylist.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(savePlaylist.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(savePlaylist.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //updatePlaylist
        builder.addCase(updatePlaylist.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(updatePlaylist.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(updatePlaylist.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //deletePlaylist
        builder.addCase(deletePlaylist.pending, (state) => {
            state.loading = true;
            state.playList = null;
            state.error = null;
        });
        builder.addCase(deletePlaylist.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deletePlaylist.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        //deletePlaylistVideo
        builder.addCase(deletePlaylistVideo.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deletePlaylistVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(deletePlaylistVideo.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    }
});

export default playListSlice.reducer;
