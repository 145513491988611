import React, { Fragment, useState } from "react";
import { copyright } from "../utils/copyright";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { livestreamData } from "../data";
import { CiStreamOn } from "react-icons/ci";
import FourDotDrawer from "../components/common/FourDotDrawer";

const LiveStream = () => {
    const navigate = useNavigate();
    const [openVideoInfo, setOpenVideoInfo] = useState(null);

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Live Stream / V1 / 24 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-3 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack className="text-white h-5 w-5 cursor-pointer ml-[-3px]" onClick={() => navigate(-1)} />
                    <p className="text-white text-[16px] font-medium">Livestreams</p>
                </div>
            </div>

            <div className="bg-black h-full overflow-y-scroll mt-2">
                {livestreamData.map((data) => (
                    <div className="pb-2" key={data.id}>
                        <img
                            alt="thumbnail"
                            src={data.thumbnail}
                            onClick={() => navigate(`/video/${30}`)}
                            className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                        />
                        <div className="grid grid-cols-12 mt-1 px-2">
                            <div className="col-span-2">
                                <img
                                    alt="profile"
                                    className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                    src={data.channelLogo}
                                />
                                <div className="flex flex-col items-center justify-start ml-[-12px]">
                                    <p className="text-white text-[13px]">{data.views}</p>
                                    <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                </div>
                            </div>
                            <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                                <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                                <p className="text-[#8d8d8d] text-[12px]">{data.channel} - {data.days}</p>
                                <div className="flex items-center gap-1 px-2 py-[2px] rounded bg-[#F80000]">
                                    <CiStreamOn className="text-[13px] text-white" />
                                    <p className="text-[10px] text-white">live</p>
                                </div>
                            </div>
                            <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                <p
                                    onClick={() => handleToggle(data.id)}
                                    className="text-white text-[14px] cursor-pointer"
                                >
                                    : :
                                </p>
                            </div>
                        </div>

                        {openVideoInfo === data.id && (
                            <FourDotDrawer
                                title={data.title}
                                onClose={() => handleToggle(data.id)}
                            />
                        )}
                    </div>
                ))}
            </div>
        </Fragment>
    )
}

export default LiveStream