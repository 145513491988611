import React, { Fragment } from "react";

import { GoEye } from "react-icons/go";
import { FaUserAlt } from "react-icons/fa";
import { FaRegCalendarDays } from "react-icons/fa6";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { mainHomePageData } from "../../data";

const Default = () => {
    return (
        <Fragment>
            <div className="pt-1">
                <p className="text-white text-[16px] font-bold tracking-wider">
                    New Videos
                </p>
                <div className="pt-1">
                    <Carousel
                        ssr={true}
                        arrows={false}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={{
                            desktop: {
                                breakpoint: { max: 3000, min: 1024 },
                                items: 2.5,
                                slidesToSlide: 1
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 2.5,
                                slidesToSlide: 1
                            },
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 2.5,
                                slidesToSlide: 1
                            }
                        }}
                    >
                        {mainHomePageData.map((data) => (
                            <div className="select-none w-[95%] md:w-[6.8rem]" key={data.id}>
                                <img
                                    alt="playlist"
                                    src={data.thumbnail}
                                    className="w-full h-[5rem] md:h-[4.4rem] object-cover rounded-sm cursor-pointer"
                                />
                                <div className="mt-1">
                                    <p className="text-[12px] md:text-[10px] text-[#ffffff] line-clamp-1 cursor-pointer">
                                        {data.title}
                                    </p>
                                    <div className="flex items-center gap-x-[6px]">
                                        <FaUserAlt className="text-[11px] text-[#e1e1e1]" />
                                        <p className="text-[12px] md:text-[10px] ml-[1px] text-[#9a9a9a] line-clamp-1 cursor-pointer">
                                            {data.channel}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-[6px]">
                                        <GoEye className="text-[12px] text-[#e1e1e1]" />
                                        <p className="text-[12px] md:text-[10px] text-[#9a9a9a] line-clamp-1 cursor-pointer">
                                            {data.views} Views
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-[6px]">
                                        <FaRegCalendarDays className="text-[12px] text-[#e1e1e1]" />
                                        <p className="text-[12px] md:text-[10px] mt-[2px] text-[#9a9a9a] line-clamp-1 cursor-pointer">
                                            {data.date}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>

            <div className="absolute left-0 h-[0.4px] w-full bg-[#434343] mt-3" />

            <div className="pt-5">
                <p className="text-white text-[16px] font-bold tracking-wider">
                    Reccomended Channels
                </p>
                <div className="pt-1">
                    <Carousel
                        ssr={true}
                        arrows={false}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={{
                            desktop: {
                                breakpoint: { max: 3000, min: 1024 },
                                items: 4.5,
                                slidesToSlide: 1
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 4.5,
                                slidesToSlide: 1
                            },
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 4.5,
                                slidesToSlide: 1
                            }
                        }}
                    >
                        {mainHomePageData.map((data) => (
                            <div className="select-none" key={data.id}>
                                <img
                                    alt="playlist"
                                    src={data.channelLogo}
                                    className="w-[4rem] h-[4rem] md:w-[3.5rem] md:h-[3.5rem] object-cover rounded-full cursor-pointer"
                                />
                                <div className="w-[4rem] md:w-[3.5rem] flex items-center justify-center mt-1">
                                    <p className="text-[12px] md:text-[10px] text-[#ffffff] line-clamp-1 cursor-pointer">
                                        {data.channel}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </Fragment>
    )
}

export default Default