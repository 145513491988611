import React, { Fragment, useState } from "react";
import { FaHeart } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import { HiDownload } from "react-icons/hi";
import { GoBookmark } from "react-icons/go";
import { MdOutlineReport } from "react-icons/md";

const VideoControls = ({
    title,
    author,
    authorId,
    authorProfile,
    viewCount = 0,
    likeCount = 0,
    description,
    handleNavigate,
    handleClickSub,
    subscribe,
    userSubscribeList,
    showDescription,
    setShowDescription,
    showComment,
    setShowComment,
    handleLike,
    userLiked = false,
    downloadVideo,
    comments,
    video_id,
    handleToggle
}) => {
    const [liked, setLiked] = useState(userLiked);
    const [like_Count, setLike_Count] = useState(likeCount)

    const handleChangeLike = () => {
        handleLike(!liked)
        setLiked(!liked)
        if (!liked) {
            setLike_Count(like_Count + 1)
        } else {
            setLike_Count(like_Count - 1)
        }
    }

    const commentData = comments?.filter((_, index) => index < 1)

    return (
        <div className="px-2">
            <div className="grid grid-cols-12 gap-y-0 gap-2">
                <div className="col-span-10">
                    <p className="text-[12px] text-[#ffffff] leading-[15px] mt-[2px]">{title}</p>
                </div>
                <div className="col-span-2 flex justify-end items-start">
                    <button onClick={() => handleChangeLike()} className={`bg-white p-1 rounded-md mt-[2px]`}>
                        <FaHeart className={` ${liked ? "text-red-600" : ""} text-[12px] hover:text-red-600`} />
                    </button>
                </div>
                <div className="col-span-8 flex items-center gap-2 py-1">
                    <img
                        alt={"profile"}
                        src={authorProfile}
                        onClick={() => handleNavigate(authorId)}
                        className="h-8 w-8 rounded-full object-cover cursor-pointer"
                    />
                    <button
                        onClick={() => handleClickSub()}
                        className={`${!subscribe ? "bg-white text-black px-3 py-[4px] " : "border border-white text-white bg-black px-3 py-[2px]"}  cursor-pointer font-medium rounded-full text-[10px]`}
                    >
                        {!subscribe ? "Subscribe" : "Unsubscribe"}
                        <p className="text-[9px] mt-[-3px] w-full text-center">
                            {userSubscribeList?.subscribers_count ? userSubscribeList?.subscribers_count : null}
                        </p>
                    </button>
                    <p
                        onClick={() => setShowDescription(!showDescription)}
                        className="text-blue-600 hover:text-blue-500 text-[10px] cursor-pointer"
                    >
                        {showDescription ? "Hide Details" : "More Details"}
                    </p>
                </div>
                <div className="col-span-4 pt-[10px]">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-1 text-[#9b9b9b]">
                            <FaEye className="text-[12px]" />
                            <p className="text-[12px]">{viewCount}</p>
                        </div>
                        <div className="flex items-center gap-1 text-[#9b9b9b]">
                            <FaHeart className="text-[12px]" />
                            <p className="text-[12px] text-center">{like_Count}</p>
                        </div>
                    </div>
                </div>
                {showDescription &&
                    <div className="col-span-12 bg-[#353535] my-1 p-2 rounded-lg">
                        <p className="text-[10px] text-white">
                            {description}
                        </p>
                    </div>
                }
                <div className="col-span-12 flex items-center justify-between pt-1">
                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                        <RiShareForwardLine className="text-[14px]" />
                        <p className="text-[10px] ml-1" onClick={() => handleToggle(video_id)}>Share</p>
                    </div>
                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                        <HiDownload className="text-[14px]" />
                        <p className="text-[10px] ml-1" onClick={() => downloadVideo(video_id)}>Download</p>
                    </div>
                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                        <GoBookmark className="text-[14px]" />
                        <p className="text-[10px] ml-1">Save</p>
                    </div>
                    <div className="bg-[#353535] text-[#ffffff] flex items-center px-2 py-[2px] rounded-full cursor-pointer">
                        <MdOutlineReport className="text-[14px]" />
                        <p className="text-[10px] ml-1">Report</p>
                    </div>
                </div>
                <div className="col-span-12 pt-2">
                    <div className="bg-[#353535] p-2 rounded-lg">
                        {comments?.length > 0 &&
                            <p p className="text-[10px] text-white flex items-center justify-end">{comments.length} Comments</p>}
                        <div className="grid grid-cols-12">

                            {commentData?.length > 0 && commentData?.map((data) => (
                                <Fragment key={data.id}>
                                    <div className="col-span-2">
                                        <img
                                            alt="user"
                                            src={data.author.profile_picture}
                                            className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                        />
                                    </div>
                                    <div className="col-span-9 -ml-3">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.author.full_name}</p>
                                        <p className="text-[#b2b2b2] text-[10px] leading-[12px] line-clamp-2">
                                            {data.content}
                                        </p>
                                    </div>
                                </Fragment>
                            ))

                            }
                            <div className="col-span-12 mt-2">
                                <p
                                    onClick={() => setShowComment(!showComment)}
                                    className="bg-[#242424] text-white text-[10px] text-center py-[6px] rounded-lg cursor-pointer"
                                >
                                    Type here to view or add comment
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default VideoControls