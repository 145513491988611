import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const getDraftPosts = createAsyncThunk(
    "getDraftPosts",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/posts/draft/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const createPostDraftSlice = createSlice({
    name: "DraftSlice",
    initialState: {
        loading: false,
        draftData: [],
        error: null,
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
        setUploadingData: (state, action) => {
            state.videoData = action.payload.videoData;
            state.videoDescription = action.payload.videoDescription;
        }
    },
    extraReducers: (builder) => {
        // get draft posts
        builder.addCase(getDraftPosts.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getDraftPosts.fulfilled, (state, action) => {
            state.loading = false;
            state.draftData = action.payload;
            state.error = null;
        });
        builder.addCase(getDraftPosts.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

    }
});

export default createPostDraftSlice.reducer;