import React, { useCallback, useEffect, useState } from 'react'

export default function FilterHeader({ data = [], setSortedResults }) {
    const [activeFilter, setActiveFilter] = useState('')


    const filterItems = useCallback((filterType = "") => {
        let sorted = Array.isArray(data) ? [...data] : [];
        setActiveFilter(filterType)
        switch (filterType) {
            case "Recently Added":
                sorted.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                return setSortedResults(sorted)

            case "Recently Played":
                return setSortedResults(sorted)

            case "A-Z":
                sorted.sort((a, b) => a.title?.localeCompare(b.title));
                return setSortedResults(sorted)

            case "Z-A":
                sorted.sort((a, b) => b?.title.localeCompare(a.title));
                return setSortedResults(sorted)

            default:
                return setSortedResults(sorted)
        }

    }, [data ,setSortedResults])

    useEffect(() => {
        filterItems()
    }, [filterItems, setSortedResults])

    return (
        <div className="flex items-center justify-between my-2">
            <p
                onClick={() => filterItems("Recently Added")}
                className={`${activeFilter === "Recently Added" ? "bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
            >
                Recently Added
            </p>
            <p
                onClick={() => filterItems("Recently Played")}
                className={`${activeFilter === "Recently Played" ? "bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
            >
                Recently Played
            </p>
            <p
                onClick={() => filterItems("A-Z")}
                className={` ${activeFilter === "A-Z" ? "bg-gray-600 text-white" : "bg-white"}   px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
            >
                A-Z
            </p>
            <p
                onClick={() => filterItems("Z-A")}
                className={` ${activeFilter === "Z-A" ? "bg-gray-600 text-white" : "bg-white"}  px-3 py-[2px] rounded-md text-[10px] cursor-pointer hover:opacity-80`}
            >
                Z-A
            </p>
        </div>

    )
}
