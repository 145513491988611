import React, { Fragment, useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FaLock, FaUnlock, FaRegNewspaper } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import handLogo from "../assets/footer/hand_footer_icon.png";
import fireLogo from "../assets/footer/fire_footer_icon.png";
import homeLogo from "../assets/footer/home_footer_icon.png";
import plusLogo from "../assets/footer/plus_footer_icon.png";
import { CiStreamOn } from "react-icons/ci";
import { MdOutlinePodcasts, MdOutlineMovieFilter } from "react-icons/md";
import {
    RiVideoAddFill,
    // RiVideoUploadFill, 
    RiImageAddFill
} from "react-icons/ri";
import { useSelector } from "react-redux";

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLocked, setIsLocked] = useState(true);
    const [isCreatePopup, setIsCreatePopup] = useState(false);
    const popupRef = useRef(null);
    const { randomClipsId } = useSelector(state => state.clips);
    console.log(randomClipsId, "randomClipsId")

    const handleCreatePopup = () => {
        setIsCreatePopup(!isCreatePopup);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsCreatePopup(false);
        }
    };

    useEffect(() => {
        if (isCreatePopup) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isCreatePopup]);


    useEffect(() => {
        setIsCreatePopup(false);
        return () => {
            setIsCreatePopup(false);
        }
    }, [location.pathname]);

    const createOptions = [
        { icon: RiVideoAddFill, label: "Create Video", path: "/createvideo" },
        // { icon: RiVideoUploadFill, label: "Upload Video", path: "/uploadvideo" },
        { icon: MdOutlineMovieFilter, label: "Create Clips", path: "/createclips" },
        { icon: RiImageAddFill, label: "Create Post", path: "/createpost" },
        { icon: FaRegNewspaper, label: "Create News", path: "/createnews" },
        { icon: MdOutlinePodcasts, label: "Create Podcast", path: "/create/podcast" },
        { icon: CiStreamOn, label: "Go Live", path: "/golive" }
    ];

    return (
        <Fragment>
            <div className="grid grid-cols-12 items-center text-white py-[8px] bg-[#272727] px-3">
                <div className="col-span-8">
                    <button
                        onClick={() => navigate("/underconstruction")}
                        className="text-black bg-[#FFF500] text-[16px] md:text-[13px] font-bold w-[95%] py-[7px] cursor-pointer rounded-[5px] outline-none border-none"
                    >
                        Secret sauce panel
                    </button>
                </div>
                <div className="col-span-4">
                    <button
                        onClick={() => navigate("/underconstruction")}
                        className="text-black bg-[#E4080A] text-[16px] md:text-[13px] font-bold w-full py-[7px] cursor-pointer rounded-[5px] outline-none border-none"
                    >
                        Invite
                    </button>
                </div>
            </div>
            <div className="bg-[#0DD315] flex items-center justify-between px-3 py-2">
                {isLocked ?
                    <FaLock className="w-[20px] h-[20px] md:w-[17px] md:h-[17px] cursor-pointer" onClick={() => setIsLocked(!isLocked)} />
                    :
                    <FaUnlock className="w-[20px] h-[20px] md:w-[17px] md:h-[17px] cursor-pointer" onClick={() => setIsLocked(!isLocked)} />
                }
                <img src={plusLogo} alt="plus" className="w-6 h-6 md:w-5 md:h-5 cursor-pointer" onClick={handleCreatePopup} />
                <img src={homeLogo} alt="home" className="w-7 h-7 md:w-6 md:h-6 cursor-pointer" onClick={() => navigate("/home")} />
                <img src={handLogo} alt="clips" className="w-7 h-7 md:w-6 md:h-6 cursor-pointer" onClick={() => navigate(`/clips/${randomClipsId?.selected_id}`)} />
                <img src={fireLogo} alt="trending" className="w-7 h-7 md:w-6 md:h-6 cursor-pointer" onClick={() => navigate("/trending")} />
            </div>

            {isCreatePopup && (
                <div ref={popupRef} className="absolute w-full h-[25vh] bg-[#1a1a1a] bottom-[5.6rem] rounded-t-xl z-50">
                    <div className="px-2 sticky top-0 z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#343434]">
                        <p className="text-white text-[14px]">Create</p>
                        <IoClose className="text-white text-[20px] cursor-pointer" onClick={() => setIsCreatePopup(false)} />
                    </div>

                    <div className="grid grid-cols-12 gap-3 mt-2">
                        {createOptions.map((option, index) => (
                            <div
                                key={index}
                                // className={`col-span-${index === createOptions.length - 1 ? "12" : "4"} flex flex-col items-center`}
                                className={`col-span-${index === createOptions.length - 1 ? "4" : "4"} flex flex-col items-center`}
                                onClick={() => navigate(option.path)}
                            >
                                <option.icon className="w-[25px] h-[25px] text-[#dedede] hover:text-[#ffffff] cursor-pointer" />
                                <p className="text-[#dedede] hover:text-[#ffffff] cursor-pointer text-[12px]">
                                    {option.label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default Footer;
