import React, { Fragment, useState, useEffect, useCallback } from "react";
import { copyright } from "../utils/copyright";
import FourDotDrawer from "../components/common/FourDotDrawer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { videoList } from "../redux/apislice/videoListSlice";
// import BeatLoader from "react-spinners/BeatLoader";
import Cookies from 'js-cookie';
import { getAllUserSubscription } from "../redux/apislice/subscriptionSlice";
import SavePlaylist from "../components/common/SavePlaylist";
// import LazyLoad from 'react-lazyload';
import Skeleton from "react-loading-skeleton";
import { getClipsDetails, randomClips } from "../redux/apislice/clips/clipsSlice";
import Share from "../components/clips/Share";
import { blockChannel, blockVideo } from "../redux/apislice/blockSlice";
import toast, { Toaster } from "react-hot-toast";
import { getDownload } from "../redux/apislice/downloadSlice";
import { addWatchList } from "../redux/apislice/watchListSlice";

const HomePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const videoListData = useSelector((state) => state.videoList);
    const [openVideoInfo, setOpenVideoInfo] = useState(null);
    const [videoData, setVideoData] = useState([]);
    const userId = Cookies.get("userId");
    const [savePlaylistModal, setSavePlaylistModal] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const [showEmpty, setShowEmpty] = useState(false);





    const handleSavePlaylist = () => {
        setSavePlaylistModal(true);
    }

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    useEffect(() => {
        if (videoListData?.videoData?.results) {
            const sortedData = videoListData.videoData.results.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setVideoData(sortedData);
        }
        if (videoData?.length === 0 && !videoListData.isLoading) {
            setTimeout(() => {
                setShowEmpty(true)
            }, 1000);
        }
    }, [videoListData, videoData?.length]);


    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }


    const handleNavigate = (id) => {
        if (id === userId) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${id}`)
        }
    }

    const fetchVideoData = useCallback(async () => {
        await dispatch(videoList());
        await dispatch(randomClips()).then((res) => {
            console.log(res, "resres")
            if (res?.payload?.selected_id) {
                dispatch(getClipsDetails(res?.payload?.selected_id));
            }
        })
    }, [dispatch]);


    const handleShare = () => {
        setShareVisible(!shareVisible);
        if (!shareVisible) {
            setOpenVideoInfo(null)
        }
    }
    const handleBlockChannel = async (channelId) => {
        if (channelId === userId) {
            return toast('You cannot block yourself',
                {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        } else {
            await dispatch(blockChannel(channelId)).then((res) => {
                toast('Channel Blocked Successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setVideoData(videoData.filter((data) => data.author.id !== channelId));
            })
        }
    }
    const handleAddWatchLater = async (videoId) => {
        try {
            await dispatch(addWatchList(videoId)).then((result) => {
                if (result.error) {
                    toast('Already Added to Watch Later',
                        {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );

                }
                else {
                    setTimeout(setOpenVideoInfo(null), 500);

                    toast('saved to watch later',
                        {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                        }
                    );
                }
            })

        } catch (error) {
            toast.error("Video already in Watch Later list",)
            console.log(error?.data?.message, "error");
        }
    }
    const handleDownload = async (videoId) => {
        await dispatch(getDownload(videoId)).then((res) => {
            if (res?.error) {
                toast('Already downloaded',
                    {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
            } else {
                toast('downloaded successfully',
                    {
                        icon: '✅',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                    }
                );
                setTimeout(setOpenVideoInfo(null), 500);
            }
        })
    }
    const handleNotIntrested = async (videoId) => {
        await dispatch(blockVideo(videoId)).then((res) => {
            toast('Removed from feed',
                {
                    icon: '✅',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
            setVideoData(videoData.filter((data) => data.id !== videoId));
        })

    }


    const [videoDurations, setVideoDurations] = useState({});

    const formatDuration = (duration) => {
        const seconds = Math.floor(duration % 60);
        const minutes = Math.floor((duration % 3600) / 60);
        const hours = Math.floor(duration / 3600);

        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedHours = hours > 0 ? `${hours}:` : '';

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
    };

    useEffect(() => {
        videoData.forEach((data) => {
            const videoElement = document.createElement('video');
            videoElement.src = data.video;

            const handleLoadedMetadata = () => {
                setVideoDurations((prevDurations) => ({
                    ...prevDurations,
                    [data.id]: formatDuration(videoElement.duration),
                }));
            };

            videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

            // Clean up the event listener
            return () => {
                videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            };
        });
    }, [videoData]);


    useEffect(() => {
        fetchVideoData();
    }, [fetchVideoData]);

    useEffect(() => {
        dispatch(getAllUserSubscription())
    }, [dispatch, userId]);

    return (
        <Fragment>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <div className="sticky top-0 z-10">
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-600 text-[9px] uppercase font-bold">
                        KV / HOME / V3 / 25 SEP 2024
                    </p>
                    <p className="text-black text-[9px] mt-[-2px]">
                        {copyright}
                    </p>
                </div>
                <Header />
            </div>
            <div className="bg-black h-full overflow-y-scroll">
                {videoListData.isLoading &&
                    [1, 2, 3, 4, 5, 6, 7].map((_, index) => (<div key={index} className="grid grid-cols-12 px-2 py-1">
                        <div className="col-span-12">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                        </div>
                        <div className="col-span-2">
                            <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "2rem", width: "2rem" }} />
                        </div>
                        <div className="col-span-9 flex flex-col justify-center -ml-2">
                            <div>
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "60%" }} />
                            </div>
                            <div className="-mt-10">
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "100%" }} />
                            </div>
                        </div>
                        <div className="col-span-1 flex justify-end">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "12px", width: "12px", borderRadius: "1px", }} />
                        </div>
                    </div>
                    ))}
                {!videoListData.isLoading && videoData?.map((data) => {
                    console.log(data.video, "data")

                    return (
                        <div className="pb-2" key={data.id}>
                            <div className="relative">
                                {/* <LazyLoad height={200} offset={100}> */}

                                <img
                                    alt="thumbnail"
                                    preload="metadata"
                                    src={data.thumbnail}
                                    onClick={() => navigate(`/video/${data.id}`)}
                                    className="w-full px-2 h-[13rem] md:h-[10rem] object-cover rounded-sm cursor-pointer"
                                    picture-in-picture="false"
                                />
                                {/* </LazyLoad> */}
                                <div className="absolute bottom-1 left-[12px]">
                                    <p className="px-2 py-[1px] rounded-full text-[9px] bg-[#ffffff8f] font-bold">
                                        {videoDurations[data.id] ? `${videoDurations[data.id]}` : null}
                                    </p>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-1 px-2">
                                <div className="col-span-2 flex flex-col items-start">
                                    <img
                                        alt="profile"
                                        src={data.author.profile_picture}
                                        onClick={() => handleNavigate(data?.author.id)}
                                        className="h-10 w-10 md:h-8 md:w-8 rounded-full object-cover cursor-pointer"
                                    />
                                    <div className="flex flex-col items-center justify-center ml-[6px] md:ml-[2px]">
                                        <p className="text-white text-[13px]">{data.views_count}</p>
                                        <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                    </div>
                                </div>
                                <div className="col-span-9 ml-[-10px] md:ml-[-6px] flex flex-col items-start leading-[18px]">
                                    <p className="text-white text-[16px] md:text-[14px] font-light line-clamp-1">{data.title}</p>
                                    <p className="text-[#8d8d8d] text-[14px] md:text-[12px]">{data?.author.handle} - {timeAgo(data.created_at)}</p>
                                </div>
                                <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                    <p
                                        onClick={() => handleToggle(data.id)}
                                        className="text-white text-[18px] md:text-[14px] cursor-pointer"
                                    >
                                        : :
                                    </p>
                                </div>
                            </div>
                            {openVideoInfo === data.id && (
                                <FourDotDrawer
                                    title={data.title}
                                    videoId={data.id}
                                    savePlaylist={() => handleSavePlaylist()}
                                    onClose={() => handleToggle(data.id)}
                                    handleShare={handleShare}
                                    handleBlockChannel={handleBlockChannel}
                                    channelId={data?.author.id}
                                    handleDownload={handleDownload}
                                    handleNotIntrested={handleNotIntrested}
                                    handleAddWatchLater={handleAddWatchLater}
                                />
                            )}
                            {/* Share */}
                            {shareVisible &&
                                <div className="absolute top-10  h-full w-full">
                                    <Share
                                        mainurl={"https://kindviewer.com/video/"}
                                        handleShare={handleShare}
                                        id={data.id}
                                    />
                                </div>
                            }
                            {savePlaylistModal && openVideoInfo === data.id && (
                                <div className="absolute top-0 z-50 h-full w-full">
                                    <SavePlaylist onClose={() => setSavePlaylistModal(false)} title={data.title} videoId={data.id} />
                                </div>
                            )}
                        </div>)
                }
                )}
                {videoData?.length === 0 && !videoListData.isLoading && showEmpty &&
                    <div className="text-white text-[20px] my-36 h-full text-center flex gap-2 flex-col justify-center items-center">
                        <div>
                            <p className="text-[5rem]">😢</p>
                            <p className="text-[12px]">No Videos Found</p>
                        </div>

                        <button className="bg-red-900 text-[12px] p-2 px-4 rounded-3xl hover:bg-red-400" onClick={() => navigate("/createvideo")}>
                            Create your First Video
                        </button>
                    </div>}
            </div>
        </Fragment>
    );
};

export default HomePage;
