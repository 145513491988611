import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { reportClips } from "../../redux/apislice/clips/clipsReport";

const Report = ({ setIsReported, handleReport, isReported, clipsId }) => {
   const [selectedReport, setSelectedReport] = useState(null); // Track the selected report
   const [errorMessage, setErrorMessage] = useState(null); // Track error messages
   const dispatch = useDispatch();

   const reportMessage = [
      { id: 1, message: "Inappropriate Content", value: "inappropriate" },
      { id: 2, message: "Spam or Advertising", value: "spam" },
      { id: 3, message: "Harassment or Hate Speech", value: "harassment" },
      { id: 4, message: "False Information", value: "False Information" },
      { id: 5, message: "Adult Content", value: "Adult Content" },
      { id: 6, message: "Against Children", value: "Against Children" },
      { id: 7, message: "Legal Related", value: "Legal Related" },
      { id: 8, message: "Support terrorist activities", value: "Support terrorist activities" }
   ];

   const handleReportSubmit = () => {
      if (selectedReport) {
         const body = { reason: selectedReport };

         dispatch(reportClips({ clipId: clipsId, body }))
            .unwrap() // Unwraps the result to handle fulfilled and rejected cases
            .then(() => {
               setIsReported(true); // Show the thank you message
               setErrorMessage(null); // Clear any previous errors
               console.log(`Report Submitted for Clip ${clipsId} with reason: ${selectedReport}`);
            })
            .catch((error) => {
               // Set the error message from the Redux payload
               setErrorMessage(error.payload?.detail || "You have already reported this clip.");
               console.error("Failed to report the clip:", error);
            });
      }
   };

   return (
      <div className={`absolute w-full overflow-y-scroll bottom-[7.5rem] md:bottom-[7.1rem] 
         ${isReported ? "h-[25vh] md:h-[25vh]" : "h-[42vh]"} bg-black rounded-t-2xl`}
      >
         <div className="px-3 md:px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-3 md:pt-2 pb-2 md:pb-1 border-b-[0.4px] border-[#262626]">
            <p className="text-white text-[16px] md:text-[14px]">Report</p>
            <IoClose
               onClick={() => {
                  setIsReported(false); // Reset the report state
                  handleReport(); // Close the modal
               }}
               className="text-white text-[22px] md:text-[20px] cursor-pointer"
            />
         </div>

         {!isReported ? (
            <div className="mt-2 px-3 md:px-2 text-white">
               {reportMessage.map((data) => (
                  <label
                     key={data.id}
                     className="flex items-center text-[15px] md:text-[13px] mb-2"
                  >
                     <input
                        type="radio"
                        value={data.value}
                        checked={selectedReport === data.value}
                        onChange={(e) => setSelectedReport(e.target.value)}
                        className="mr-2 accent-light-blue-600 focus:accent-light-blue-800 cursor-pointer"
                     />
                     {data.message}
                  </label>
               ))}

               {errorMessage && (
                  <p className="text-[16px] md:text-[14px] text-center lowercase font-thin text-red-600 mt-2">
                     {errorMessage}
                  </p>
               )}
            </div>
         ) : (
            <div className="px-3 md:px-2 my-2 flex flex-col items-center justify-center pb-4">
               <p className="text-[25px] uppercase font-bold text-white">
                  Thank You
               </p>
               <p className="text-[16px] md:text-[14px] lowercase font-thin text-white">
                  Your message has been submitted
               </p>
            </div>
         )}

         <div className="flex items-center justify-center my-3 pb-4">
            <button
               onClick={() => {
                  if (isReported) {
                     setIsReported(false); // Reset the report state
                     handleReport(); // Close the modal
                  } else {
                     handleReportSubmit(); // Submit the report
                  }
               }}
               disabled={!selectedReport && !isReported}
               className={`px-4 md:px-3 py-[6px] md:py-1 text-[16px] md:text-[12px] rounded-full outline-none border-none
                  ${!selectedReport && !isReported ? "bg-gray-500" : "bg-[#ebebeb] hover:bg-[#ffffff]"}`
               }
            >
               {isReported ? "Close" : "Submit"}
            </button>
         </div>
      </div>
   );
}

export default Report;
