import React from "react"
import { IoClose } from "react-icons/io5";

const VideoDetails = ({
    handleVideoDetails,
    videoData,
    currentIndex,
    isSubscribed,
    setIsSubscribed
}) => {
    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[35vh] bg-black rounded-t-2xl">
            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                <p className="text-white text-[14px]">Description</p>
                <IoClose
                    onClick={handleVideoDetails}
                    className="text-white text-[20px] cursor-pointer"
                />
            </div>
            <div className="flex px-2 pt-2 items-center justify-between">
                <div className="flex items-center">
                    <img
                        alt="account"
                        className="h-9 w-9 rounded-full object-cover bg-black cursor-pointer"
                        src={videoData[currentIndex].channelLogo}
                    />
                    <div className="flex flex-col ml-2">
                        <p className="text-white text-[12px] font-semibold">{videoData[currentIndex].channelName}</p>
                        <p className="text-[#717171] text-[10px]">{videoData[currentIndex].subscriber}</p>
                    </div>
                </div>
                <button
                    onClick={() => setIsSubscribed(!isSubscribed)}
                    className="text-[12px] text-white border border-[#ffffff] px-2 py-[2px] rounded-full"
                >
                    {isSubscribed ? "Unsubscribe" : "Subscribe"}
                </button>
            </div>
            <div className="flex items-center justify-evenly pt-4">
                <div className="flex flex-col items-center leading-[14px]">
                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].likes}</p>
                    <p className="text-[12px] text-[#717171]">Likes</p>
                </div>
                <div className="flex flex-col items-center leading-[14px]">
                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].views}</p>
                    <p className="text-[12px] text-[#717171]">Views</p>
                </div>
                <div className="flex flex-col items-center leading-[14px]">
                    <p className="text-[12px] text-[#ffffff]">{videoData[currentIndex].uploaded}</p>
                    <p className="text-[12px] text-[#717171]">Uploaded</p>
                </div>
            </div>
            <p className="text-[12px] leading-[14px] text-white px-2 pt-4 pb-2">
                {videoData[currentIndex].title}
            </p>
            <div className="flex flex-wrap items-center gap-x-2 px-2">
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#livenews</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#life</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#viral</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#enjoy</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#happy</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#fun</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#smile</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#joy</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#happiness</p>
                <p className="cursor-pointer text-light-blue-700 hover:text-light-blue-500 text-[12px]">#positive</p>
            </div>
        </div>
    )
}

export default VideoDetails