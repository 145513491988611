import React, { useEffect, useRef, useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";

import Likes from "./Likes";
import Default from "./Default";
import Comments from "./Comments";
import Mentions from "./Mentions";
import Messages from "./Messages";
import Analytics from "./Analytics";
import Subscribers from "./Subscribers";

// import { IoIosSettings } from "react-icons/io";
// import { IoFilterCircle } from "react-icons/io5";

const Notification = () => {
    const lable = "KV / FE / Notification / V1 / 10 Sep, 2024";

    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(true);
    // const [showFilter, setShowFilter] = useState(false);
    // const [showSettings, setShowSettings] = useState(false);
    const [activeComponent, setActiveComponent] = useState("default");

    // const menuRef = useRef(null);
    // const toggleButtonRef = useRef(null);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    // const handleFilterPopUp = () => {
    //     setShowFilter(!showFilter);
    //     setShowSettings(false);
    // }

    // const handleSettingsPopUp = () => {
    //     setShowSettings(!showSettings);
    //     setShowFilter(false);
    // }

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (
    //             menuRef.current &&
    //             !menuRef.current.contains(event.target) &&
    //             !toggleButtonRef.current.contains(event.target)
    //         ) {
    //             setShowFilter(false);
    //             setShowSettings(false);
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [menuRef]);

    return (
        <div className="h-full">
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>
            <div className="sticky top-0">
                <div className="grid grid-cols-12 items-center px-2 bg-white">
                    <div className="col-span-2 flex items-center justify-start">
                        <h1
                            onClick={() => navigate("/home")}
                            className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                        >
                            kv
                        </h1>
                    </div>
                    <div className="col-span-8 flex items-center justify-center">
                        <p className="text-black text-[16px] font-medium" onClick={() => setActiveComponent("default")}>Notifications</p>
                    </div>
                    <div className="col-span-2 flex items-center justify-end">
                        <label className="flex cursor-pointer select-none items-center ml-3">
                            <div className="relative">
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                    className="sr-only"
                                />
                                <div className={`block h-5 w-9 rounded-full ${isChecked ? "bg-green-600" : "bg-black"}`}>

                                </div>
                                <div className={`absolute left-[2px] top-[2px] flex h-4 w-4 items-center justify-center text-[7px] rounded-full bg-white transition ${isChecked ? "translate-x-full" : ""}`}>
                                    {isChecked ? "on" : "off"}
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <div className="grid grid-cols-3 gap-3 px-2 pt-3 pb-2 border-b-[0.4px] border-[#434343] bg-black">
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("likes")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "likes" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Likes</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("comments")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "comments" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Comments</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("subscribers")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "subscribers" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Subscribers</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("mentions")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "mentions" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Mentions</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("analytics")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "analytics" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Analytics</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => setActiveComponent("messages")}
                            className={`flex items-center justify-center px-1 py-1 rounded-md cursor-pointer 
                                ${activeComponent === "messages" ? "bg-[#29e52f] text-white" : "bg-white text-black"}
                            `}
                        >
                            <p className="text-[14px] md:text-[10px] font-medium">Messages</p>
                        </div>
                    </div>
                    {/* {activeComponent === "default" ? null :
                        <div className="col-span-3 relative" ref={toggleButtonRef}>
                            <div className="flex items-center justify-between">
                                <IoFilterCircle
                                    onClick={handleFilterPopUp}
                                    className="h-6 w-6 text-white cursor-pointer"
                                />
                                <IoIosSettings
                                    onClick={handleSettingsPopUp}
                                    className="h-6 w-6 text-white cursor-pointer"
                                />
                            </div>

                            {showFilter &&
                                <div ref={menuRef} className="absolute top-8 left-0 bg-white px-2 py-1 rounded-lg">
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Latest Notifications</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Older Notifications</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">Sort by date</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-between gap-3">
                                        <p className="text-[12px]">A - Z</p>
                                        <label class="relative flex justify-between items-center group py-2 text-xl">
                                            <input type="checkbox" class="absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none rounded-md" />
                                            <span class="w-[27.5px] h-[15px] pl-[2px] flex items-center flex-shrink-0 p-1 bg-gray-300 rounded-full duration-300 ease-in-out peer-checked:bg-green-400 after:w-3 after:h-3 after:bg-white after:rounded-full after:shadow-md after:duration-300 peer-checked:after:translate-x-3"></span>
                                        </label>
                                    </div>
                                </div>
                            }

                            {showSettings &&
                                <div ref={menuRef} className="absolute top-8 right-0 bg-white py-1 rounded-lg">
                                    <p className="text-[12px] px-3 py-[6px] cursor-pointer hover:text-[#464646]">
                                        Highlight Unread
                                    </p>
                                    <p className="text-[12px] px-3 pb-[6px] cursor-pointer hover:text-[#464646]">
                                        Show Categories
                                    </p>
                                    <p className="text-[12px] px-3 pb-[6px] cursor-pointer hover:text-[#464646]">
                                        Delete Notification
                                    </p>
                                </div>
                            }
                        </div>
                    } */}
                </div>
            </div>

            <div className="px-2 pt-1 overflow-y-scroll">
                {activeComponent === "default" && <Default />}
                {activeComponent === "likes" && <Likes />}
                {activeComponent === "comments" && <Comments />}
                {activeComponent === "subscribers" && <Subscribers />}
                {activeComponent === "mentions" && <Mentions />}
                {activeComponent === "analytics" && <Analytics />}
                {activeComponent === "messages" && <Messages />}
            </div>
        </div>
    )
}

export default Notification