import React, { Fragment, useEffect, useRef, useState } from "react";
import { IoMdClose, IoMdHeart, IoMdHeartDislike } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CommentView = ({
    closeComment,
    showInput,
    showCommentInput,
    showComment,
    handleSubmitComment,
    handleChangeComment,
    commentText,
    setCommentText,
    getVideoComments,
    DeleteComment,
    likeComment,
    dislikeComment,
    getCommentReplies,
    comments = [],
    replies = [],
    loading,
    editComment,
    setEditComment,
    setReplyId,
    setReply,
    reply,
    replyId,
    commentId,
    setCommentId,
    handleCreateReply,
    repliesLoading,
    handleDeleteReply,
    setEditReply,
    editReply = false
}) => {
    const navigate = useNavigate();
    const [openReplyId, setOpenReplyId] = useState(null);
    const replyRef = useRef(null);
    const commentMenuRef = useRef(null);
    const [commentMenu, setCommentMenu] = useState(null)
    const replyMenuRef = useRef(null);
    const [replyMenu, setReplyMenu] = useState(null)
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile;


    const handleEditComment = (commentId) => {
        console.log(commentId, "commentId", showCommentInput)
        setEditComment(true)
        setCommentId(commentId)
        setCommentText((() => comments.find((comment) => comment.id === commentId).content))
        if (!showCommentInput) {
            showInput()
        }
    }
    const handleCancelEdit = () => {
        setEditComment(false)
        showInput()
        setCommentId(null)
        setCommentText("")
    }

    const toggleReplies = (id) => {
        if (openReplyId !== id) {
            getCommentReplies(id)
        }
        setOpenReplyId((prev) => (prev === id ? null : id));
    };

    const handleReply = (id) => {
        setReplyId(id);
        setCommentId(id)
        setEditReply(false)
        setReply("")
    }

    const handleEditReply = (id, comment_id) => {
        setEditReply(true)
        setReplyId(id)
        if (showCommentInput) {
            showInput()
        }
        setReply((() => replies.find((reply) => reply.id === id).content))
        setCommentId(comment_id)
    }
    const hanldeCancelEditReply = () => {
        setEditReply(false)
        setReplyId(null)
        setReply("")
    }

    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((replyRef.current && !replyRef.current.contains(event.target)) || (commentMenuRef.current && !commentMenuRef.current.contains(event.target)) ||
                (replyMenuRef.current && !replyMenuRef.current.contains(event.target)
                )) {
                if (!editReply) {
                    setReplyId(null);
                }
                setReplyMenu(null);
                setCommentMenu(null);
                // setOpenReplyId(null);
                // }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editReply, setReplyId]);

    useEffect(() => {
        getVideoComments()
    }, [getVideoComments])

    const handleNavigate = (id) => {
        if (id === userDetails?.id) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${id}`)
        }
    }

    return (
        <Fragment>
            <div className="px-2 mb-2 h-[33rem] overflow-y-scroll">
                <div className="bg-[#353535] p-1 rounded-lg">
                    <div className="flex items-center justify-between my-2">
                        <button className="text-[12px] font-semibold bg-[#ffffff] px-2 py-1 rounded-full" onClick={showInput}>
                            Add Comments
                        </button>
                        <IoMdClose
                            onClick={closeComment}
                            className="text-[20px] text-white cursor-pointer"
                        />
                    </div>

                    {!loading && Array.isArray(comments) && comments.map((data) => {
                        const { id, content,
                            author, creator,
                            is_user_liking,
                            is_user_disliking,
                            ratings
                        } = data
                        return (

                            <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-1" key={id}>
                                <div className="col-span-2">
                                    <img
                                        alt={author.handle}
                                        src={author.profile_picture}
                                        onClick={() => handleNavigate(creator)}
                                        className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-10 -ml-3 relative">
                                    <div className="flex justify-between items-center">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{author.handle}</p>
                                        <div className="">
                                            <BsThreeDots color="white" className="cursor-pointer" onClick={() => setCommentMenu(id)} />
                                        </div>
                                    </div>
                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                        {content}
                                    </p>
                                    <div className="flex items-center mt-1">
                                        <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer" onClick={() => handleReply(id)}>Reply</p>
                                        <IoMdHeart onClick={() => is_user_liking ? dislikeComment(ratings[0].id) : likeComment(id, "Like")} className={`h-3 w-3 ml-4  cursor-pointer ${is_user_liking ? "text-lime-500" : "text-white"} hover:text-lime-500`} />
                                        <IoMdHeartDislike onClick={() => is_user_disliking ? dislikeComment(ratings[0].id) : likeComment(id, "Dislike")} className={`h-3 w-3 ml-2 cursor-pointer ${is_user_disliking ? "text-red-600" : "text-white"} hover:text-red-600`} />
                                        <p
                                            onClick={() => toggleReplies(id)}

                                            className="text-light-blue-900 hover:text-light-blue-800 text-[10px] ml-6 font-semibold cursor-pointer"
                                        >
                                            {openReplyId === id ? "Hide" : "View"} Replies
                                        </p>
                                    </div>
                                    {openReplyId === id &&
                                        <>
                                            {!repliesLoading && replies?.map((res) => {
                                                const { content, author, created_at } = res
                                                return (
                                                    <div className="grid grid-cols-12 mt-2">
                                                        <div className="col-span-2">
                                                            <img
                                                                alt={author?.full_name}
                                                                src={author?.profile_picture}
                                                                onClick={() => handleNavigate(author?.id)}
                                                                className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                                            />
                                                        </div>
                                                        <div className="col-span-10">
                                                            <div className="flex items-center justify-between gap-x-4 relative">
                                                                <div className="flex items-center gap-x-4">
                                                                    <p className="text-white text-[12px] font-semibold mt-[-2px]">{author?.full_name}</p>
                                                                    <p className="text-[10px] text-[#636363]">{timeAgo(created_at)}</p>
                                                                </div>
                                                                <BsThreeDots color="white" className="cursor-pointer" onClick={() => setReplyMenu(res.id)} />

                                                                {replyMenu === res.id &&
                                                                    <div ref={replyMenuRef} className="absolute right-0 z-30 top-4 text-[10px] w-auto bg-white py-1 rounded-md">
                                                                        <p className="px-2 hover:bg-black hover:text-white text-black cursor-pointer" onClick={() => handleEditReply(res.id, id)} >
                                                                            Edit
                                                                        </p>
                                                                        <p onClick={() => handleDeleteReply(res.id, id)} className="px-2 hover:bg-black hover:text-white text-black cursor-pointer" >
                                                                            Delete
                                                                        </p>
                                                                    </div>}
                                                            </div>
                                                            <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                                                {content}
                                                            </p>
                                                        </div>
                                                    </div>)

                                            })}

                                            {repliesLoading &&
                                                [1, 2, 3,].map(() => (
                                                    <div className="my-1 rounded-md">
                                                        <Skeleton baseColor="#202020" style={{ width: "100%", height: "2rem" }} highlightColor="#444" />
                                                    </div>
                                                ))
                                            }
                                            {!repliesLoading && replies?.length <= 0 && (
                                                <div className="flex items-center justify-center h-20">
                                                    <p className="text-white text-[12px]">No replies yet</p>
                                                </div>
                                            )}
                                        </>


                                    }
                                    {commentMenu === id &&
                                        <div ref={commentMenuRef} className="absolute right-0 top-4 text-[10px] w-auto bg-black py-1 rounded-md">
                                            <p className="px-2 hover:bg-gray-600 text-white cursor-pointer" onClick={() => handleEditComment(data.id)} >
                                                Edit
                                            </p>
                                            <p onClick={() => DeleteComment(id)} className="px-2 hover:bg-gray-600 text-white cursor-pointer" >
                                                Delete
                                            </p>
                                            <p className="px-2 hover:bg-gray-600 text-white cursor-pointer" >
                                                Report
                                            </p>
                                        </div>}

                                </div>
                            </div>
                        )
                    }

                    )}
                    {loading &&
                        [1, 2, 3, 4, 5, 6].map(() => (
                            <div className="my-1 rounded-md">
                                <Skeleton baseColor="#202020" style={{ width: "100%", height: "4rem" }} highlightColor="#444" />
                            </div>
                        ))
                    }
                    {!loading && comments?.length <= 0 && (
                        <div className="flex items-center justify-center h-20">
                            <p className="text-white text-[12px]">No comments yet</p>
                        </div>
                    )}
                </div>
            </div>

            {showCommentInput &&
                <>
                    {!showComment && (
                        <div className="sticky bottom-[5.5rem]  left-0 w-full bg-[#4e4e4e] px-3 py-2 flex flex-col gap-2 items-start">
                            {/* input field for comment */}
                            {editComment && (
                                <p onClick={() => handleCancelEdit()} className="text-red-500 w-full cursor-pointer text-end text-[12px] font-bold ml-2 rounded-md">
                                    Close Edit
                                </p>
                            )}

                            {!replyId &&
                                (<div className="flex items-center w-full">
                                    <input
                                        type="text"
                                        placeholder="Add a comment..."
                                        onChange={(e) => handleChangeComment(e)}
                                        value={commentText}
                                        className="flex-1 py-1 px-2 bg-[#353535] text-white rounded-md focus:outline-none"
                                    />
                                    <button onClick={() => handleSubmitComment(editComment, commentId)} className="bg-blue-500 text-white px-2 py-[7px] text-[12px] ml-2 rounded-md">
                                        Send
                                    </button>
                                </div>)
                            }
                        </div>
                    )}
                </>
            }

            {replyId && (
                <div ref={replyRef} className="sticky bottom-[5.5rem] left-0 w-full bg-[#4e4e4e] px-3 py-2 flex flex-col items-center">
                    {editReply && (
                        <p onClick={() => hanldeCancelEditReply()} className="text-red-500 w-full cursor-pointer text-end text-[12px] font-bold ml-2 rounded-md">
                            Close Edit
                        </p>
                    )}
                    <div className="flex items-center w-full" ref={replyRef}>
                        <input
                            type="text"
                            placeholder="Add a reply..."
                            onChange={(e) => setReply(e.target.value)}
                            value={reply}
                            className="flex-1 py-1 px-2 bg-[#353535] text-white rounded-md focus:outline-none"
                        />
                        <button onClick={(event) => handleCreateReply(event)} className="bg-blue-500 text-white px-2 py-[7px] text-[12px] ml-2 rounded-md">
                            Send
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default CommentView;
