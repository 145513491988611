import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const recoveryPass = createAsyncThunk(
    "recoveryPass",
    async (data) => {
        const request = await axios.post(`${mainUrl}api/recover-password/`, data);
        const response = await request.data;
        return response;
    }
);

export const verifyOtp = createAsyncThunk(
    "verifyOtp",
    async (data) => {
        const request = await axios.post(`${mainUrl}api/verify-otp/`, data);
        const response = await request.data;
        return response;
    }
);

export const resetPassword = createAsyncThunk(
    "resetPassword",
    async (data) => {
        const request = await axios.post(`${mainUrl}api/password-reset/`, data);
        const response = await request.data;
        return response;
    }
);

const recoveryPassSlice = createSlice({
    name: "recoveryPass",
    initialState: {
        loading: false,
        data: null,
        error: null,
    },
    extraReducers: (builder) => {
        //recoveryPass
        builder.addCase(recoveryPass.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(recoveryPass.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(recoveryPass.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        //verifyOtp
        builder.addCase(verifyOtp.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(verifyOtp.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(verifyOtp.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        //resetPassword
        builder.addCase(resetPassword.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(resetPassword.rejected, (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
    }
});

export default recoveryPassSlice.reducer;
