import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const createEvent = createAsyncThunk(
    "createEvent",
    async (formData, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/calenderkv/events/add/`, formData, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const allEventList = createAsyncThunk(
    "allEventList",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}/api/calenderkv/events/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteEvent = createAsyncThunk(
    "deleteEvent",
    async (eventId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            await axios.delete(`${mainUrl}api/calenderkv/events/delete/${eventId}/`, config);
            return eventId; // Return the eventId instead of response
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const CalendarSlice = createSlice({
    name: "calendar",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create Event
        builder.addCase(createEvent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.data.push(action.payload);
        });
        builder.addCase(createEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // All Event List
        builder.addCase(allEventList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(allEventList.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(allEventList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Delete Event
        builder.addCase(deleteEvent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteEvent.fulfilled, (state, action) => {
            state.loading = false;
            state.data = state.data.filter(event => event.id !== action.payload); // Use event ID from action.payload
        });
        builder.addCase(deleteEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default CalendarSlice.reducer;
