import React, { useEffect, useState } from "react";
import { MdOutlineSecurity } from "react-icons/md";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { securityLogin } from "../redux/apislice/securitySlice";

import PulseLoader from "react-spinners/PulseLoader";
import toast, { Toaster } from 'react-hot-toast';

const DustParticles = () => {
   useEffect(() => {
      const createParticle = () => {
         const particle = document.createElement("div");
         particle.className = "particle";
         document.body.appendChild(particle);

         particle.style.left = `${Math.random() * 100}vw`;
         particle.style.animationDuration = `${Math.random() * 5 + 5}s`;

         setTimeout(() => {
            particle.remove();
         }, 10000);
      };

      const interval = setInterval(createParticle, 500);

      return () => clearInterval(interval);
   }, []);

   return null;
};

const SecurityPage = () => {
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");

   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.security);

   const handleEvent = (e) => {
      e.preventDefault();
      let loginDetails = { email, password };

      dispatch(securityLogin(loginDetails)).then((result) => {
         if (result.payload) {
            toast.success("Login Successful");
            setTimeout(() => navigate("/login"), 1000);
         } else {
            toast.error("Invalid Credentials");
         }
      });
   }

   return (
      <div className="bg-[#040415] h-full flex items-start justify-center px-4 pt-[50%] relative">
         <DustParticles />
         <Toaster
            position="top-right"
            reverseOrder={false}
         />
         <form
            onSubmit={handleEvent}
            autoComplete="off"
            className="flex flex-col gap-y-4 p-4 pt-6 rounded-[10px] border-[2px] border-[#9a9a9a] hover:border-[#ffffff] w-full transition-all duration-500 hover:shadow-2xl"
         >
            <div className="flex items-center justify-center gap-x-1 mt-[-1rem]">
               <MdOutlineSecurity className="text-[24px] md:text-[20px] text-[#ffffff]" />
               <p className="text-[24px] md:text-[20px] font-bold uppercase text-center text-[#ffffff]">
                  Security Login
               </p>
            </div>
            <input
               required
               autoComplete="off"
               color="white"
               name="email"
               type="email"
               value={email}
               variant="standard"
               placeholder="Enter your username"
               onChange={(e) => setEmail(e.target.value)}
               className="p-2 rounded-lg outline-none bg-[#f1f1f1] focus:bg-[#ffffff] placeholder:text-[14px] transition-all duration-500"
            />
            <input
               required
               autoComplete="off"
               color="white"
               name="password"
               type="password"
               value={password}
               variant="standard"
               placeholder="Enter your password"
               onChange={(e) => setPassword(e.target.value)}
               className="p-2 rounded-lg outline-none bg-[#f1f1f1] focus:bg-[#ffffff] placeholder:text-[14px] transition-all duration-500"
            />
            <Button
               size="md"
               fullWidth
               color="white"
               type="submit"
               className="mt-3"
            >
               {loading ?
                  <PulseLoader
                     color={"#000"}
                     loading={loading}
                     size={10}
                     aria-label="Loading Spinner"
                     data-testid="loader"
                  />
                  : "continue"
               }
            </Button>
         </form>
      </div>
   );
};

export default SecurityPage;