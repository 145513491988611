import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createPost = createAsyncThunk(
    "createPost",
    async (data, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}/api/posts/create/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCreatePosts = createAsyncThunk(
    "getCreatePosts",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}/api/posts/user/posts/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);



const createPostSlice = createSlice({
    name: "createPost",
    initialState: {
        loading: false,
        post: [],
        error: null,
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
        setUploadingData: (state, action) => {
            state.videoData = action.payload.videoData;
            state.videoDescription = action.payload.videoDescription;
        }
    },
    extraReducers: (builder) => {
        // Create post
        builder.addCase(createPost.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createPost.fulfilled, (state, action) => {
            state.loading = false;
            state.post = action.payload;
            state.error = null;
        });
        builder.addCase(createPost.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.progress = 0
        });
        // get posts
        builder.addCase(getCreatePosts.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCreatePosts.fulfilled, (state, action) => {
            state.loading = false;
            state.post = action.payload;
            state.error = null;
        });
        builder.addCase(getCreatePosts.rejected, (state) => {
            state.loading = false;
            state.error = true;
            state.progress = 0
        });
    }
});

export default createPostSlice.reducer;