import React from "react";
import { SocialIcon } from "react-social-icons";
import { IoClose } from "react-icons/io5";

const Share = ({ handleShare }) => {
    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[20vh] bg-black rounded-t-2xl">
            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                <p className="text-white text-[14px]">Share</p>
                <IoClose
                    onClick={handleShare}
                    className="text-white text-[20px] cursor-pointer"
                />
            </div>
            <div className="flex items-center justify-between gap-1 p-2">
                <SocialIcon network="facebook" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="whatsapp" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="instagram" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="x" style={{ height: 40, width: 40 }} className="cursor-pointer" bgColor="#1e1e1e" />
                <SocialIcon network="pinterest" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="linkedin" style={{ height: 40, width: 40 }} className="cursor-pointer" />
            </div>
            <div className="flex items-center justify-between gap-1 p-2">
                <SocialIcon network="telegram" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="threads" style={{ height: 40, width: 40 }} className="cursor-pointer" bgColor="#1e1e1e" />
                <SocialIcon network="email" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="discord" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="reddit" style={{ height: 40, width: 40 }} className="cursor-pointer" />
                <SocialIcon network="sharethis" style={{ height: 40, width: 40 }} className="cursor-pointer" />
            </div>
        </div>
    )
}

export default Share