import React, { Fragment, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
// import { padcastData.results
// ? } from "../data";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import MainMenu from "../components/common/MainMenu";
import SecondMenu from "../components/common/SecondMenu";
import FourDotDrawer from "../components/common/FourDotDrawer";

import { FaSearch } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoNotifications } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import { getAllPodcast } from "../redux/apislice/podcast/podcastSlice";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3.5,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3.5,
        slidesToSlide: 1
    }
};

const Podcast = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile;
    const userId = Cookies.get("userId");

    const [openVideoInfo, setOpenVideoInfo] = useState(null);
    const [activeCategory, setActiveCategory] = useState(""); // Track active category

    const podcast = useSelector((state) => state.podcast);
    const { loading, podcast: podcastData } = podcast;

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };
    const timeAgo = (timestamp) => {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    // Filter podcasts based on active category
    const filteredPodcasts = activeCategory
        ? podcastData.results?.filter((data) => data?.podcast_genre === activeCategory)
        : podcastData.results;

    useEffect(() => {
        dispatch(getAllPodcast());
    }, [dispatch, userId])

    console.log(filteredPodcasts, "filteredPodcasts")
    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Podcast / V1 / 24 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-4 flex items-center justify-between gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <FaSearch className="text-black text-[18px] cursor-pointer" />
                    <FaCirclePlus className="text-black text-[18px] m-[4px] cursor-pointer" onClick={() => navigate("/create/podcast")} />
                </div>
                <div className="col-span-4 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">Podcasts</p>
                </div>
                <div className="col-span-4 flex items-center justify-between">
                    <img
                        alt="profile"
                        onClick={() => navigate("/account")}
                        src={userDetails?.profile_picture}
                        className="w-[1.3rem] h-[1.3rem] object-cover rounded-full cursor-pointer"
                    />
                    <div className="relative">
                        <IoNotifications
                            onClick={() => navigate("/notification")}
                            className="text-[22px] md:text-[20px] cursor-pointer"
                        />
                        <div className="absolute -top-[5px] -right-[6px] flex items-center justify-center h-[13px] w-[13px] rounded-full bg-[#f83131]">
                            <p className="text-[8px] text-white">5</p>
                        </div>
                    </div>
                    <MainMenu />
                </div>
            </div>

            <SecondMenu />

            <div className="px-2 pt-2 pb-2 bg-black sticky top-0">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    className="select-none"
                >
                    {["Tech", "Politics", "Hollywood", "Sports", "Currencies"].map((category) => (
                        <p
                            key={category}
                            onClick={() => handleCategoryClick(category)}
                            className={`text-[11px] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer ${activeCategory === category
                                ? "bg-green-600 text-white"
                                : "bg-[#353535] text-white hover:bg-[#3e3e3e]"
                                }`}
                        >
                            {category}
                        </p>
                    ))}
                </Carousel>
            </div>

            <div className="bg-black h-full overflow-y-scroll">
                {loading &&
                    [1, 2, 3, 4, 5, 6, 7].map((_, index) => (<div key={index} className="grid grid-cols-12 px-2 py-1">
                        <div className="col-span-12">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10rem", width: "100%" }} />
                        </div>
                        <div className="col-span-2">
                            <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "2rem", width: "2rem" }} />
                        </div>
                        <div className="col-span-9 flex flex-col justify-center -ml-2">
                            <div>
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "60%" }} />
                            </div>
                            <div className="-mt-10">
                                <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "10px", width: "100%" }} />
                            </div>
                        </div>
                        <div className="col-span-1 flex justify-end">
                            <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "12px", width: "12px", borderRadius: "1px", }} />
                        </div>
                    </div>
                    ))}

                {filteredPodcasts?.length > 0 ? (
                    filteredPodcasts.map((data) => (
                        <div className="pb-2" key={data.id}>
                            <img
                                alt="thumbnail"
                                src={data.thumbnail}
                                onClick={() => navigate(`/podcast/video/${data?.id}`)}
                                className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer"
                            />
                            {/* <video src={data.video} onClick={() => navigate(`/podcast/video/${data?.id}`)} className="w-full px-2 h-[10rem] object-cover rounded-sm cursor-pointer" /> */}
                            <div className="grid grid-cols-12 mt-1 px-2">
                                <div className="col-span-2">
                                    <img
                                        alt="profile"
                                        className="h-8 w-8 rounded-full object-cover cursor-pointer"
                                        src={data?.creator_profile_picture}
                                    />
                                    <div className="flex flex-col items-center justify-start ml-[-12px]">
                                        <p className="text-white text-[13px]">{data.view_count}</p>
                                        <p className="text-[#8d8d8d] text-[12px] mt-[-6px]">views</p>
                                    </div>
                                </div>
                                <div className="col-span-9 ml-[-6px] flex flex-col items-start leading-[18px]">
                                    <p className="text-white text-[14px] font-light line-clamp-1">{data.host_name}</p>
                                    <p className="text-[#8d8d8d] text-[12px]">{data.creator_channel_name} - {timeAgo(data.created_at)}</p>
                                </div>
                                <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                    <p
                                        onClick={() => handleToggle(data.id)}
                                        className="text-white text-[14px] cursor-pointer"
                                    >
                                        : :
                                    </p>
                                </div>
                            </div>

                            {openVideoInfo === data.id && (
                                <FourDotDrawer
                                    title={data.title}
                                    onClose={() => handleToggle(data.id)}
                                    savePlaylist={() => (() => handleToggle(data.id))}
                                    handleShare={(() => handleToggle(data.id))}
                                    handleBlockChannel={(() => handleToggle(data.id))}
                                    channelId={(() => handleToggle(data.id))}
                                    handleDownload={(() => handleToggle(data.id))}
                                    handleNotIntrested={(() => handleToggle(data.id))}
                                    handleAddWatchLater={(() => handleToggle(data.id))}
                                />
                            )}
                        </div>
                    ))
                ) : (!loading && <p className="text-[#4d4d4d] px-1 text-[14px] text-center mt-4">No results found</p>)}
            </div>
        </Fragment>
    );
}

export default Podcast;
