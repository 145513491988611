import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { createSubscription, DeleteSubscription, getAllUserSubscription, getUserSubscription } from "../../redux/apislice/subscriptionSlice";

const Subscribe = ({ currentIndex, videoData, isSubscribed, setIsSubscribed, handleVideoDetails, filteredVideo }) => {

    const dispatch = useDispatch();
    const subscriptionlist = useSelector((state) => state.subscription);
    const { subscribeList } = subscriptionlist;
    const profile = useSelector((state) => state.profile);
    const { userDetails } = profile;
    console.log(filteredVideo, "filteredVideo")
    const { created_by, description } = filteredVideo[0] || {};
    const subscribe = subscribeList?.results?.some((data) => data?.channel.id === created_by?.id);

    const handleSubscribe = async () => {
        try {
            await dispatch(createSubscription({ channel_id: created_by?.id, user: userDetails?.id, notifications: "all" })).then((res) => {
                if (res.error) {
                    console.log(res, "Subscription failed");
                }
                else {
                    dispatch(getUserSubscription(res.payload.channel.id))
                    dispatch(getAllUserSubscription())
                }
            })

        } catch (error) {
            console.log(error, "Subscription failed");

        }
    }

    const deleteSubscribe = () => {
        const filterData = subscribeList?.results?.filter((data) => data.channel.id === created_by?.id);
        const chennel_id = filterData[0]?.id;
        setIsSubscribed(!isSubscribed);
        dispatch(DeleteSubscription(chennel_id)).then((result) => {
            if (result.error) {
                console.log(result, "Subscription failed");
            } else {
                // dispatch(getUserSubscription(result?.payload?.channel?.id))
                dispatch(getAllUserSubscription())
            }
        });
    }


    return (
        <div className="absolute w-full bottom-[9rem] md:bottom-[8rem] flex flex-col items-center justify-center">
            <div className="flex rounded-[6px] bg-[#00000065] px-2 py-2 md:py-1 mx-6" onClick={handleVideoDetails}>
                <div className="grid grid-cols-12">
                    <div className="col-span-2 flex items-center justify-start">
                        <img
                            alt="account"
                            className="h-9 w-9 md:h-7 md:w-7 rounded-full object-cover bg-black cursor-pointer"
                            src={created_by?.profile_picture}
                        />
                    </div>
                    <div className="col-span-10 w-[10rem] flex items-center ml-[5px]">
                        <p
                            onClick={handleVideoDetails}
                            className="text-[12px] md:text-[9.5px] line-clamp-2 leading-[14px] md:leading-[11.5px] text-white"
                        >
                            {description}
                        </p>
                    </div>
                </div>
            </div>
            <button
                onClick={() => subscribe ? deleteSubscribe() : handleSubscribe()}
                className="text-[16px] md:text-[13px] bg-[#0DD315] text-white w-[8rem] font-semibold py-2 md:py-1 outline-none border-none"
            >
                {subscribe ? "Unsubscribe" : "Subscribe"}
            </button>
        </div>
    )
}

export default Subscribe