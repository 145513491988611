import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import comingsoon from "../assets/comingsoon.png";

const ComingSoon = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(-1);
        }, 7000);

        // Clean up the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center h-full bg-gradient-to-r from-teal-400 to-gray-800">
            <img 
                src={comingsoon}
                alt="Coming Soon"
                className="w-80 h-80 object-cover rounded-full mb-4"
            />
            <p className="text-gray-300 text-[13px] mb-4 leading-4 px-5 text-center">
                We are working hard to bring you something amazing. Stay tuned!
            </p>
            <button
                onClick={() => navigate(-1)}
                className=" bg-cyan-600 rounded-full px-7 py-2 text-gray-100 hover:text-white uppercase font-semibold transition-all duration-300"
            >
                Go Back
            </button>
        </div>
    );
}

export default ComingSoon;
