import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const searchResult = createAsyncThunk(
    "searchResult",
    async (searchData, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/search/?q=${searchData}`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            console.error("Error fetching search results:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const searchHistory = createAsyncThunk(
    "searchHistory",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/search-history/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            console.error("Error fetching search history:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const searchHistoryDeleteById = createAsyncThunk(
    "searchHistoryDeleteById",
    async (id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/delete-search-history/${id}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            console.error("Error deleting search history:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const searchHistoryDeleteAll = createAsyncThunk(
    "searchHistoryDeleteAll",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/delete-search-history/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            console.error("Error deleting search history:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const popularSearch = createAsyncThunk(
    "popularSearch",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/trending-search-videos/`, config);
            const response = await request.data;
            return response.results;
        } catch (error) {
            console.error("Error fetching popular search:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const searchSlice = createSlice({
    name: "search",
    initialState: {
        loading: false,
        data: [],
        searchHistory: [],
        popularSearchData: [],
        error: null,
    },
    reducers: {
        resetSearchState: (state) => {
            state.loading = false;
            state.data = [];
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // Search result
        builder.addCase(searchResult.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(searchResult.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(searchResult.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Search history
        builder.addCase(searchHistory.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(searchHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.searchHistory = action.payload;
            state.error = null;
        });
        builder.addCase(searchHistory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Delete search history by ID
        builder.addCase(searchHistoryDeleteById.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(searchHistoryDeleteById.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(searchHistoryDeleteById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Delete all search history
        builder.addCase(searchHistoryDeleteAll.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(searchHistoryDeleteAll.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        });
        builder.addCase(searchHistoryDeleteAll.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Popular search
        builder.addCase(popularSearch.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(popularSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.popularSearchData = action.payload;
            state.error = null;
        });
        builder.addCase(popularSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export const { resetSearchState } = searchSlice.actions;
export default searchSlice.reducer;