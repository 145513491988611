import axios from "axios";
import getAccessConfig from "../config";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const contactUs = createAsyncThunk(
    "contactUs",
    async (formData, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/contact-us/`, formData, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        //contactUs
        builder.addCase(contactUs.pending, (state) => {
            state.loading = true;
            state.userDetails = null;
            state.error = null;
        });
        builder.addCase(contactUs.fulfilled, (state, action) => {
            state.loading = false;
            state.userDetails = action.payload;
            state.error = null;
        });
        builder.addCase(contactUs.rejected, (state) => {
            state.loading = false;
            state.userDetails = null;
            state.error = true;
        });
    }
});

export default contactSlice.reducer;