import axios from "axios";
import getAccessConfig from "../../config";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL;

export const getClipsList = createAsyncThunk(
    "getClipsList",
    async (userId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/user-clips/${userId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const postedClipsSlice = createSlice({
    name: "postedClipsSlice",
    initialState: {
        loading: false,
        postedClips: [],
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(getClipsList.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getClipsList.fulfilled, (state, action) => {
            state.loading = false;
            state.postedClips = action.payload;
            state.error = null;
        });
        builder.addCase(getClipsList.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
    },
});

export default postedClipsSlice.reducer;