import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { FaDownload } from "react-icons/fa6";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaShareAlt, FaComment } from "react-icons/fa";
import { MdOutlineBookmark, MdReport } from "react-icons/md";
import { BsFillHandThumbsUpFill, BsFillHandThumbsDownFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getAllLikesList, getAllDislikesList, likeClips, likeRemove, dislikeClips, dislikeRemove } from "../../redux/apislice/clips/clipsLikeSlice";
import { removeSavedClips, saveClips } from "../../redux/apislice/clips/clipsSaveSlice";
import { viewClips } from "../../redux/apislice/clips/clipsSlice";
// import { getTagsClips } from "../../redux/apislice/clips/clipsTagSlice";

const Controls = ({ videoData , filteredVideo, currentIndex, handleShare, handleReport, handleComment, handleDownload, setSavedClips, savedClips  ,clipsId}) => {
    const [expandedMenuIndex, setExpandedMenuIndex] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const dispatch = useDispatch();
    const userId = Cookies.get("userId");
    const { comments_count } = filteredVideo

    useEffect(() => {
        if (clipsId) {
            dispatch(getAllLikesList(clipsId));
            dispatch(getAllDislikesList(clipsId));
            dispatch(viewClips(clipsId));
            // dispatch(getTagsClips())
        }
    }, [dispatch, videoData, currentIndex ,clipsId]);

    const likesCount = useSelector(state => state.clipsLike.likesCount);
    const dislikesCount = useSelector(state => state.clipsLike.dislikesCount);
    const likedData = useSelector(state => state.clipsLike.LikeData);
    const dislikedData = useSelector(state => state.clipsLike.dislikeData);

    useEffect(() => {
        if (likedData && likedData.likes.some(like => like.user === userId)) {
            setIsLiked(true);
        } else {
            setIsLiked(false);
        }
    }, [likedData, userId]);

    useEffect(() => {
        if (dislikedData && dislikedData.some(dislike => dislike.user === userId)) {
            setIsDisliked(true);
        } else {
            setIsDisliked(false);
        }
    }, [dislikedData, userId]);

    // THIS FUNCTION IS USED TO HANDLE THE LIKE FUNCTIONALITY
    const handleLike = () => {
        if (isLiked) {
            dispatch(likeRemove(clipsId)).then(() => {
                dispatch(getAllLikesList(clipsId));
                dispatch(getAllDislikesList(clipsId));
            });
        } else {
            if (isDisliked) {
                dispatch(dislikeRemove(clipsId));
            }
            dispatch(likeClips(clipsId)).then(() => {
                dispatch(getAllLikesList(clipsId));
                dispatch(getAllDislikesList(clipsId));
            });
        }
        setIsLiked(!isLiked);
        setIsDisliked(false);
    };

    // THIS FUNCTION IS USED TO HANDLE THE DISLIKE FUNCTIONALITY
    const handleDislike = () => {
        if (isDisliked) {
            dispatch(dislikeRemove(clipsId)).then(() => {
                dispatch(getAllLikesList(clipsId));
                dispatch(getAllDislikesList(clipsId));
            });
        } else {
            if (isLiked) {
                dispatch(likeRemove(clipsId));
            }
            dispatch(dislikeClips(clipsId)).then(() => {
                dispatch(getAllLikesList(clipsId));
                dispatch(getAllDislikesList(clipsId));
            });
        }
        setIsDisliked(!isDisliked);
        setIsLiked(false);
    };

    // THIS FUNCTION IS USED TO TOGGLE THE MENU
    const toggleMenu = (index) => {
        setExpandedMenuIndex(expandedMenuIndex === index ? null : index);
    };

    // THIS FUNCTION IS USED TO HANDLE THE SAVE AND UNSAVE CLIPS FUNCTIONALITY
    const handleSave = async () => {
        if (savedClips?.includes(clipsId)) {
            await dispatch(removeSavedClips(clipsId)).then(() => {
                setSavedClips(savedClips.filter((item) => item !== clipsId));
            })
        } else {
            await dispatch(saveClips(clipsId)).then(() => {
                setSavedClips([...savedClips, clipsId]);
            })
        }
    }

    return (
        <div className="absolute right-1 top-[40%] transform -translate-y-[40%]">
            <div className="flex flex-col items-center space-y-2 bg-[#ffffff7e] rounded-full py-3 px-[6px]">
                <div className="flex flex-col space-y-[1px] items-center">
                    <BsFillHandThumbsUpFill
                        onClick={handleLike}
                        className={`text-[24px] md:text-[18px] cursor-pointer text-[#3c3c3c] ${isLiked ? 'text-blue-500' : ''}`}
                    />
                    <p className="text-[12px] md:text-[8px] text-[#2a2a2a]">
                        {likesCount}
                    </p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <BsFillHandThumbsDownFill
                        onClick={handleDislike}
                        className={`text-[24px] md:text-[18px] cursor-pointer text-[#3c3c3c] ${isDisliked ? 'text-red-500' : ''}`}
                    />
                    <p className="text-[12px] md:text-[8px] text-[#2a2a2a]">
                        {dislikesCount}
                    </p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <FaComment
                        onClick={handleComment}
                        className={`text-[22px] md:text-[16px] cursor-pointer text-[#3c3c3c]`}
                    />
                    <p className="text-[12px] md:text-[8px] text-[#2a2a2a]">
                        {comments_count}
                    </p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <MdOutlineBookmark
                        onClick={handleSave}
                        className={`text-[24px] md:text-[18px] cursor-pointer ${savedClips?.includes(clipsId) ? "text-[#3cbbff]" : "text-[#3c3c3c]"}`}
                    />
                    <p className="text-[12px] md:text-[8px] text-[#2a2a2a]">{savedClips?.length}</p>
                </div>
                <div className="pb-[4px]">
                    <FaShareAlt
                        onClick={handleShare}
                        className={`text-[20px] md:text-[16px] cursor-pointer text-[#3c3c3c]`}
                    />
                </div>

                <div
                    className={`transition-all duration-300 ease-in-out overflow-hidden 
                        ${expandedMenuIndex === currentIndex ? 'h-[60px] md:h-[50px] opacity-100' : 'h-0 opacity-0'}
                    `}
                >
                    <div className="pb-2 flex justify-center items-center">
                        <FaDownload
                            onClick={handleDownload}
                            className={`text-[20px] md:text-[16px] cursor-pointer text-[#3c3c3c]`}
                        />
                    </div>
                    <div className="pb-0 mt-[5px] flex justify-center items-center">
                        <MdReport
                            onClick={handleReport}
                            className={`text-[24px] md:text-[18px] cursor-pointer text-[#3c3c3c]`}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-center">
                    <HiDotsHorizontal
                        onClick={() => toggleMenu(currentIndex)}
                        className="text-[20px] md:text-[16px] text-[#2a2a2a] cursor-pointer"
                    />
                </div>
            </div>
        </div>
    );
};

export default Controls;