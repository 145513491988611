import React from "react"

const Subscribe = ({ currentIndex, videoData, isSubscribed, setIsSubscribed, handleVideoDetails }) => {
    return (
        <div className="absolute w-full bottom-[8rem] flex flex-col items-center justify-center">
            <div className="flex rounded-[6px] bg-[#00000065] px-2 py-1 mx-6">
                <div className="grid grid-cols-12">
                    <div className="col-span-2 flex items-center justify-start">
                        <img
                            alt="account"
                            className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                            src={videoData[currentIndex].channelLogo}
                        />
                    </div>
                    <div className="col-span-10 w-[10rem] flex items-center ml-[5px]">
                        <p
                            onClick={handleVideoDetails}
                            className="text-[9.5px] line-clamp-2 leading-[11.5px] text-white"
                        >
                            {videoData[currentIndex].title}
                        </p>
                    </div>
                </div>
            </div>
            <button
                onClick={() => setIsSubscribed(!isSubscribed)}
                className="text-[13px] bg-[#0DD315] text-white w-[8rem] font-semibold py-1"
            >
                {isSubscribed ? "Unsubscribe" : "Subscribe"}
            </button>

            <div className="absolute bottom-1 left-2 flex items-center gap-1">
                <div className="h-[10px] w-[10px] bg-[#F80000] rounded-full animate-blink" />
                <p className="text-[12px] text-white font-semibold">10:45</p>
            </div>
        </div>
    )
}

export default Subscribe