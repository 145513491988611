import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createCommentLike = createAsyncThunk(
    "createCommentLike",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${commentId}/like/`, {}, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createCommentUnlike = createAsyncThunk(
    "createCommentUnlike",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${commentId}/unlike/`, {}, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createCommentDislike = createAsyncThunk(
    "createCommentDislike",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${commentId}/dislike/`, {}, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createCommentRemoveDislike = createAsyncThunk(
    "createCommentRemoveDislike",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/clips/comment/${commentId}/remove_dislike/`, {}, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCommentlikes = createAsyncThunk(
    "getCommentlikes",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/comments/${commentId}/likes/`, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const getCommentDislikes = createAsyncThunk(
    "getCommentDislikes",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/clips/comments/${commentId}/dislikes/`, config)
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const clipscommentLikeSlice = createSlice({
    name: "clipscommentLike",
    initialState: {
        loading: false,
        likesData: null,
        dislikesData: null,
        error: null,
    },
    extraReducers: (builder) => {
        // get comment likes list
        builder.addCase(getCommentlikes.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCommentlikes.fulfilled, (state, action) => {
            state.loading = false;
            state.likesData = action.payload;
            state.error = null;
        });
        builder.addCase(getCommentlikes.rejected, (state) => {
            state.loading = false;
            state.likesData = null;
            state.error = true;

        });
        // get comment dislikes list
        builder.addCase(getCommentDislikes.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getCommentDislikes.fulfilled, (state, action) => {
            state.loading = false;
            state.dislikesData = action.payload;
            state.error = null;
        });
        builder.addCase(getCommentDislikes.rejected, (state) => {
            state.loading = false;
            state.dislikesData = null;
            state.error = true;

        });

    }
});

export const { setUploadProgress, setUploadingData } = clipscommentLikeSlice.actions;
export default clipscommentLikeSlice.reducer;