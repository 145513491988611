import React, { useState } from "react";
import { FaShareAlt, FaComment } from "react-icons/fa";
import { MdOutlineBookmark, MdReport } from "react-icons/md";
import { BsFillHandThumbsUpFill, BsFillHandThumbsDownFill } from "react-icons/bs";

const Controls = ({ handleComment, handleShare, handleReport }) => {
    const [likes, setLikes] = useState(350);
    const [dislikes, setDislikes] = useState(140);
    const [userLiked, setUserLiked] = useState(false);
    const [userDisliked, setUserDisliked] = useState(false);

    const [bookmarks, setBookmarks] = useState(190);
    const [isBookmarked, setIsBookmarked] = useState(false);

    const handleLike = () => {
        if (userLiked) {
            setLikes(likes - 1);
            setUserLiked(false);
        } else {
            setLikes(likes + 1);
            if (userDisliked) {
                setDislikes(dislikes - 1);
                setUserDisliked(false);
            }
            setUserLiked(true);
        }
    };

    const handleDislike = () => {
        if (userDisliked) {
            setDislikes(dislikes - 1);
            setUserDisliked(false);
        } else {
            setDislikes(dislikes + 1);
            if (userLiked) {
                setLikes(likes - 1);
                setUserLiked(false);
            }
            setUserDisliked(true);
        }
    };

    const handleBookmark = () => {
        if (isBookmarked) {
            setBookmarks(bookmarks - 1);
        } else {
            setBookmarks(bookmarks + 1);
        }
        setIsBookmarked(!isBookmarked); // Toggle the state
    };

    return (
        <div className="absolute right-1 top-[40%] transform -translate-y-[40%]">
            <div className="flex flex-col items-center space-y-2 bg-[#ffffff7e] rounded-full py-3 px-[6px]">
                <div className="flex flex-col space-y-[1px] items-center">
                    <BsFillHandThumbsUpFill
                        className={`text-[18px] cursor-pointer ${userLiked ? 'text-blue-500' : 'text-[#3c3c3c]'}`}
                        onClick={handleLike}
                    />
                    <p className="text-[8px] text-[#2a2a2a]">{likes.toLocaleString()}</p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <BsFillHandThumbsDownFill
                        className={`text-[18px] cursor-pointer ${userDisliked ? 'text-red-500' : 'text-[#3c3c3c]'}`}
                        onClick={handleDislike}
                    />
                    <p className="text-[8px] text-[#2a2a2a]">{dislikes.toLocaleString()}</p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <FaComment
                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                        onClick={handleComment}
                    />
                    <p className="text-[8px] text-[#2a2a2a]">598</p>
                </div>
                <div className="flex flex-col space-y-[1px] items-center">
                    <MdOutlineBookmark
                        className={`text-[18px] cursor-pointer ${isBookmarked ? 'text-blue-500' : 'text-[#3c3c3c]'}`}
                        onClick={handleBookmark}
                    />
                    <p className="text-[8px] text-[#2a2a2a]">{bookmarks.toLocaleString()}</p>
                </div>
                <div className="pb-2">
                    <FaShareAlt
                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                        onClick={handleShare}
                    />
                </div>
                <div className="pb-0">
                    <MdReport
                        className={`text-[18px] cursor-pointer text-[#3c3c3c]`}
                        onClick={handleReport}
                    />
                </div>
            </div>
        </div>
    )
}

export default Controls