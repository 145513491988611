import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

// comment section
export const getComments = createAsyncThunk(
    "getComments",
    async (post_id) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/comments/video/${post_id}/`, config);
        const response = await request.data;
        return response;
    }
);
export const createComment = createAsyncThunk(
    "createComment",
    async ({ video, content }) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/comments/`, { content, video }, config);
        const response = await request.data;
        return response;
    }
);
export const updateComments = createAsyncThunk(
    "updateComments",
    async ({ commentID, data }, { rejectWithValue }) => {
        const config = getAccessConfig();
        try {
            console.log(commentID, data, "commentID, data")
            const request = await axios.patch(`${mainUrl}api/comments/${commentID}/`, data, {
                headers: config.headers
            });
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const deleteComment = createAsyncThunk(
    "deleteComment",
    async (commentId) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}/api/comments/${commentId}/`, config);
        const response = await request.data;
        return response;
    }
);
// replies section
export const getReplies = createAsyncThunk(
    "getReplies",
    async (commentId) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/reply-comments/comment/${commentId}/`, config);
        const response = await request.data;
        return response;
    }
);
export const createCommentReply = createAsyncThunk(
    "createCommentReply",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/reply-comments/`, data, config);
        const response = await request.data;
        return response;
    }
);
export const updateCommentReply = createAsyncThunk(
    "updateCommentReply",
    async ({ replyId, data }) => {
        const config = getAccessConfig();
        const request = await axios.put(`${mainUrl}api/reply-comments/${replyId}/`, data, config);
        const response = await request.data;
        return response;
    }
);
export const deleteReplies = createAsyncThunk(
    "deleteReplies",
    async (reply_Id) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/reply-comments/${reply_Id}/`, config);
        const response = await request.data;
        return response;
    }
);
// like dislike section

export const likeComment = createAsyncThunk(
    "likeComment",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/comment-ratings/`, data, config);
        const response = await request.data;
        return response;
    }
);
export const removeLikeDislikeComment = createAsyncThunk(
    "removeLikeDislikeComment",
    async (id) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/comment-ratings/${id}/`, config);
        const response = await request.data;
        return response;
    }
);


const commentsSlice = createSlice({
    name: "commentsSlice",
    initialState: {
        commentLoading: false,
        repliesLoading: false,
        comments: [],
        replies: [],
        error: null,
    },

    extraReducers: (builder) => {
        //getComments
        builder.addCase(getComments.pending, (state) => {
            state.commentLoading = true;
            state.error = null;

        });
        builder.addCase(getComments.fulfilled, (state, action) => {
            state.commentLoading = false;
            state.comments = action.payload;
            state.error = null;

        });
        builder.addCase(getComments.rejected, (state) => {
            state.commentLoading = false;
            state.comments = null;
            state.error = true;

        });
        //createComment
        builder.addCase(createComment.pending, (state) => {
            state.commentLoading = true;
            state.error = null;

        });
        builder.addCase(createComment.fulfilled, (state) => {
            state.commentLoading = false;
            state.error = null;

        });
        builder.addCase(createComment.rejected, (state) => {
            state.commentLoading = false;
            state.error = true;

        });
        //updateComment
        // builder.addCase(updateComment.pending, (state) => {
        //     state.commentLoading = true;
        //     state.error = null;

        // });
        // builder.addCase(updateComment.fulfilled, (state, action) => {
        //     state.commentLoading = false;
        //     state.error = null;

        // });
        // builder.addCase(updateComment.rejected, (state) => {
        //     state.commentLoading = false;
        //     state.error = true;

        // });
        //deleteComment
        builder.addCase(deleteComment.pending, (state) => {
            state.commentLoading = true;
            state.error = null;

        });
        builder.addCase(deleteComment.fulfilled, (state) => {
            state.commentLoading = false;
            state.error = null;

        });
        builder.addCase(deleteComment.rejected, (state) => {
            state.commentLoading = false;
            state.error = true;
        });
        //getReplies
        builder.addCase(getReplies.pending, (state) => {
            state.repliesLoading = true;
            state.error = null;

        });
        builder.addCase(getReplies.fulfilled, (state, action) => {
            state.repliesLoading = false;
            state.replies = action.payload;
            state.error = null;

        });
        builder.addCase(getReplies.rejected, (state) => {
            state.repliesLoading = false;
            state.replies = null;
            state.error = true;

        });
        //createCommentReply
        builder.addCase(createCommentReply.pending, (state) => {
            state.repliesLoading = true;
            state.error = null;

        });
        builder.addCase(createCommentReply.fulfilled, (state) => {
            state.repliesLoading = false;
            state.error = null;

        });
        builder.addCase(createCommentReply.rejected, (state) => {
            state.repliesLoading = false;
            state.error = true;

        });
        //updateCommentReply
        builder.addCase(updateCommentReply.pending, (state) => {
            state.repliesLoading = true;
            state.error = null;

        });
        builder.addCase(updateCommentReply.fulfilled, (state) => {
            state.repliesLoading = false;
            state.error = null;

        });
        builder.addCase(updateCommentReply.rejected, (state) => {
            state.repliesLoading = false;
            state.error = true;

        });
    }
});

export default commentsSlice.reducer;
