import React, { Fragment, useRef, useState } from "react";
import { copyright } from "../../utils/copyright";
import { CiCirclePlus } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import MainMenu from "../../components/common/MainMenu";
import { Input, Option, Select, Switch, Typography } from "@material-tailwind/react";
import { IoCloseCircle } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { createPodcast } from "../../redux/apislice/podcast/podcastSlice";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "rc-progress";
import { LuImagePlus } from "react-icons/lu";

const CreatePodcast = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [frames, setFrames] = useState([]);
    const videoRef = useRef(null);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        podcast_name: "",
        podcast_genre: "",
        host_name: "",
        video: null,
        video_quality: null,
        comments_enabled: false,
        share_enabled: false,
        download_enabled: false,
        is_public: false,
        thumbnail: null
    });

    const podcast = useSelector((state) => state.podcast);
    const { loading, progress } = podcast;

    // this function for handle the form change
    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    // this function for handle the file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setVideoPreviewUrl(fileUrl);
            setFormData({
                ...formData, video: file
            });
        }
    };

    // this function for handle the image change
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setThumbnailUrl(fileUrl);
            setFormData({
                ...formData, thumbnail: file
            });
        }
        else {
            toast.error("Please upload a valid image file");
        }
    }
    // this function for handle the loaded metadata
    const handleLoadedMetadata = async () => {
        const videoElement = videoRef.current;
        const duration = Math.floor(videoElement.duration);
        const framesArray = await captureFrames(videoElement, duration);
        setFrames(framesArray);
    };

    const captureFrames = (videoElement, duration) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const framesArray = [];

            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            const captureFrameAtSecond = (second) => {
                return new Promise((resolve) => {
                    videoElement.currentTime = second;
                    videoElement.onseeked = () => {
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                        const frameData = canvas.toDataURL("image/jpeg");
                        framesArray.push(frameData);
                        resolve();
                    };
                });
            };

            const captureFramesAsync = async () => {
                for (let i = 0; i < duration; i++) {
                    await captureFrameAtSecond(i);
                }
                resolve(framesArray);
            };

            captureFramesAsync();
        });
    };

    //this function for move on the next step
    const handleNext = (e) => {
        e.preventDefault();
        if (formData.podcast_name === "" || formData.podcast_genre === "" || formData.host_name === "" || formData.video === null || formData.video_quality === null) {
            toast.error("Please fill all the fields and upload a video");
            return;
        }
        setStep(2);
    };

    //this function for handle the upload
    const handleUpload = async () => {
        const uploadData = new FormData();
        for (const key in formData) {
            uploadData.append(key, formData[key]);
        }

        try {
            await dispatch(createPodcast(uploadData)).then((response) => {
                if (response.error) {
                    toast.error(response?.error?.data?.detail);
                } else {
                    toast.success("Podcast uploaded successfully");
                    setTimeout(() => {
                        navigate("/home");
                    }, 2000)
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
            // Handle error (e.g., show an error message)
        }
    };

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Podcast / V1 / 27 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center sticky top-0 z-40">
                <div className="col-span-4 flex items-center justify-between gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                </div>
                <div className="col-span-4 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">
                        Create Podcast
                    </p>
                </div>
                <div className="col-span-4 flex items-center justify-end">
                    <MainMenu />
                </div>
            </div>

            {step === 1 &&
                <form className="px-2 mt-3" onSubmit={handleNext}>
                    <Input
                        required
                        color="blue"
                        variant="standard"
                        label="Podcast Name"
                        name="podcast_name"
                        value={formData.podcast_name}
                        onChange={handleFormChange}
                        placeholder="Enter podcast name"
                        className="text-[14px] text-white"
                    />
                    <div className="mt-3">
                        <Select
                            value={formData.podcast_genre}
                            onChange={(e) => setFormData({ ...formData, podcast_genre: e })}
                            name="podcast_genre"
                            required variant="standard" label="Podcast Genre" color="blue" className="text-[14px] text-white">
                            <Option value="Entertainment">Entertainment</Option>
                            <Option value="Tech">Tech</Option>
                            <Option value="Education">Education</Option>
                            <Option value="Politics">Politics</Option>
                            <Option value="Crime">Crime</Option>
                            <Option value="Finances">Finances</Option>
                            <Option value="Currencies">Currencies</Option>
                            <Option value="Lifestyle">Lifestyle</Option>
                            <Option value="Music">Music</Option>
                            <Option value="Sports">Sports</Option>
                            <Option value="News">News</Option>
                            <Option value="Comedy">Comedy</Option>
                            <Option value="Travel">Travel</Option>
                            <Option value="Gaming">Gaming</Option>
                            <Option value="Health & Fitness">Health & Fitness</Option>
                            <Option value="Food">Food</Option>
                            <Option value="Fashion & Beauty">Fashion & Beauty</Option>
                            <Option value="Science">Science</Option>
                            <Option value="Art & Design">Art & Design</Option>
                        </Select>
                    </div>
                    <div className="mt-3">
                        <Input
                            required
                            name="host_name"
                            value={formData.host_name}
                            onChange={handleFormChange}
                            color="blue"
                            label="Host Name"
                            variant="standard"
                            placeholder="Enter podcast name"
                            className="text-[14px] text-white"
                        />
                    </div>
                    <div className="mt-3 relative">
                        <p className="text-[12px] text-white">
                            Add Video
                        </p>
                        <input
                            type="file"
                            accept="video/*"
                            id="fileInput"
                            className="hidden"
                            name="video"
                            onChange={handleFileChange}
                        />
                        {!videoPreviewUrl && <label htmlFor="fileInput" className="w-full h-28 bg-[#3e3e3e] rounded flex items-center justify-center cursor-pointer">
                            <CiCirclePlus className="text-white text-[25px]" />
                        </label>}
                        {videoPreviewUrl &&
                            <>
                                <video src={videoPreviewUrl}
                                    ref={videoRef}
                                    muted
                                    loop
                                    autoPlay
                                    className="w-full h-auto rounded max-h-[24rem]"
                                    onLoadedMetadata={handleLoadedMetadata}
                                    disablePictureInPicture
                                />
                                <IoCloseCircle className="absolute text-red-900 top-6 text-[1.5rem] right-2 z-20 cursor-pointer" onClick={() => {
                                    setFormData({
                                        ...formData, video: null
                                    }); setVideoPreviewUrl(null)
                                }} />
                            </>
                        }

                    </div>
                    <div className="mt-3 relative">
                        <p className="text-[12px] text-white">
                            Add Thumbnail
                        </p>
                        <input
                            type="file"
                            accept="image/*"
                            id="ImageInput"
                            className="hidden"
                            name="thumbnail"
                            onChange={handleImageChange}
                        />
                        {!thumbnailUrl && <label htmlFor="ImageInput" className="w-full h-28 bg-[#3e3e3e] rounded flex items-center justify-center cursor-pointer">
                            <LuImagePlus className="text-white text-[23px]" />
                        </label>}
                        {thumbnailUrl &&
                            <>
                                <img src={thumbnailUrl}
                                    alt="Thumbnail"
                                    className="w-full h-[8rem] rounded  object-cover" />
                                <label htmlFor="ImageInput" className="rounded flex items-center justify-center cursor-pointer">
                                    <CiCirclePlus htmlFor="ImageInput" className="absolute text-white font-bold top-6 text-[1.5rem] right-2 z-20 cursor-pointer"
                                    />
                                </label>
                            </>
                        }

                    </div>
                    <div className="mt-3">
                        <Select
                            name="video_quality"
                            value={formData.video_quality}
                            onChange={(e) => setFormData({ ...formData, video_quality: e })}
                            required variant="standard" label="Video Quality" color="blue" className="text-[14px] text-white">
                            <Option value="144p">144p</Option>
                            <Option value="240p">240p</Option>
                            <Option value="360p">360p</Option>
                            <Option value="480p">480p</Option>
                            <Option value="720p">720p</Option>
                            <Option value="1080p">1080p</Option>
                        </Select>
                    </div>
                    <div className="flex items-center justify-between mt-4">
                        <p className="text-[14px] text-white">
                            Comments
                        </p>
                        <Switch
                            ripple={true}
                            checked={formData.comments_enabled}
                            onChange={(e) => setFormData({ ...formData, comments_enabled: e.target.checked })}
                            name="comments_enabled"
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Share
                        </p>
                        <Switch
                            ripple={true}
                            checked={formData.share_enabled}
                            onChange={(e) => setFormData({ ...formData, share_enabled: e.target.checked })}
                            name="share_enabled"
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Download
                        </p>
                        <Switch
                            ripple={true}
                            checked={formData.download_enabled}
                            onChange={(e) => setFormData({ ...formData, download_enabled: e.target.checked })}
                            name="download_enabled"
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[14px] text-white">
                            Public / Private
                        </p>
                        <Switch
                            ripple={true}
                            checked={formData.is_public}
                            onChange={(e) => setFormData({ ...formData, is_public: e.target.checked })}
                            name="is_public"
                            className="h-4 w-[28.5px] checked:bg-[#0DD315]"
                            containerProps={{
                                className: "w-8 h-4",
                            }}
                            circleProps={{
                                className: "before:hidden left-0.5 border-none w-3 h-3",
                            }}
                        />
                    </div>
                    <div className="w-full my-6 flex items-center justify-center">
                        <button className="text-[16px] py-[6px] px-14 rounded text-white bg-[#3e3e3e] hover:opacity-90" >
                            Next
                        </button>
                    </div>
                </form>
            }

            {step === 2 &&
                <div className="px-2 mt-2">
                    <p className="text-white text-[15px] font-bold text-center">
                        Review Podcast
                    </p>

                    <div className="mt-2">
                        <video
                            ref={videoRef}
                            muted
                            loop
                            autoPlay
                            src={videoPreviewUrl}
                            disablePictureInPicture
                            className="w-full h-full max-h-[22rem] rounded"
                            onLoadedMetadata={handleLoadedMetadata}
                        />
                    </div>

                    <div className="flex overflow-x-scroll mt-2">
                        {frames.map((frame, index) => (
                            <img
                                key={index}
                                src={frame}
                                alt={`Frame ${index + 1}`}
                                className="w-[2.5rem] h-[2.5rem] object-cover"
                            />
                        ))}
                    </div>

                    <div className="mt-5">
                        <Input
                            value={formData.podcast_name}
                            readOnly
                            color="blue"
                            label="Title"
                            variant="standard"
                            placeholder="Enter podcast title"
                            className="text-[14px] text-white"
                        />
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-6 flex flex-col items-start">
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Format</p>
                                <p className="text-[13px] text-[#3e3e3e]">mp4</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Quality</p>
                                <p className="text-[13px] text-[#3e3e3e]">{formData.video_quality}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Comments</p>
                                <p className="text-[13px] text-[#3e3e3e]">{formData.comments_enabled ? 'on' : 'off'}</p>
                            </div>
                        </div>
                        <div className="col-span-6 flex flex-col items-start">
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Viewers</p>
                                <p className="text-[13px] text-[#3e3e3e]">{formData.is_public ? 'public' : 'private'}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Sharing</p>
                                <p className="text-[13px] text-[#3e3e3e]">{formData.share_enabled ? 'on' : 'off'}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-[13px] text-white">Download</p>
                                <p className="text-[13px] text-[#3e3e3e]">{formData.download_enabled ? 'on' : 'off'}</p>
                            </div>
                        </div>
                    </div>

                    {progress <= 0 && !loading && <div className="w-full mt-6 mb-5 flex items-center justify-between">
                        <button type="button" className="text-[14px] py-[5px] w-24 rounded text-white bg-[#3e3e3e] hover:opacity-90" onClick={() => setStep(1)}>
                            Back
                        </button>
                        <button type="button" className="text-[14px] py-[5px] w-24 rounded text-white bg-[#3e3e3e] hover:opacity-90">
                            Draft
                        </button>
                        <button type="button" onClick={handleUpload} className="text-[14px] py-[5px] w-24 rounded text-white bg-[#3e3e3e] hover:opacity-90">
                            Upload
                        </button>
                    </div>}
                    {progress > 0 && loading && <div className="w-full flex flex-col gap-4 mt-4">
                        <Line percent={progress} strokeWidth={4} strokeLinecap="round" strokeColor="rgb(67,60,187)" trailColor="#D9D9D9" style={{ height: "10px", width: "100%" }} />
                        <div className="mb-2 flex items-center justify-between gap-4">
                            <Typography className="text-white" color="blue-gray" variant="h6">
                                Uploading video... Please wait.
                            </Typography>
                            <Typography className="text-white" color="blue-gray" variant="h6">
                                {progress}%
                            </Typography>
                        </div>
                    </div>}
                </div>
            }
        </Fragment>
    );
};

export default CreatePodcast;