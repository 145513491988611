import React, { Fragment, useEffect, useRef, useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdFlipCameraAndroid } from "react-icons/md";
import { FaCrown } from "react-icons/fa";
import { IoGift } from "react-icons/io5";

const GoLive = () => {
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const [isLiveStarted, setIsLiveStarted] = useState(false);
    const [viewCount, setViewCount] = useState(0);

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;

                    videoRef.current.onloadedmetadata = () => {
                        console.log("Metadata loaded, playing video...");
                        videoRef.current.play().catch(error => console.error("Error playing the video: ", error));
                    };
                } else {
                    // console.error("Video element not found!");
                }
            } catch (err) {
                // console.error("Error accessing the camera: ", err);
            }
        };

        startCamera();

        const videoElement = videoRef.current;

        return () => {
            if (videoElement && videoElement.srcObject) {
                const stream = videoElement.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        let interval;
        if (isLiveStarted) {
            interval = setInterval(() => {
                setViewCount(prevCount => prevCount + Math.floor(Math.random() * 10) + 1);
            }, 1000); // Increase view count every second
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isLiveStarted]);

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Go lIve / V1 / 13 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="relative">
                <div className="grid grid-cols-12 items-center bg-white px-2">
                    <div className="col-span-3 flex items-center justify-start">
                        <h1
                            onClick={() => navigate("/home")}
                            className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                        >
                            kv
                        </h1>
                    </div>
                    <div className="col-span-6 flex items-center justify-center">
                        <p className="font-semibold text-[16px]">Go live</p>
                    </div>
                    <div className="col-span-3 flex items-center justify-end">
                        <p className={`bg-[#f73030] text-[#ffffff] rounded-full px-3 py-[1.5px] text-[10px] ${isLiveStarted ? "animate-blink" : ""}`}>Live</p>
                    </div>
                </div>

                <div className="absolute w-full top-12 grid grid-cols-12 px-2 z-10">
                    <div className="col-span-3 flex justify-start">
                        <IoArrowBackCircle
                            onClick={() => navigate(-1)}
                            className="text-[22px] cursor-pointer text-white"
                        />
                    </div>
                    <div className="col-span-6 flex justify-center items-start">
                        {isLiveStarted &&
                            <button className="text-[10px] bg-black text-white rounded-full px-2">
                                {viewCount} views
                            </button>
                        }
                    </div>
                    <div className="col-span-3 flex justify-end">
                        <div className="flex flex-col">
                            <MdFlipCameraAndroid className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                            <FaCrown className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer mb-4" />
                            <IoGift className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full h-screen md:h-[67vh] relative">
                <video
                    muted
                    autoPlay
                    ref={videoRef}
                    disablePictureInPicture
                    className="object-cover h-full bg-black"
                />

                <div className="absolute bottom-2 w-full px-2 z-10">
                    <button
                        onClick={() => setIsLiveStarted(!isLiveStarted)}
                        className={`${isLiveStarted ? "bg-[#f73030]" : "bg-[#141414]"} w-full hover:opacity-85 text-white hover:font-semibold font-medium rounded-full py-1 text-[14px]`}
                    >
                        {isLiveStarted ? "Stop Live" : "Go Live"}
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default GoLive;
