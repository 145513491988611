import React, { Fragment, useState, useEffect } from "react";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { TbLogout2 } from "react-icons/tb";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaChartLine, FaUserEdit } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { profileDetails } from "../redux/apislice/profileSlice";
import Skeleton from "react-loading-skeleton";
import { getHistoryList } from "../redux/apislice/watchHistorySlice";
import { getWatchList } from "../redux/apislice/watchListSlice";
import Posted from "../components/account/Posted";
import { persistor } from "../redux/store";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1
    }
};

const responsiveClips = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
}

const Account = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedId, setSelectedId] = useState(null);
    // const popupRef = useRef(null);
    const rememberMe = Cookies.get("rememberMe") === "true" ? true : false;
    const profile = useSelector((state) => state.profile);
    const { loading, userDetails } = profile;
    // const [activePopupId, setActivePopupId] = useState(false);
    const history = useSelector((state) => state.watchHistoryList);
    const { historyList } = history
    const watchlist = useSelector((state) => state.watchLaterList);
    const { watchLaterData } = watchlist;
    const userId = Cookies.get("userId");


    const handleMenuItemClick = (id) => {
        setSelectedId(id);
    };

    // const handleClickOutside = (event) => {
    //     if (popupRef.current && !popupRef.current.contains(event.target)) {
    //         setActivePopupId(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const videoData = [
        {
            id: 1,
            title: "Epic Goals and Game-Changing Moments on the Field",
            url: "https://cdn.pixabay.com/video/2022/11/18/139533-772542665_large.mp4",
        },
        {
            id: 2,
            title: "Exploring Hidden Gems Around the World",
            url: "https://cdn.pixabay.com/video/2024/06/01/214780_large.mp4",
        },
        {
            id: 3,
            title: "Riding Through Scenic Trails and Urban Adventures",
            url: "https://cdn.pixabay.com/video/2023/01/22/147560-791705573_large.mp4",
        },
    ];


    const logout = () => {
        if (rememberMe) {
            localStorage.removeItem("securityAccessToken");
            Cookies.remove("accessToken");
            persistor.purge();
            navigate("/");
        } else {
            Cookies.remove();
            persistor.purge();
            localStorage.removeItem("securityAccessToken");
            navigate("/");
        }
    }

    useEffect(() => {
        dispatch(profileDetails(userId));
        dispatch(getHistoryList());
        dispatch(getWatchList())
    }, [dispatch, userId])

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Account / V2 / 29 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className={`bg-white px-2 flex items-center justify-between py-2  sticky top-0 z-20`}>
                <div className="flex items-center gap-x-4">
                    <IoMdArrowRoundBack
                        onClick={() => navigate(-1)}
                        className="text-[20px] text-[#000000] cursor-pointer"
                    />
                    {!loading && <p className="text-[16px] text-[#000000] font-bold">
                        {userDetails?.full_name}
                    </p>}
                    {loading && <Skeleton width={60} height={20} baseColor="#202020" highlightColor="#444" />}
                </div>
                <div className="flex items-center gap-x-4">
                    <IoSearch
                        onClick={() => navigate("/search")}
                        className="text-[20px] text-black cursor-pointer"
                    />
                    <TbLogout2
                        onClick={() => logout()}
                        className="text-[22px] text-black cursor-pointer"
                    />
                </div>
            </div>
            <div className="bg-black pb-2">
                <div className="flex items-center justify-between px-3 pt-3">
                    <div className="p-2 bg-white rounded-md cursor-pointer">
                        <FaChartLine />
                    </div>
                    {!loading &&
                        <img
                            alt="profile"
                            onClick={() => setSelectedId(null)}
                            src={userDetails?.profile_picture}
                            className="w-[4rem] h-[4rem] object-cover rounded-full cursor-pointer"
                        />
                    }
                    {loading && <Skeleton
                        baseColor="#202020" highlightColor="#444"
                        circle
                        style={{ height: "4rem", width: "4rem" }} />
                    }
                    <div className="p-2 bg-white rounded-md cursor-pointer">
                        <FaUserEdit
                            className="text-[16px]"
                            onClick={() => navigate("/edit/account")}
                        />
                    </div>
                </div>

                {/* Account menu 01 */}
                <div className="grid grid-cols-3 gap-3 px-3 pt-3">
                    <div className="col-span-1">
                        <div
                            onClick={() => navigate("/subscribed")}
                            className="flex items-center justify-center px-1 py-1 bg-white hover:bg-[#404040] hover:text-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">5,023</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => handleMenuItemClick(1)}
                            className={`flex items-center justify-center px-1 py-1 ${selectedId === 1 ? "bg-[#29e52f] text-white" : "bg-white"}  hover:bg-[#404040] hover:text-white rounded-md cursor-pointer`}
                        >
                            <p className="text-[10px] font-medium">Posted</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            // onClick={}
                            className="flex items-center justify-center px-1 py-1 bg-white hover:bg-[#404040] hover:text-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Switch</p>
                        </div>
                    </div>
                </div>

                {/* Account menu 02 */}
                <div className="grid grid-cols-3 gap-3 px-3 pt-2">
                    <div className="col-span-1">
                        <div
                            onClick={() => navigate("/downloads")}
                            className="flex items-center justify-center px-1 py-1 bg-white hover:bg-[#404040] hover:text-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Downloads</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => navigate("/watchlist")}
                            className="flex items-center justify-center px-1 py-1 bg-white hover:bg-[#404040] hover:text-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Watch Later</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div
                            onClick={() => navigate("/likedvideos")}
                            className="flex items-center justify-center px-1 py-1 bg-white hover:bg-[#404040] hover:text-white rounded-md cursor-pointer"
                        >
                            <p className="text-[10px] font-medium">Liked Video</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* Main Content */}
            {selectedId === null &&
                <div className="pt-1">
                    {historyList?.results?.length > 0 && <div className="mb-2">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Recent watched Videos</p>
                            {historyList?.results?.length > 2 && <p
                                onClick={() => navigate("/history")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer"
                            >
                                view all
                            </p>}
                        </div>
                        {historyList?.results?.length > 0 && historyList?.results ? (
                            <div className="px-1">
                                <Carousel
                                    ssr={true}
                                    arrows={false}
                                    infinite={false}
                                    swipeable={true}
                                    draggable={true}
                                    responsive={responsive}
                                    className="select-none"
                                >
                                    {historyList?.results?.map((item) => (
                                        <div className="select-none mx-1" key={item.id}>
                                            <img
                                                alt="post"
                                                src={item.video.thumbnail}
                                                onClick={() => navigate(`/video/${item.video.id}`)}
                                                className="w-full h-[5.3rem] object-cover rounded-[6px] cursor-pointer"
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>) : <p>No items to display</p>}
                    </div>}
                    <div className="mt-5">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Recent watched clips</p>
                            <p
                                onClick={() => navigate("/clips/e7491808-c97c-493d-81e8-6d53f108e595")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer">view all</p>
                        </div>
                        <div className="px-1">
                            <Carousel
                                ssr={true}
                                arrows={false}
                                infinite={true}
                                swipeable={true}
                                draggable={true}
                                responsive={responsiveClips}
                                className="select-none"
                            >
                                {videoData.map((item) => (
                                    <div className="mx-1" key={item.id}>
                                        <video
                                            muted
                                            loop
                                            disablePictureInPicture
                                            src={item.url}
                                            onClick={() => navigate("/clips/6e02cad6-978b-4880-9699-e29550a2452c")}
                                            className="w-full h-full object-cover rounded-[6px] cursor-pointer"
                                        />
                                    </div>
                                ))}

                            </Carousel>
                        </div>
                    </div>

                    {watchLaterData?.results?.length > 0 && <div className="my-5">
                        <div className="px-2 flex mb-1 items-center justify-between">
                            <p className="text-[10px] text-[#a1a1a1]">Added to Watchlater</p>
                            {watchLaterData?.results?.length > 3 && <p
                                onClick={() => navigate("/watchlist")}
                                className="text-[10px] text-[#a1a1a1] hover:text-[#ffffff] cursor-pointer"
                            >
                                view all
                            </p>}
                        </div>
                        <div className="px-1">
                            {watchLaterData && watchLaterData?.results && watchLaterData?.results?.length > 0 ? (
                                <Carousel
                                    ssr={true}
                                    arrows={false}
                                    infinite={true}
                                    swipeable={true}
                                    draggable={true}
                                    responsive={responsiveClips}
                                    className="select-none"
                                >
                                    {watchLaterData?.results.map((item) => (
                                        <div className="select-none mx-1" key={item.id}>
                                            <img
                                                alt="post"
                                                src={item.video.thumbnail}
                                                onClick={() => navigate(`/video/${item.video.id}`)}
                                                className="w-full h-[3.5rem] object-cover rounded-[6px] cursor-pointer"
                                            />
                                        </div>
                                    ))}
                                </Carousel>
                            ) : (
                                <p>No items to display</p>
                            )}
                        </div>
                    </div>}
                </div>
            }
            {/* view video */}
            {selectedId === 1 && <Posted userDetails={userDetails} />}
        </Fragment>
    );
};

export default Account;
