import React, { Fragment, useEffect } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Carousel from "react-multi-carousel";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { blogDetail } from "../../redux/apislice/blogSlice";
import BeatLoader from "react-spinners/BeatLoader";

const BlogViewPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { loading, data } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(blogDetail(id));
    }, [dispatch, id]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Blog View / V1 / 20 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-1 flex items-center justify-start gap-3">
                    <IoMdArrowRoundBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />

                </div>
                <div className="col-span-11">
                    <p className="text-white text-[16px] font-medium line-clamp-1">
                        <Marquee speed={25}>
                            {data.title}
                        </Marquee>
                    </p>
                </div>
            </div>

            {loading ?
                <div className="flex items-center justify-center mt-3">
                    <BeatLoader
                        color={"#fff"}
                        loading={true}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                :
                <div className="px-2 pt-2">
                    <img
                        alt="blog"
                        className="h-[220px] md:h-[165px] w-full object-cover rounded mb-2"
                        src={data.post_image ? data.post_image :
                            "https://cdn.pixabay.com/photo/2014/06/19/21/36/blog-372771_1280.jpg"
                        }
                    />
                    <p className="text-[18px] mb-2 md:text-[16px] leading-[18px] md:leading-[16px] font-semibold text-white">
                        {data.title}
                    </p>
                    <p className="text-[14px] mb-6 md:text-[12px] leading-[17px] md:leading-[16px] text-white text-justify">
                        {data.content}
                    </p>

                    {data.subposts && data.subposts.map((item) => (
                        <div key={item.id}>
                            {item.images && item.images.length > 1 ? (
                                <div className="pb-2">
                                    <Carousel
                                        draggable
                                        infinite={true}
                                        arrows={false}
                                        autoPlay={true}
                                        showDots={true}
                                        autoPlaySpeed={3000}
                                        responsive={responsive}
                                        swipeable={true}
                                        renderDotsOutside={false}
                                    >
                                        {item.images.map((image) => (
                                            <img
                                                key={image.id}
                                                alt="blog_image"
                                                src={image.image}
                                                className="h-[220px] md:h-[165px] w-full object-cover cursor-pointer"
                                            />
                                        ))}
                                    </Carousel>
                                </div>
                            ) : (
                                item.images && item.images.length === 1 && (
                                    <img
                                        key={item.images[0].id}
                                        alt="blog_image"
                                        src={item.images[0].image}
                                        className="h-[220px] md:h-[165px] w-full object-cover cursor-pointer pb-2"
                                    />
                                )
                            )}
                            <p className="text-[18px] mb-2 md:text-[16px] leading-[18px] md:leading-[16px] font-semibold text-white">
                                {item.title && item.title}
                            </p>
                            <p className="text-[14px] mb-2 md:text-[12px] leading-[17px] md:leading-[16px] text-white text-justify">
                                {item.content && item.content}
                            </p>
                        </div>
                    ))}

                </div>
            }
        </Fragment>
    )
}

export default BlogViewPage