import React, { Fragment, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import { DEFAULT_ID } from "../utils/mainUrl";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import MainMenu from "../components/common/MainMenu";
import { IoCaretBackCircle } from "react-icons/io5";
import Carousel from "react-multi-carousel";
import { mainHomePageData } from "../data";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { createSubscription, DeleteSubscription, getAllUserSubscription, getUserSubscription } from "../redux/apislice/subscriptionSlice";
import Clips from "../components/account/Clips";
import { otherUserDetails } from "../redux/apislice/otherUserAccount/otherUserAccount";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
};

const OtherUserAccount = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const profile = useSelector((state) => state.otherUserProfile);
    const { loading, userDetails } = profile;
    const [videoData, setVideoData] = useState(null);
    const userId = Cookies.get("userId");
    const [activeTab, setActiveTab] = useState("Popular");


    useEffect(() => {
        const data = mainHomePageData.find(video => video.id === parseInt(id));
        setVideoData(data);

        dispatch(otherUserDetails(id));
    }, [id, dispatch]);



    const subscriptionlist = useSelector((state) => state.subscription);
    const { subscribeList } = subscriptionlist;

    const subscribe = subscribeList?.results?.some((data) => data?.channel.id === id);

    const handleSubscribe = async () => {
        try {
            await dispatch(createSubscription({ channel_id: id, user: userId, notifications: "all" })).then((res) => {
                if (res.error) {
                    console.log(res, "Subscription failed");
                }
                else {
                    dispatch(getUserSubscription(res.payload.channel.id))
                    dispatch(getAllUserSubscription())
                }
            })

        } catch (error) {
            console.log(error, "Subscription failed");

        }
    }
    const deleteSubscribe = () => {
        const filterData = subscribeList?.results?.filter((data) => data.channel.id === id);
        const chennel_id = filterData[0]?.id;
        console.log(chennel_id, "filterData", filterData)
        dispatch(DeleteSubscription(chennel_id)).then((result) => {
            if (result.error) {
                console.log(result, "Subscription failed");
            } else {
                // dispatch(getUserSubscription(result?.payload?.channel?.id))
                dispatch(getAllUserSubscription())
            }
        });
    }

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / other user account / V1 / 26 Aug, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="bg-white px-2 grid grid-cols-12 items-center relative">
                <div className="col-span-3 flex items-center justify-start gap-3">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <IoCaretBackCircle
                        onClick={() => navigate(-1)}
                        className="text-[20px] text-black cursor-pointer"
                    />
                </div>
                <div className="col-span-6 flex items-center justify-center gap-2">
                    <p className="text-[14px] font-bold">
                        {videoData?.channel}
                    </p>
                </div>
                <div className="col-span-3 flex items-center justify-between gap-2">
                    <FaSearch className="text-black text-[18px] ml-4 cursor-pointer" />
                    <MainMenu />
                </div>
            </div>

            {!loading && <div className="mt-2 grid grid-cols-12 px-2 border-b-[0.4px] border-[#3e3e3e] pb-1">
                <div className="col-span-4 flex flex-col items-start justify-between">
                    <p onClick={() => subscribe ? deleteSubscribe() : handleSubscribe()} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        {subscribe ? "Unsubscribe" : "Subscribe"}
                    </p>
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Notification
                    </p>
                </div>
                <div className="col-span-4 flex items-center justify-center">
                    {userDetails?.profile_picture && (
                        <img
                            alt={userDetails?.full_name}
                            src={userDetails?.profile_picture}
                            className="h-[4rem] w-[4rem] rounded-full object-cover cursor-pointer"
                        />
                    )}
                </div>
                <div className="col-span-4 flex flex-col items-end justify-between">
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        {userDetails?.subscribers_count}
                    </p>
                    <p className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Related
                    </p>
                </div>
                <div className="col-span-12 mt-2">
                    <p className="text-[10px] leading-[14px] line-clamp-3 text-justify text-white">
                        {userDetails?.description}
                    </p>
                    <div className="flex flex-col items-start mt-1">
                        <p className="text-[10px] flex-grow-0 text-light-blue-600 hover:text-light-blue-500 cursor-pointer">
                            https://www.example1.com
                        </p>
                        <p className="text-[10px] text-light-blue-600 hover:text-light-blue-500 cursor-pointer">
                            https://www.example2.com
                        </p>
                    </div>
                </div>
            </div>}

            {loading && (
                <div className="mt-2 grid grid-cols-12 px-2 border-b-[0.4px] border-[#3e3e3e] pb-1">
                    <div className="col-span-4 flex flex-col gap-1 items-start justify-between">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.6rem", width: "5rem" }} />
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.6rem", width: "5rem" }} />
                    </div>
                    <div className="col-span-4 flex items-center justify-center">
                        <Skeleton baseColor="#202020" highlightColor="#444" circle style={{ height: "4rem", width: "4rem" }} />
                    </div>
                    <div className="col-span-4 flex flex-col gap-1 items-end  justify-between">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.6rem", width: "5rem" }} />
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "1.6rem", width: "5rem" }} />
                    </div>
                    <div className="col-span-12 mt-2">
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "0.9rem", width: "100%" }} />
                        <Skeleton baseColor="#202020" highlightColor="#444" style={{ height: "0.9rem", width: "70%" }} />
                    </div>
                </div>
            )}

            <div className="px-2 py-2 bg-black sticky top-0">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    className=" select-none"
                >
                    <p onClick={() => setActiveTab("Popular")} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Popular
                    </p>

                    <p onClick={() => setActiveTab("Videos")} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Videos
                    </p>

                    <p onClick={() => setActiveTab("Clips")} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Clips
                    </p>

                    <p onClick={() => setActiveTab("Podcasts")} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        Podcasts
                    </p>

                    <p onClick={() => setActiveTab("News")} className="text-[11px] text-white bg-[#353535] hover:bg-[#3e3e3e] w-[5rem] py-[5px] rounded flex items-center justify-center cursor-pointer">
                        News
                    </p>
                </Carousel>
            </div>

            {activeTab !== "Clips" && <div div className="px-2">
                {mainHomePageData.map((data) => (
                    <div className="pb-3" key={data.id}>
                        <img
                            alt="thumbnail"
                            src={data.thumbnail}
                            onClick={() => navigate(`/video/${DEFAULT_ID}`)}
                            className="w-full h-[10rem] object-cover rounded-sm cursor-pointer"
                        />
                        <div className="grid grid-cols-12 mt-1">
                            <div className="col-span-11 flex leading-[18px]">
                                <p className="text-white text-[14px] font-light line-clamp-1">{data.title}</p>
                            </div>
                            <div className="col-span-1 flex items-start justify-end mt-[-2px]">
                                <p className="text-white text-[14px] cursor-pointer">
                                    : :
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-12">
                            <div className="col-span-4 flex items-center justify-start">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.videoLength} length
                                </p>
                            </div>
                            <div className="col-span-4 flex items-center justify-center">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.date} date
                                </p>
                            </div>
                            <div className="col-span-4 flex items-center justify-end">
                                <p className="bg-[#353535] text-[10px] text-[#ffffff] flex items-center px-3 py-[2px] rounded-full cursor-pointer">
                                    {data.views} views
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}

            {activeTab === "Clips" && <div className="px-2">
                <Clips userId={id} />
            </div>}
        </Fragment >
    );
};

export default OtherUserAccount;
