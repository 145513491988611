
import React, { Fragment, useEffect, useRef, useState } from "react";
import { IoMdClose, IoMdHeart, IoMdHeartDislike } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { BsThreeDots } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { createPodcastComment, deletePodcastComment, dislikePodcastComment, getPodcastComment, likePodcastComment, updatePodcastComment } from "../../redux/apislice/podcast/commentsSlice";
import { createPodcastCommentReply, deletePodcastCommentReply, dislikePodcastCommentReply, getPodcastCommentReplies, likePodcastCommentReply, updatePodcastCommentReply } from "../../redux/apislice/podcast/podcastReplies";
import { useNavigate } from "react-router-dom";

const CommentView = ({
    closeComment,
    showComment,
    postId,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openReplyId, setOpenReplyId] = useState(null);
    const [commentText, setCommentText] = useState('')
    const [commentId, setCommentId] = useState(null)
    const [replyId, setReplyId] = useState(null)
    const [reply, setReply] = useState('')
    const replyRef = useRef(null);
    const commentMenuRef = useRef(null);
    const [commentMenu, setCommentMenu] = useState(null)
    const replyMenuRef = useRef(null);
    const [replyMenu, setReplyMenu] = useState(null)
    const [editComment, setEditComment] = useState(false)
    const [editCommentReply, setEditCommentReply] = useState(false)
    const [showCommentInput, setShowCommentInput] = useState(false);
    const podcastComment = useSelector((state) => state.podcastComment)
    const { commentLoading, podcastComments } = podcastComment
    const commentReplys = useSelector((state) => state.podcastReplies)
    const { replyLoading, podcastCommentReplies } = commentReplys
    const profile = useSelector((state) => state.profile)
    const { userDetails } = profile

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (replyRef.current && !replyRef.current.contains(event.target)) ||
                (commentMenuRef.current && !commentMenuRef.current.contains(event.target)) ||
                (replyMenuRef.current && !replyMenuRef.current.contains(event.target))
            ) {
                setCommentMenu(null);
                setReplyMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(getPodcastComment(postId))
    }, [dispatch, postId]);

    const toggleReplies = (id) => {
        if (openReplyId !== id) {
            getCommentReplies(id)
        }
        setOpenReplyId((prev) => (prev === id ? null : id));
    };

    const handleReply = (id) => {
        setReplyId(id);
        setCommentId(id)
    }

    const handleEditComment = (id) => {
        setEditComment(true)
        setCommentId(id)
        setCommentText(podcastComments.find((comment) => comment.comment_id === id).content)
        if (!showCommentInput) {
            setShowCommentInput(true)
        }
    }
    const handleEditCommentReply = (id) => {
        setEditCommentReply(true)
        setReplyId(id)
        setReply(podcastCommentReplies.find((reply) => reply.reply_id === id).content)
    }

    const showInput = () => {
        setReplyId(null)
        setShowCommentInput(true)
        setCommentText("")
    }

    // comment section
    const handleChangeComment = (e) => {
        setCommentText(e.target.value)
    }

    const getVideoComments = async () => {
        await dispatch(getPodcastComment(postId))
    }
    const handleComment = () => {
        if (editComment) {
            handleUpdateComment(commentId)
        } else {
            handleSubmitComment(commentId)
        }
    }
    const handleSubmitComment = () => {
        dispatch(createPodcastComment({ data: { content: commentText }, podcast_id: postId })).then((result) => {
            if (result.error) {
                console.log("Comment failed")
            } else {
                getVideoComments()
                setCommentText("")
            }
        }
        )
    }
    const handleUpdateComment = () => {
        dispatch(updatePodcastComment({ commentId, data: { content: commentText } })).then((result) => {
            if (result.error) {
                console.log("Comment update failed")
            } else {
                getVideoComments()
                setCommentText("")
            }
        })
    }
    const handleDeleteComment = async (commentId) => {
        await dispatch(deletePodcastComment(commentId)).then((result) => {
            if (result.error) {
                console.log("Comment delete failed")
            } else {
                getVideoComments()
            }
        })

    }
    const handleLikeComment = async (commentId) => {
        await dispatch(likePodcastComment(commentId)).then((result) => {
            if (result.error) {
                console.log("Comment like failed")
            } else {
                getVideoComments()
            }
        })
    }
    const handleDislikeComment = async (commentId) => {
        await dispatch(dislikePodcastComment(commentId)).then((result) => {
            if (result.error) {
                console.log("Comment dislike failed")
            } else {
                getVideoComments()
            }
        })
    }

    //reply section
    const getCommentReplies = async (id) => {
        await dispatch(getPodcastCommentReplies(id))
    }
    const handleCommentReply = () => {
        if (editCommentReply) {
            handleUpdateCommentReply(commentId)
        } else {
            createSubmitReply(commentId)
        }
    }
    // create reply
    const createSubmitReply = (commentId) => {
        dispatch(createPodcastCommentReply({ data: { content: reply }, commentId })).then((result) => {
            if (result.error) {
                console.log("Comment failed")
            } else {
                getCommentReplies(commentId)
                setReplyId(null)
                setReply("")
            }
        }
        )
    }

    const handleDeleteCommentReply = async (replyId) => {
        await dispatch(deletePodcastCommentReply(replyId)).then((result) => {
            if (result.error) {
                console.log("Comment delete failed")
            } else {
                getCommentReplies(commentId)
            }
        })

    }
    const handleUpdateCommentReply = () => {
        dispatch(updatePodcastCommentReply({ replyId, data: { content: reply } })).then((result) => {
            if (result.error) {
                console.log("Comment Reply update failed")
            } else {
                getCommentReplies(commentId)
                setEditCommentReply(false)
                setReply("")
            }
        })
    }
    const handleLikeReply = async (replyId, comment_id) => {
        await dispatch(likePodcastCommentReply(replyId)).then((result) => {
            if (result.error) {
                console.log("Comment like failed")
            } else {
                getCommentReplies(comment_id)
            }
        })
    }
    const handleDislike = async (replyId, comment_id) => {
        dispatch(dislikePodcastCommentReply(replyId)).then((result) => {
            if (result.error) {
                console.log("Comment dislike failed")
            } else {
                getCommentReplies(comment_id)
            }
        })

    }

    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    const handleNavigate = (id) => {
        if (id === userDetails?.id) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${id}`)
        }
    }

    return (
        <Fragment>
            <div className="px-2 mb-2 h-[33rem] overflow-y-scroll">
                <div className="bg-[#353535] p-1 rounded-lg">
                    <div className="flex items-center justify-between my-2">
                        <button className="text-[12px] font-semibold bg-[#ffffff] px-2 py-1 rounded-full" onClick={showInput}>
                            Add Comments
                        </button>
                        <IoMdClose
                            onClick={closeComment}
                            className="text-[20px] text-white cursor-pointer"
                        />
                    </div>

                    {!commentLoading && Array.isArray(podcastComments) && podcastComments?.map((data) => {
                        const { comment_id, profile_picture, handle, content, user_has_liked, user_has_disliked } = data
                        return (

                            <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-1" key={comment_id}>
                                <div className="col-span-2">
                                    <img
                                        alt={handle}
                                        src={profile_picture}
                                        onClick={() => handleNavigate(data?.user)}
                                        className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                    />
                                </div>
                                <div className="col-span-10 -ml-3 relative">
                                    <div className="flex justify-between items-center">
                                        <p className="text-white text-[12px] font-semibold mt-[-2px]">{handle}</p>
                                        <div className="">
                                            <BsThreeDots color="white" className="cursor-pointer" onClick={() => setCommentMenu(comment_id)} />
                                        </div>
                                    </div>
                                    <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                        {content}
                                    </p>
                                    <div className="flex items-center mt-1">
                                        <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer" onClick={() => handleReply(comment_id)}>Reply</p>
                                        <IoMdHeart onClick={() => handleLikeComment(comment_id)} className={`h-3 w-3 ml-4  cursor-pointer ${user_has_liked ? "text-lime-500" : "text-white"} hover:text-lime-500`} />
                                        <IoMdHeartDislike onClick={() => handleDislikeComment(comment_id)} className={`h-3 w-3 ml-2 cursor-pointer ${user_has_disliked ? "text-red-600" : "text-white "} hover:text-red-600`} />
                                        <p
                                            onClick={() => toggleReplies(comment_id)}

                                            className="text-light-blue-900 hover:text-light-blue-800 text-[10px] ml-6 font-semibold cursor-pointer"
                                        >
                                            {openReplyId === comment_id ? "Hide" : "View"} Replies
                                        </p>
                                    </div>
                                    {openReplyId === comment_id && userDetails?.id === data?.user &&
                                        <>
                                            {replyLoading && <Skeleton count={2} baseColor="#202020" style={{ width: "100%", height: "2rem" }} highlightColor="#444" />}

                                            {!replyLoading && podcastCommentReplies && podcastCommentReplies?.map((data) => {
                                                return (
                                                    <div className="grid grid-cols-12 mt-2">
                                                        <div className="col-span-2">
                                                            <img
                                                                alt="profile"
                                                                src={data?.profile_picture}
                                                                onClick={() => handleNavigate(data?.user)}
                                                                className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                                            />
                                                        </div>
                                                        <div className="col-span-10">
                                                            <div className="flex items-center justify-between gap-x-4 relative">
                                                                <div className="flex items-center gap-x-4">
                                                                    <p className="text-white text-[12px] font-semibold mt-[-2px]">{data?.handle}</p>
                                                                    <p className="text-[10px] text-[#636363]">{timeAgo(data?.created_at)}</p>
                                                                    <p className="flex justify-center">
                                                                        <IoMdHeart onClick={() => handleLikeReply(data?.reply_id, comment_id)} className={`h-3 w-3 ml-4  cursor-pointer ${data.user_has_liked ? "text-lime-500" : "text-white"} hover:text-lime-500`} />
                                                                        <IoMdHeartDislike onClick={() => handleDislike(data?.reply_id, comment_id)} className={`h-3 w-3 ml-2 cursor-pointer ${data.user_has_disliked ? "text-red-600" : "text-white "} hover:text-red-600`} />
                                                                    </p>

                                                                </div>
                                                                <BsThreeDots color="white" className="cursor-pointer" onClick={() => setReplyMenu(data?.reply_id)} />

                                                                {replyMenu === data?.reply_id &&
                                                                    <div ref={replyMenuRef} className="absolute z-50 right-0 top-4 text-[10px] w-auto bg-white py-1 rounded-md">
                                                                        <p className="px-2 hover:bg-black hover:text-white text-black cursor-pointer" onClick={() => handleEditCommentReply(data?.reply_id)} >
                                                                            Edit
                                                                        </p>
                                                                        <p onClick={() => handleDeleteCommentReply(data?.reply_id)} className="px-2 hover:bg-black hover:text-white text-black cursor-pointer" >
                                                                            Delete
                                                                        </p>
                                                                    </div>}
                                                            </div>
                                                            <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                                                {data?.content}
                                                            </p>
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                            {
                                                !replyLoading && podcastCommentReplies.length === 0 && <p className="text-[10px] text-[#636363] text-center mt-2">No replies yet</p>
                                            }
                                        </>
                                    }
                                    {commentMenu === comment_id &&
                                        <div ref={commentMenuRef} className="absolute right-0 top-4 text-[10px] w-auto bg-black py-1 rounded-md">
                                            <p className="px-2 hover:bg-gray-600 text-white cursor-pointer" onClick={() => handleEditComment(comment_id)} >
                                                Edit
                                            </p>
                                            <p onClick={() => handleDeleteComment(comment_id)} className="px-2 hover:bg-gray-600 text-white cursor-pointer" >
                                                Delete
                                            </p>
                                            <p className="px-2 hover:bg-gray-600 text-white cursor-pointer" >
                                                Report
                                            </p>
                                        </div>}
                                </div>
                            </div>
                        )
                    }

                    )}
                    {commentLoading ? (
                        [1, 2, 3, 4, 5, 6].map(() => (
                            <div className="my-1 rounded-md">
                                <Skeleton baseColor="#202020" style={{ width: "100%", height: "4rem" }} highlightColor="#444" />
                            </div>
                        ))
                    ) : null}
                </div>
            </div>

            {showCommentInput &&
                <>
                    {!showComment && (
                        <div className="sticky bottom-[5.5rem] left-0 w-full bg-[#4e4e4e] px-3 py-2 flex items-center">
                            {/* input field for comment */}
                            {!replyId &&
                                (<>
                                    <input
                                        type="text"
                                        placeholder="Add a comment..."
                                        onChange={(e) => handleChangeComment(e)}
                                        value={commentText}
                                        className="flex-1 py-1 px-2 bg-[#353535] text-white rounded-md focus:outline-none"
                                    />
                                    <button onClick={() => handleComment()} className="bg-blue-500 text-white px-2 py-[7px] text-[12px] ml-2 rounded-md">
                                        Send
                                    </button>
                                </>)
                            }
                        </div>
                    )}
                </>
            }

            {replyId && (
                <div className="sticky bottom-[5.5rem] left-0 w-full bg-[#4e4e4e] px-3 py-2 flex items-center">
                    <input
                        type="text"
                        placeholder="Add a reply..."
                        onChange={(e) => setReply(e.target.value)}
                        value={reply}
                        className="flex-1 py-1 px-2 bg-[#353535] text-white rounded-md focus:outline-none"
                    />
                    <button type="submit" onClick={() => handleCommentReply(commentId)} className="bg-blue-500 text-white px-2 py-[7px] text-[12px] ml-2 rounded-md">
                        Send
                    </button>
                </div>
            )}
        </Fragment>
    );
};

export default CommentView;
