import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

//download list api
export const getDownloadList = createAsyncThunk(
    "getDownloadList",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/video/downloads/`, config);
        const response = await request.data;
        return response;
    }
);

export const getDownload = createAsyncThunk(
    "getDownload",
    async (postId) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/video/download/${postId}/`, config);
        const response = await request.data;
        return response;
    }
);

export const deleteDownloadVideo = createAsyncThunk(
    "deleteDownloadVideo",
    async (videoId) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/video/download/${videoId}/delete/`, config);
        const response = await request.data;
        return response;
    }
);
const downloadSlice = createSlice({
    name: "downloadSlice",
    initialState: {
        isLoading: false,
        downloadList: null,
        error: null,
    },
    extraReducers: (builder) => {
        //videoListSlice
        builder.addCase(getDownloadList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getDownloadList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.downloadList = action.payload;
            state.error = null;
        });
        builder.addCase(getDownloadList.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export default downloadSlice.reducer;
