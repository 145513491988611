import React, { useEffect, useRef, useState } from 'react'
import { mainHomePageData } from '../../data'

import Carousel from "react-multi-carousel";
import { DEFAULT_ID } from '../../utils/mainUrl';

import { FaShare } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { FaCommentDots } from "react-icons/fa6";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoEyeSharp, IoHeartSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { getCreatePosts } from '../../redux/apislice/createPost/createPostSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PiDotsNineBold } from "react-icons/pi";
import { getDraftPosts } from '../../redux/apislice/createPost/createPostDraftSlice';
import construction from "../../assets/construction.png";



const responsivePosted = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3.5,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3.5,
        slidesToSlide: 1
    }
};

export default function Posted({ userDetails }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeCategory, setActiveCategory] = useState("Videos"); // Track active category
    const [activePopupId, setActivePopupId] = useState(false);
    const popupRef = useRef(null);

    const posts = useSelector((state) => state.createPost)
    const { post } = posts;

    const DraftPosts = useSelector((state) => state.draft)
    const { draftData } = DraftPosts;
    const handleCategoryClick = (category) => {
        setActiveCategory(category);
    };

    const handlePopupClick = (id) => {
        setActivePopupId(id === activePopupId ? null : id); // Toggle the popup
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setActivePopupId(false);
        }
    };



    function timeAgo(timestamp) {
        const now = new Date(); // Current date and time
        const past = new Date(timestamp); // The timestamp you want to convert

        const diffInSeconds = Math.floor((now - past) / 1000); // Time difference in seconds

        // Define time intervals
        const intervals = {
            year: 31536000, // 365 days * 24 hours * 60 minutes * 60 seconds
            month: 2592000,  // 30 days * 24 hours * 60 minutes * 60 seconds
            week: 604800,    // 7 days * 24 hours * 60 minutes * 60 seconds
            day: 86400,      // 24 hours * 60 minutes * 60 seconds
            hour: 3600,      // 60 minutes * 60 seconds
            minute: 60,      // 60 seconds
            second: 1        // 1 second
        };

        // Calculate the largest time interval and its corresponding value
        for (const [key, value] of Object.entries(intervals)) {
            const timeDifference = Math.floor(diffInSeconds / value);
            if (timeDifference >= 1) {
                return `${timeDifference} ${key}${timeDifference > 1 ? 's' : ''} ago`;
            }
        }

        return 'just now'; // Default if the difference is less than 1 second
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(getCreatePosts())
        dispatch(getDraftPosts())
    }, [dispatch])

    return (
        <div className="pb-10 grid grid-cols-12 gap-2">
            {/* <div className="col-span-12 flex items-center justify-between">
        <IoMdArrowRoundBack
            onClick={() => setSelectedId(null)}
            className="text-[15px] text-white cursor-pointer"
        />
        <div className="flex items-center gap-5">
            <p className="text-[12px] text-[#00C2FF]">Total 6 Videos</p>
            <FaFilter className="text-[12px] text-white cursor-pointer" />
        </div>
    </div> */}
            <hr className="col-span-12" />
            <div className="col-span-12 px-3 pt-[4px] pb-[4px] bg-black sticky -top-[1px] z-40">
                <Carousel
                    ssr={true}
                    arrows={false}
                    infinite={false}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsivePosted}
                    className="select-none"
                >
                    {["Videos", "Posts", "Clips", "Draft", "Schedule"].map((category) => (
                        <p
                            key={category}
                            onClick={() => handleCategoryClick(category)}
                            className={`text-[11px] w-[5rem] py-[2px] rounded-md flex items-center justify-center cursor-pointer ${activeCategory === category
                                ? "border text-white border-b-2 border-[#3dff44]"
                                : "bg-[#353535] text-white hover:bg-[#3e3e3e]"
                                }`}
                        >
                            {category}
                        </p>
                    ))}
                </Carousel>
            </div>
            {activeCategory === "Videos" && <div className="px-2 col-span-12 grid grid-cols-12 gap-2">
                {mainHomePageData.map((item) => (
                    <div className="col-span-6  flex flex-col items-center" key={item.id}>
                        <img
                            alt="post"
                            src={item.thumbnail}
                            onClick={() => navigate(`/video/${DEFAULT_ID}`)}
                            className="w-full h-[5.3rem] object-cover rounded-[6px] cursor-pointer"
                        />
                        <div className="grid grid-cols-12 px-[3px] items-center bg-white w-[90%] rounded-b-[6px]">
                            <div className="col-span-3 flex items-center justify-start">
                                <p className="text-[8px]">{item.videoLength}</p>
                            </div>
                            <div className="col-span-8">
                                <p className="text-[8px] line-clamp-1">{item.title}</p>
                            </div>
                            <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                <p
                                    onClick={() => handlePopupClick(item.id)}
                                    className="text-[8px] font-semibold cursor-pointer hover:text-green-600"
                                >
                                    : :
                                </p>
                                {activePopupId === item.id && (
                                    <div className="absolute top-0 -right-2 bg-[#f8f8f8] rounded">
                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#646464] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                            <FaShare className="text-[11px]" />
                                            <p className="text-[10px]">Share</p>
                                        </div>
                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                            <FaRegEdit className="text-[11px]" />
                                            <p className="text-[10px]" >Edit</p>
                                        </div>
                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-[#6d6d6d] hover:text-[#f75252] cursor-pointer">
                                            <RiDeleteBin5Fill className="text-[11px]" />
                                            <p className="text-[10px]" >Delete</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-span-12 flex items-center justify-around my-[2px]">
                                <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                    <IoEyeSharp className="text-[11px]" />
                                    <p className="text-[6px]">{item.views}</p>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                    <FaShare className="text-[11px]" />
                                    <p className="text-[6px]">105K</p>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                    <IoHeartSharp className="text-[11px]" />
                                    <p className="text-[6px]">586K</p>
                                </div>
                                <div className="flex flex-col items-center cursor-pointer text-[#6d6d6d]">
                                    <FaCommentDots className="text-[11px]" />
                                    <p className="text-[6px]">60K</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>}
            {activeCategory === "Posts" &&
                <div className="col-span-12 ">
                    {post?.map((item) => (
                        <>
                            <div className='px-2 grid grid-cols-12 gap-2'>
                                <div className='col-span-12 rounded'>
                                    <div className='px-2'>
                                        <div className='py-2 grid grid-cols-12  gap-0 items-center'>
                                            <img src={userDetails?.profile_picture} alt="post" className="col-span-1 w-6 h-6 object-cover rounded-full cursor-pointer" onClick={() => navigate(`/video/${DEFAULT_ID}`)} />
                                            <div className="col-span-10">
                                                <p className='text-white text-[12px] font-semibold px-2 line-clamp-1'>
                                                    {item?.text}
                                                </p>
                                                <p className='text-blue-500 text-[8px] font-semibold px-2'>
                                                    {timeAgo(item?.created_at)}
                                                </p>
                                            </div>
                                            <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                                <p
                                                    onClick={() => handlePopupClick(item?.id)}
                                                    className="text-[14px] text-white font-bold cursor-pointer hover:text-green-600"
                                                >
                                                    <PiDotsNineBold />
                                                </p>
                                                {activePopupId === item.id && (
                                                    <div className="absolute top-0 -right-2 bg-[#4f4e4e] rounded">
                                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-white hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                            <FaShare className="text-[11px]" />
                                                            <p className="text-[10px]">Share</p>
                                                        </div>
                                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-white hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                            <FaRegEdit className="text-[11px]" />
                                                            <p className="text-[10px]" >Edit</p>
                                                        </div>
                                                        <div className="flex items-center gap-[5px] px-1 py-[2px] text-white hover:text-[#f75252] cursor-pointer">
                                                            <RiDeleteBin5Fill className="text-[11px]" />
                                                            <p className="text-[10px]" >Delete</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-span-12 flex flex-col items-center" key={item.id}>
                                            <img
                                                alt="post"
                                                src={item?.image1}
                                                onClick={() => navigate(`/video/${DEFAULT_ID}`)}
                                                className="w-full max-h-max object-cover rounded-[6px] cursor-pointer"
                                            />
                                            <div className="grid grid-cols-12 items-center w-[100%] rounded-b-[6px]">
                                                <div className="col-span-3 flex items-center justify-start">
                                                    <p className="text-[8px]">{item?.videoLength}</p>
                                                </div>
                                                <div className="col-span-12 py-[1px] flex items-center justify-around my-[2px]">
                                                    <div className="flex flex-col items-center cursor-pointer text-white">
                                                        <IoEyeSharp className="text-[13px]" />
                                                        <p className="text-[6px]">586K</p>
                                                    </div>
                                                    <div className="flex flex-col items-center cursor-pointer text-white">
                                                        <FaShare className="text-[13px]" />
                                                        <p className="text-[6px]">105K</p>
                                                    </div>
                                                    <div className="flex flex-col items-center cursor-pointer text-white">
                                                        <IoHeartSharp className="text-[13px]" />
                                                        <p className="text-[6px]">586K</p>
                                                    </div>
                                                    <div className="flex flex-col items-center cursor-pointer text-white">
                                                        <FaCommentDots className="text-[13px]" />
                                                        <p className="text-[6px]">60K</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='w-full py-1 text-gray-400' />
                        </>
                    ))}
                    {post?.length === 0 &&
                        <div className="col-span-12 flex flex-col items-center justify-center h-96 bg-gradient-to-r from-teal-400 to-gray-800">
                            <p className="text-gray-300 text-[13px] mb-4 leading-4 px-5 text-center">
                                No post available. Please create a post.
                            </p>
                        </div>}
                </div>}
            {/* need to discuss with team for remove the draft inside account */}
            {activeCategory === "Draft" &&
                <>
                    <div className="px-2 col-span-12 grid grid-cols-12 gap-2 ">
                        {draftData?.map((item) => (
                            <div className='bg-white col-span-12 px-2 py-1 rounded'>
                                <div className='py-2 grid grid-cols-12  gap-0 items-center'>
                                    <img src={userDetails?.profile_picture} alt="post" className="col-span-1 w-6 h-6 object-cover rounded-full cursor-pointer" onClick={() => navigate(`/video/${DEFAULT_ID}`)} />
                                    <div className="col-span-10">
                                        <p className='text-black text-[12px] font-semibold px-2'>
                                            {item?.text}
                                        </p>
                                        <p className='text-blue-500 text-[8px] font-semibold px-2'>
                                            {timeAgo(item?.created_at)}
                                        </p>
                                    </div>
                                    <div ref={popupRef} className="col-span-1 flex items-center justify-end relative">
                                        <p
                                            onClick={() => handlePopupClick(item?.id)}
                                            className="text-[14px] font-bold cursor-pointer hover:text-green-600"
                                        >
                                            <PiDotsNineBold />
                                        </p>
                                        {activePopupId === item?.id && (
                                            <div ref={popupRef} className="absolute top-0 w-20 -right-0 bg-black rounded">
                                                <div className="flex items-center gap-[5px] px-1 py-[2px] text-white hover:text-[#f75252] cursor-pointer border-b-[0.4px] border-[#c4c4c4]">
                                                    <FaRegEdit className="text-[11px]" />
                                                    <p className="text-[10px]" onClick={() => navigate("/createpost")} >Edit Draft</p>
                                                </div>
                                                <div className="flex items-center gap-[5px] px-1 py-[2px] text-white hover:text-[#f75252] cursor-pointer">
                                                    <RiDeleteBin5Fill className="text-[11px]" />
                                                    <p className="text-[10px]" >Delete</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-12 flex flex-col items-center" key={item?.id}>
                                    <img
                                        alt="post"
                                        src={item?.image1}
                                        onClick={() => navigate(`/video/${DEFAULT_ID}`)}
                                        className="w-full max-h-max object-cover rounded-[6px] cursor-pointer"
                                    />
                                </div>
                            </div>
                        ))}

                    </div>
                    {draftData?.length === 0 && <div className="col-span-12 flex flex-col items-center justify-center h-96 bg-gradient-to-r from-teal-400 to-gray-800">
                        <p className="text-gray-300 text-[13px] mb-4 leading-4 px-5 text-center">
                            No draft post available. Please create a draft post.
                        </p>
                    </div>}
                </>}
            {activeCategory !== "Videos" && activeCategory !== "Posts" && activeCategory !== "Draft" &&
                <div className="col-span-12   flex flex-col items-center justify-center h-96 bg-gradient-to-r from-teal-400 to-gray-800">
                    <img
                        src={construction}
                        alt="Under Construction"
                        className="w-28 h-28 object-cover rounded-full mb-4"
                    />
                    <p className="text-gray-300 text-[13px] mb-4 leading-4 px-5 text-center">
                        Work in progress. This page is under construction. Please check back later.
                    </p>
                </div>}
        </div>
    )
}
