import React, { Fragment, useEffect, useState } from "react";
import { copyright } from "../utils/copyright";
import MainMenu from "../components/common/MainMenu";
import { useNavigate } from "react-router-dom";
import { IoNotifications, IoSearch } from "react-icons/io5";
import { FaCalendarDay, FaCalendarPlus, FaDeleteLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { allEventList, createEvent, deleteEvent } from "../redux/apislice/CalendarSlice";
import BeatLoader from "react-spinners/BeatLoader";

const CalendarPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showForm, setShowForm] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        date: "",
        time: "",
        description: ""
    });

    const { data = [], loading } = useSelector(state => state.calendar);

    useEffect(() => {
        dispatch(allEventList());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resultAction = await dispatch(createEvent(formData));
        if (createEvent.fulfilled.match(resultAction)) {
            // const newEvent = resultAction.payload;
            setFormData({ title: "", date: "", time: "", description: "" }); // Reset form data
            setShowForm(false);
        }
    };

    const handleDeleteEvent = (id) => {
        dispatch(deleteEvent(id));
        setShowConfirmation(false); // Close confirmation dialog
    };

    const today = new Date().toISOString().split('T')[0];
    const todaysEvents = data?.filter(event => event?.date && event.date === today) || [];
    const upcomingEvents = data?.filter(event => event?.date && event.date > today) || [];

    // Utility function to convert 24-hour time to 12-hour format with AM/PM
    const convertTo12HourFormat = (time) => {
        const [hours, minutes] = time.split(":");
        const hour = parseInt(hours, 10);
        const isPM = hour >= 12;
        const formattedHour = hour % 12 || 12; // Convert 0 hour to 12
        const formattedTime = `${formattedHour}:${minutes} ${isPM ? 'PM' : 'AM'}`;
        return formattedTime;
    };

    return (
        <Fragment>
            {/* Label Section */}
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / KV Calendar / V2 / 16 OCT, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            {/* Header Section */}
            <div className="bg-white px-2 grid grid-cols-12 items-center sticky top-0">
                <div className="col-span-6 flex items-center justify-start gap-6">
                    <h1 className="text-[1.6rem] font-bold uppercase text-black cursor-pointer" onClick={() => navigate("/home")}>
                        kv
                    </h1>
                    <IoSearch
                        onClick={() => navigate("/search")}
                        className="text-[20px] text-black cursor-pointer"
                    />
                </div>
                <div className="col-span-6 flex items-center justify-end gap-4">
                    <div className="relative">
                        <IoNotifications
                            onClick={() => navigate("/notification")}
                            className="text-[22px] md:text-[20px] cursor-pointer"
                        />
                        <div className="absolute -top-[5px] -right-[6px] flex items-center justify-center h-[13px] w-[13px] rounded-full bg-[#f83131]">
                            <p className="text-[8px] text-white">5</p>
                        </div>
                    </div>
                    <img
                        alt="profile"
                        onClick={() => navigate("/account")}
                        className="w-[1.3rem] h-[1.3rem] object-cover rounded-full cursor-pointer"
                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                    <MainMenu />
                </div>
            </div>

            {/* Calendar Section */}
            <div className="p-2">
                <div
                    onClick={() => setShowForm(true)}
                    className={`flex items-center justify-center gap-x-3 bg-[#363636] rounded-md py-[6px] cursor-pointer`}
                >
                    <p className="text-[#ffffff] text-[14px] text-center font-[500]">
                        Schedule your day
                    </p>
                    <FaCalendarPlus className="text-[16px] text-[#ffffff]" />
                </div>

                {showForm && (
                    <form onSubmit={handleSubmit}>
                        <input
                            required
                            type="text"
                            name="title"
                            placeholder="Add a title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className="w-full p-[6px] mt-2 rounded-md focus:outline-none placeholder:text-[14px]"
                        />
                        <input
                            required
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            className="w-full p-[6px] mt-2 rounded-md focus:outline-none placeholder:text-[14px]"
                        />
                        <input
                            required
                            type="time"
                            name="time"
                            value={formData.time}
                            onChange={handleInputChange}
                            className="w-full p-[6px] mt-2 rounded-md focus:outline-none placeholder:text-[14px]"
                        />
                        <textarea
                            rows={3}
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            placeholder="Add a description"
                            className="w-full p-[6px] mt-2 rounded-md resize-none focus:outline-none placeholder:text-[14px]"
                        />
                        <div className="flex items-center gap-x-2">
                            <button
                                type="button"
                                onClick={() => setShowForm(false)}
                                className="w-full p-[6px] text-[14px] bg-[#e53e3e] hover:bg-[#f32d2d] text-white rounded-md transition-all duration-300 ease-in-out"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="w-full p-[6px] text-[14px] bg-[#4b4b4b] hover:bg-[#393939] text-white rounded-md transition-all duration-300 ease-in-out"
                            >
                                Add Event
                            </button>
                        </div>
                    </form>
                )}
            </div>

            {/* Calendar Data */}
            <div className={`px-2 ${showForm ? "mt-10" : "mt-1"}`}>
                {loading &&
                    <div className="flex items-center justify-center">
                        <BeatLoader
                            color={"#fff"}
                            loading={true}
                            size={15}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                }

                {todaysEvents.length > 0 && (
                    <div>
                        <h2 className="text-[#4b4b4b] text-[16px] font-bold mb-1">Today's Events</h2>
                        {todaysEvents.map((event) => (
                            <div key={event.id} className="bg-[#e7e7e7] p-2 rounded-md mb-2">
                                {showConfirmation === event.id ?
                                    <div className="flex flex-col">
                                        <p className="text-[#313131] text-[14px] text-center mb-4">
                                            Are you sure, you want to delete this event?
                                        </p>
                                        <div className="flex items-center gap-x-2">
                                            <button
                                                type="button"
                                                onClick={() => handleDeleteEvent(event.id)}
                                                className="w-full p-[6px] text-[14px] bg-[#000000] text-white rounded-md transition-all duration-300 ease-in-out"
                                            >
                                                Yes
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowConfirmation(false)}
                                                className="w-full p-[6px] text-[14px] bg-[#ffffff] text-black rounded-md transition-all duration-300 ease-in-out"
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex flex-col">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-10 flex items-center justify-start gap-x-2">
                                                <FaCalendarDay className="text-[#313131] text-[16px] cursor-pointer" />
                                                <p className="text-[#313131] text-[16px] font-[500]">
                                                    {event.title}
                                                </p>
                                            </div>
                                            <div className="col-span-2 flex items-center justify-end">
                                                <FaDeleteLeft
                                                    onClick={() => setShowConfirmation(event.id)}
                                                    className="text-[#000000] text-[20px] cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                        <p className="text-[#313131] text-[14px] font-[400]">
                                            {event.description}
                                        </p>
                                        <p className="text-[#313131] text-[12px] font-[400]">
                                            {event.date} at {convertTo12HourFormat(event.time)}
                                        </p>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                )}

                {upcomingEvents.length > 0 && (
                    <div className="mt-4">
                        <h2 className="text-[#4b4b4b] text-[16px] font-bold mb-1">Upcoming Events</h2>
                        {upcomingEvents.map((event) => (
                            <div key={event.id} className="bg-[#e7e7e7] p-2 rounded-md mb-2">
                                {showConfirmation === event.id ?
                                    <div className="flex flex-col">
                                        <p className="text-[#313131] text-[14px] text-center mb-4">
                                            Are you sure, you want to delete this event?
                                        </p>
                                        <div className="flex items-center gap-x-2">
                                            <button
                                                type="button"
                                                onClick={() => handleDeleteEvent(event.id)}
                                                className="w-full p-[6px] text-[14px] bg-[#000000] text-white rounded-md transition-all duration-300 ease-in-out"
                                            >
                                                Yes
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowConfirmation(false)}
                                                className="w-full p-[6px] text-[14px] bg-[#ffffff] text-black rounded-md transition-all duration-300 ease-in-out"
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <div className="flex flex-col">
                                        <div className="grid grid-cols-12">
                                            <div className="col-span-10 flex items-center justify-start gap-x-2">
                                                <FaCalendarDay className="text-[#313131] text-[16px] cursor-pointer" />
                                                <p className="text-[#313131] text-[16px] font-[500]">
                                                    {event.title}
                                                </p>
                                            </div>
                                            <div className="col-span-2 flex items-center justify-end">
                                                <FaDeleteLeft
                                                    onClick={() => setShowConfirmation(event.id)}
                                                    className="text-[#000000] text-[20px] cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                        <p className="text-[#313131] text-[14px] font-[400]">
                                            {event.description}
                                        </p>
                                        <p className="text-[#313131] text-[12px] font-[400]">
                                            {event.date} at {convertTo12HourFormat(event.time)}
                                        </p>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default CalendarPage;
