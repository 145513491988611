import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

export const createSubscription = createAsyncThunk(
    "createSubscription",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/subscriptions/`, data, config);
        const response = await request.data;
        return response;
    }
);

export const DeleteSubscription = createAsyncThunk(
    "DeleteSubscription",
    async (id) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/subscriptions/${id}/`, config);
        const response = await request.data;
        return response;
    }
);

export const getAllUserSubscription = createAsyncThunk(
    "getAllUserSubscription",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/subscriptions/`, config);
        const response = await request.data;
        return response;
    }
);
export const getUserSubscription = createAsyncThunk(
    "getUserSubscription",
    async (id) => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/subscriptions/users/${id}/`, config);
        const response = await request.data;
        return response;
    }
);

const subscriptionSlice = createSlice({
    name: "subscriptionSlice",
    initialState: {
        loading: false,
        subscribe: null,
        subscribeList: null,
        userSubscribeList: null,
        error: null,
    },
    extraReducers: (builder) => {
        //createSubscription
        builder.addCase(createSubscription.pending, (state) => {
            state.loading = true;
            state.subscribe = null;
            state.error = null;

        });
        builder.addCase(createSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.subscribe = action.payload;
            state.error = null;

        });
        builder.addCase(createSubscription.rejected, (state) => {
            state.loading = false;
            state.subscribe = null;
            state.error = true;

        });

        //DeleteSubscription
        builder.addCase(DeleteSubscription.pending, (state) => {
            state.loading = true;
            state.subscribe = null;
            state.error = null;

        });
        builder.addCase(DeleteSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.subscribe = action.payload;
            state.error = null;

        });
        builder.addCase(DeleteSubscription.rejected, (state) => {
            state.loading = false;
            state.subscribe = null;
            state.error = true;

        });
        //getAllUserSubscription
        builder.addCase(getAllUserSubscription.pending, (state) => {
            state.loading = true;
            state.subscribeList = null;
            state.error = null;

        });
        builder.addCase(getAllUserSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.subscribeList = action.payload;
            state.error = null;

        });
        builder.addCase(getAllUserSubscription.rejected, (state) => {
            state.loading = false;
            state.subscribeList = null;
            state.error = true;

        });
        //getUserSubscription
        builder.addCase(getUserSubscription.pending, (state) => {
            state.loading = true;
            state.userSubscribeList = null;
            state.error = null;

        });
        builder.addCase(getUserSubscription.fulfilled, (state, action) => {
            state.loading = false;
            state.userSubscribeList = action.payload;
            state.error = null;

        });
        builder.addCase(getUserSubscription.rejected, (state) => {
            state.loading = false;
            state.userSubscribeList = null;
            state.error = true;

        });
    }
});

export default subscriptionSlice.reducer;
