import React, { Fragment, useState, useCallback, useRef, useEffect } from "react";
import { mainHomePageData } from "../../data";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'js-cookie';
import toast, { Toaster } from "react-hot-toast";
import { createSubscription, DeleteSubscription, getAllUserSubscription, getUserSubscription } from "../../redux/apislice/subscriptionSlice";
// import { fetchVideoDetails } from "../../redux/apislice/videoDetailsSlice";
import { copyright } from "../../utils/copyright";
import VideoHeader from "../postVideo/VideoHeader";
import MainVideoControls from "../postVideo/MainVideoControls";
import VideoControls from "../postVideo/VideoControls";
import RelatedVideos from "../postVideo/RelatedVideos";
import FourDotDrawer from "../common/FourDotDrawer";
import { createPodcastComment, deletePodcastComment, dislikePodcastComment, getPodcastComment, likePodcastComment } from "../../redux/apislice/podcast/commentsSlice";
import { createPodcastCommentReply, getPodcastCommentReplies } from "../../redux/apislice/podcast/podcastReplies";
import CommentView from "./CommentView";
import { getPodcastById, likeAndDislikePodcast } from "../../redux/apislice/podcast/podcastSlice";

const PodcastVideoDetails = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const videoRef = useRef(null);

    // const [isSubscribe, setIsSubscribe] = useState(true);
    const [menu, setMenu] = useState(false);
    const [showComment, setShowComment] = useState(true);
    // const [showCommentInput, setShowCommentInput] = useState(false);
    const [showTitle, setShowTitle] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showDescription, setShowDescription] = useState(false);

    const video = useSelector((state) => state.videoDetails);
    const { videoData, isLoading } = video;

    const podcast = useSelector((state) => state.podcast);
    const podcastComment = useSelector((state) => state.podcastComment);
    const {
        podcastComments, commentLoading
    } = podcastComment;
    const { podcast: podcastData } = podcast;
    // const videoData1 = podcastData?.results?.filter((data) => data.id === id);
    // const videoData1 = filterVideoData
    const subscriptionlist = useSelector((state) => state.subscription);
    const { subscribeList, userSubscribeList } = subscriptionlist;
    const [progress, setProgress] = useState(0);

    const [mute, setMute] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isPlaying, setIsPlaying] = useState(true);
    const [openVideoInfo, setOpenVideoInfo] = useState(null);
    const user = Cookies.get("userId");

    //comment state
    const [comment, setComment] = useState("");


    const subscribe = subscribeList?.results?.some((data) => data.channel.id === videoData?.author?.id);
    const filterData = mainHomePageData.filter((data) => data.id === openVideoInfo);

    const handleToggle = (id) => {
        if (openVideoInfo === id) {
            setOpenVideoInfo(null);
        } else {
            setOpenVideoInfo(id);
        }
    };

    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                if (videoRef.current) {
                    setCurrentTime(videoRef.current.currentTime);
                    setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
                }
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isPlaying]);

    const handleReplay = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - 10);
            setCurrentTime(videoRef.current.currentTime);
            setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
            console.log("Replayed 10 seconds", videoRef.current.currentTime);
        }
    };

    const handleForward = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = Math.min(videoRef.current.duration, videoRef.current.currentTime + 10);
            setCurrentTime(videoRef.current.currentTime);
            setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
            console.log("Forwarded 10 seconds", videoRef.current.currentTime);
        }
    };

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch(error => {
                    console.error("Error attempting to play video:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    // api calls
    const handleClickSub = () => {
        if (subscribe) {
            deleteSubscribe();
        } else {
            handleSubscribe();
        }
    }

    const handleSubscribe = async () => {
        // setIsSubscribe(!isSubscribe);
        try {
            const rest = await dispatch(createSubscription({ channel_id: videoData.author.id, user, notifications: "all" }))
            if (rest.error) {
                toast.error("Subscription failed")
            }
            else {
                dispatch(getUserSubscription(videoData?.author?.id))
                dispatch(getAllUserSubscription())
            }
        } catch (error) {
            console.log(error, "Subscription failed");

        }
    }

    const deleteSubscribe = () => {
        const filterData = subscribeList?.results?.filter((data) => data.channel.id === videoData?.author?.id);
        const chennel_id = filterData[0].id;
        // setIsSubscribe(!isSubscribe);
        dispatch(DeleteSubscription(chennel_id)).then((result) => {
            if (result.error) {

                console.log(result, "Subscription failed");
            } else {
                dispatch(getUserSubscription(videoData?.author?.id))
                dispatch(getAllUserSubscription())
            }
        });
    }

    const handleSeek = (e) => {
        if (videoRef.current) {
            const seekTime = (e.nativeEvent.offsetX / e.target.clientWidth) * videoRef.current.duration;
            videoRef.current.currentTime = seekTime;
            setCurrentTime(seekTime);
            setProgress((seekTime / videoRef.current.duration) * 100);
        }
    };

    const handleNavigate = (id) => {
        if (id === user) {
            navigate("/account")
        } else {
            navigate(`/otheruser/account/${id}`)
        }
    }

    // video like and dislike function
    const handleLike = async () => {
        await dispatch(likeAndDislikePodcast(id)).then((result) => {
            if (result.error) {
                toast.error("Like failed")
            } else {
                // dispatch(getPodcastById(id))
            }
        })
    }


    // comment section
    const handleChangeComment = (e) => {
        setComment(e.target.value)
    }

    const getVideoComments = async () => {
        await dispatch(getPodcastComment(id))
    }
    const handleSubmitComment = () => {
        dispatch(createPodcastComment({ data: { content: comment }, podcast_id: id })).then((result) => {
            if (result.error) {
                toast.error("Comment failed")
            } else {
                getVideoComments()
                setComment("")
            }
        }
        )
    }
    const handleDeleteComment = async (commentId) => {
        await dispatch(deletePodcastComment(commentId)).then((result) => {
            if (result.error) {
                toast.error("Comment delete failed")
            } else {
                getVideoComments(id)
            }
        })

    }
    const handleLikeComment = async (commentId) => {
        await dispatch(likePodcastComment(commentId)).then((result) => {
            if (result.error) {
                toast.error("Comment like failed")
            } else {
                getVideoComments(id)
            }
        })
    }
    const handleDislikeComment = async (commentId) => {
        await dispatch(dislikePodcastComment(commentId)).then((result) => {
            if (result.error) {
                toast.error("Comment dislike failed")
            } else {
                getVideoComments(id)
            }
        })
    }

    //reply section
    const getCommentReplies = async (id) => {
        await dispatch(getPodcastCommentReplies(id))
    }
    // create reply
    const createReply = async (data, event) => {
        await dispatch(createPodcastCommentReply(data)).then((result) => {
            if (result.error) {
                toast.error("Reply failed")
            } else {
                getCommentReplies(data.id)
            }
        }
        )
    }

    const fetchData = useCallback(async () => {
        // await dispatch(fetchVideoDetails(id));
        await dispatch(getPodcastById(id));
        await dispatch(getAllUserSubscription())
    }, [dispatch, id])



    const handleSomeAction = useCallback(async () => {
        if (videoData?.author?.id) {
            await dispatch(getUserSubscription(videoData?.author?.id));
        }
    }, [videoData?.author?.id, dispatch]);

    useEffect(() => {
        handleSomeAction();
    }, [handleSomeAction]);


    useEffect(() => {
        fetchData()
    }, [fetchData])

    if (!podcastData?.video) {
        return <div>Video not found</div>;
    }

    const handleComment = () => {
        setShowComment(!showComment);
        // setShowCommentInput(false);
    }

    return (
        <Fragment>
            <Toaster position="top-right" />
            <div className="bg-black h-full relative">
                <div className="bg-[#d9d9d9] text-center">
                    <p className="text-red-600 text-[9px] uppercase font-bold">
                        KV / FE / podcast Video Details / V3 / 01 Oct, 2024
                    </p>
                    <p className="text-black text-[9px] mt-[-2px]">
                        {copyright}
                    </p>
                </div>

                <div className="sticky top-0 z-50">
                    <VideoHeader
                        title={podcastData?.host_name}
                        author={videoData?.author.handle}
                        showTitle={showTitle}
                        setShowTitle={setShowTitle}
                        menu={menu}
                        setMenu={setMenu}
                        navigate={navigate}
                    />

                    <div className="p-2 relative group bg-black">
                        {!isLoading && (
                            <video
                                loop
                                autoPlay
                                muted={true}
                                ref={videoRef}
                                disablePictureInPicture
                                src={podcastData.video}
                                poster={podcastData.thumbnail}
                                onLoadedMetadata={() => setDuration(videoRef.current.duration)}
                                className="w-full h-[9.5rem] min-h-[10rem] object-cover rounded-sm"
                            />
                        )}
                        <MainVideoControls
                            handleReplay={handleReplay}
                            handlePlayPause={handlePlayPause}
                            isPlaying={isPlaying}
                            handleForward={handleForward}
                            handleSeek={handleSeek}
                            progress={progress}
                            currentTime={currentTime}
                            duration={duration}
                            formatTime={formatTime}
                            mute={mute}
                            setMute={setMute}
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                        />
                    </div>
                </div>

                {showComment && (
                    <VideoControls
                        // videoData={videoData}
                        title={podcastData?.host_name}
                        author={podcastData?.author?.username}
                        authorId={podcastData?.creator}
                        authorProfile={podcastData?.creator_profile_picture}
                        viewCount={podcastData?.view_count}
                        likeCount={podcastData?.like_count}
                        description={podcastData?.podcast_name}
                        handleNavigate={handleNavigate}
                        handleClickSub={handleClickSub}
                        subscribe={subscribe}
                        userSubscribeList={userSubscribeList}
                        showDescription={showDescription}
                        setShowDescription={setShowDescription}
                        showComment={showComment}
                        setShowComment={setShowComment}
                        handleLike={handleLike}
                        userLiked={podcastData?.is_liked_by_user}
                        downloadVideo={() => console.log("download")}
                        handleToggle={handleToggle}


                    />
                )}

                {showComment && (
                    <RelatedVideos
                        id={id}
                        mainHomePageData={mainHomePageData}
                        navigate={navigate}
                        openVideoInfo={openVideoInfo}
                        handleToggle={handleToggle}
                        relatedVideoData={[]}
                    />
                )}

                {!showComment && (
                    // <div className="relative">
                    <CommentView
                        closeComment={handleComment}
                        showComment={showComment}
                        postId={id}
                        commentText={comment}
                        handleChangeComment={handleChangeComment}
                        handleSubmitComment={handleSubmitComment}
                        getVideoComments={getVideoComments}
                        DeleteComment={handleDeleteComment}
                        likeComment={handleLikeComment}
                        dislikeComment={handleDislikeComment}
                        getCommentReplies={getCommentReplies}
                        comments={podcastComments}
                        createReply={createReply}
                        loading={commentLoading}
                    />
                    // </div>
                )}
            </div>
            {openVideoInfo && (
                <FourDotDrawer
                    title={filterData[0]?.title}
                    onClose={() => handleToggle(openVideoInfo)}
                    savePlaylist={() => handleToggle(openVideoInfo)}
                />
            )}
        </Fragment>
    );
};

export default PodcastVideoDetails;