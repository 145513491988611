import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const mainUrl = REACT_APP_MAIN_URL

export const userSignup = createAsyncThunk(
    "userSignup",
    async (signupDetails) => {
        const request = await axios.post(`${mainUrl}api/signup/`, signupDetails);
        const response = await request.data;
        return response;
    }
);
export const verifySignup = createAsyncThunk(
    "verifySignup",
    async (signupDetails) => {
        const request = await axios.post(`${mainUrl}api/signup_verify-otp/`, signupDetails);
        const response = await request.data;
        return response;
    }
);


export const userLogin = createAsyncThunk(
    "userLogin",
    async (signupDetails) => {
        const request = await axios.post(`${mainUrl}api/login/`, signupDetails);
        const response = await request.data;
        return response;
    }
);

const authSlice = createSlice({
    name: "security",
    initialState: {
        loading: false,
        user: null,
        error: null,
    },
    extraReducers: (builder) => {
        //userSignup
        builder.addCase(userSignup.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(userSignup.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(userSignup.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });

        //userLogin
        builder.addCase(userLogin.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(userLogin.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(userLogin.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });
        //verifySignup
        builder.addCase(verifySignup.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        });
        builder.addCase(verifySignup.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        });
        builder.addCase(verifySignup.rejected, (state) => {
            state.loading = false;
            state.user = null;
            state.error = true;
        });


    }
});

export default authSlice.reducer;
