import Cookies from 'js-cookie';

const getAccessConfig = () => {
   const accessToken = Cookies.get("accessToken");

   if (!accessToken) {
      throw new Error("No access token found");
   }

   return {
      headers: {
         Authorization: `Bearer ${accessToken}`
      }
   };
};

export default getAccessConfig;