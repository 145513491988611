import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createPodcast = createAsyncThunk(
    "createPodcast",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/podcasts/`, data,
                {
                    headers: config.headers,
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(setUploadProgress(percentCompleted));
                    },
                }

            );
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getAllPodcast = createAsyncThunk(
    "getPodcast",
    async (_, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/podcast/all_podcasts/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getPodcastById = createAsyncThunk(
    "getPodcastById",
    async (podcastId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/podcast/podcasts/${podcastId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const likeAndDislikePodcast = createAsyncThunk(
    "likeAndDislikePodcast",
    async (podcast_id, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/podcasts/${podcast_id}/like/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const podcastSlice = createSlice({
    name: "podcastSlice",
    initialState: {
        loading: false,
        podcast: [],
        error: null,
        progress: 0,
    },
    reducers: {
        setUploadProgress: (state, action) => {
            state.progress = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Create post
        builder.addCase(createPodcast.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createPodcast.fulfilled, (state, action) => {
            state.loading = false;
            state.podcast = action.payload;
            state.error = null;;
        });
        builder.addCase(createPodcast.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // Get all  podcast
        builder.addCase(getAllPodcast.pending, (state) => {
            state.loading = true;
            state.podcast = [];
            state.error = null;
        });
        builder.addCase(getAllPodcast.fulfilled, (state, action) => {
            state.loading = false;
            state.podcast = action.payload;
            state.error = null;;
        });
        builder.addCase(getAllPodcast.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });
        // Get all  podcast
        builder.addCase(getPodcastById.pending, (state) => {
            state.loading = true;
            state.podcast = [];
            state.error = null;
        });
        builder.addCase(getPodcastById.fulfilled, (state, action) => {
            state.loading = false;
            state.podcast = action.payload;
            state.error = null;;
        });
        builder.addCase(getPodcastById.rejected, (state) => {
            state.loading = false;
            state.error = true;
        });

    }
});

const { setUploadProgress } = podcastSlice.actions;
export default podcastSlice.reducer;