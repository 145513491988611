import React, { useCallback, useEffect, useRef, useState } from "react";
import { copyright } from "../utils/copyright";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { Button } from "@material-tailwind/react";
// import { verifySignup } from "../redux/apislice/authSlice"; for
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { verifySignup } from "../redux/apislice/authSlice";

const EnterSignupOtp = () => {
    const lable = "KV / FE / VERIFY OTP/ V1 / 16 OCT, 2024";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isVerified, setIsVerified] = useState(false);

    const numberOfDigits = 6;
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const otpBoxReference = useRef([]);
    const [timeLeft, setTimeLeft] = useState(360); // 6 minutes in seconds

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);


        return () => clearInterval(intervalId);

    }, [timeLeft]);

    // Decrypt data using AES decryption
    const decryptData = (encryptedData) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
            2,
            "0"
        )}`;
    };



    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    }


    const decryptedEmail = decryptData(Cookies.get("email_id"))

    const handleVerifyOtp = useCallback(async () => {
        const otpData = otp.join("");
        const toastId = toast.loading("Verifying OTP...");
        try {
            const res = await dispatch(verifySignup({ otp: otpData, email: decryptedEmail }));
            console.log(res);
            if (res.error) {
                console.log(res.error)
                toast.error("Error verifying OTP", { id: toastId });
            } else {
                toast.success("OTP verified successfully", { id: toastId });
                setTimeout(() => {
                    navigate("/login");
                    Cookies.remove("email_id")
                    setIsVerified(true)
                }, 2000);
            }
        } catch (error) {
            console.error("Error verifying OTP: ", error);
            toast.error("Error verifying OTP", { id: toastId });
        }

    }, [dispatch, navigate, otp, decryptedEmail]);

    useEffect(() => {
        // Replace the current history state to disable the back button
        navigate('.', { replace: true, state: { preventBack: true } });

        const handleBeforeUnload = (event) => {
            if (!isVerified) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isVerified, navigate]);


    return (
        <div className="h-full bg-[#2C672E] relative">
            <Toaster position="right-top" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">{lable}</p>
                <p className="text-black text-[9px] mt-[-2px]">{copyright}</p>
            </div>

            <h1 className="px-3 text-[3rem] font-semibold uppercase text-white">
                kv
            </h1>

            <form className="px-6 mt-14">
                <p className="text-white font-semibold text-[28px] md:text-[24px] text-center">
                    OTP Verification
                </p>
                <div className=" p-4 rounded-md">
                    <p className="text-[16px] md:text-[12px] text-white text-center">
                        we've sent an OTP to your email address -
                        <span className="font-bold text-blue-500">
                            {decryptedEmail}
                        </span>
                    </p>
                </div>
                <div className="mt-4">

                    <div className="flex items-center justify-between mt-3">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                required
                                value={digit}
                                maxLength={1}
                                onChange={(e) => handleChange(e.target.value, index)}
                                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                ref={(reference) =>
                                    (otpBoxReference.current[index] = reference)
                                }
                                className="h-12 md:h-10 w-12 md:w-10 text-center text-white bg-transparent border border-[#D9D9D9] rounded-md focus:outline-none focus:ring-2 focus:ring-[#0DD315] focus:border-transparent"
                            />
                        ))}
                    </div>

                </div>
                <div className="mt-4 md:mt-2 flex items-center justify-between">
                    <div className="flex items-center gap-2">

                        <p className={`cursor-pointer text-[16px] md:text-[12px] text-[#0DD315]  font-semibold`}>
                            Resend OTP
                        </p>
                    </div>
                    <p className="cursor-pointer text-[16px] md:text-[12px] text-white font-semibold"> {formatTime(timeLeft)}</p>
                </div>
                <div className="mt-4 md:mt-2 flex items-center justify-between">
                    <Button className="w-full bg-[#0DD315] text-black hover:text-white" onClick={() => handleVerifyOtp()}>
                        Verify OTP
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default EnterSignupOtp