import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL
//home page video list api
export const getHistoryList = createAsyncThunk(
    "getHistoryList",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/video/watch-history/`, config);
        const response = await request.data;
        return response;
    }
);

export const deleteHistoryById = createAsyncThunk(
    "deleteHistoryById",
    async ({ ids }) => {
        const config = getAccessConfig();
        console.log(ids)
        const request = await axios.delete(`${mainUrl}api/video/watch-history/delete/`, { ...config, data: { ids } });
        const response = await request.data;
        return response;
    }
);
export const clearHistory = createAsyncThunk(
    "clearHistory",
    async (data) => {
        const config = getAccessConfig();
        const request = await axios.delete(`${mainUrl}api/video/watch-history/clear/`, config);
        const response = await request.data;
        return response;
    }
);
const watchListSlice = createSlice({
    name: "watchListSlice",
    initialState: {
        isLoading: false,
        historyList: null,
        error: null,
    },
    extraReducers: (builder) => {
        //videoListSlice
        builder.addCase(getHistoryList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getHistoryList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.historyList = action.payload;
            state.error = null;
        });
        builder.addCase(getHistoryList.rejected, (state) => {
            state.isLoading = false;
            state.error = true;
        });
    }
});

export default watchListSlice.reducer;
