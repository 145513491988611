import React, { useState, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";

const MusicCard = ({ showSaved, showDelete, musicData, handleSaveUnsave, handleDelete }) => {
    const [savedMusic, setSavedMusic] = useState({});

    useEffect(() => {
        // Initialize all music items as saved
        const initialSavedState = musicData.reduce((acc, data) => {
            acc[data.id] = true;
            return acc;
        }, {});
        setSavedMusic(initialSavedState);
    }, [musicData]);

    const toggleSaved = async (id) => {
        await handleSaveUnsave(id);
        setSavedMusic((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    return (
        <div className="mt-0">
            {musicData.map((data) => (
                <div className="grid grid-cols-12 gap-x-1 mb-3" key={data.id}>
                    <div className="col-span-2">
                        <img
                            alt={data.singer}
                            src={data.image === null ?
                                "https://cdn.pixabay.com/photo/2021/01/29/08/10/musician-5960112_1280.jpg" : data.image
                            }
                            className="w-14 md:w-10 h-14 md:h-10 rounded-full object-cover"
                        />
                    </div>
                    <div className="col-span-9 ml-[-1px] md:ml-[-5px] mt-[2px] md:mt-0 flex flex-col justify-between">
                        <p className="text-[14px] md:text-[9px] text-white line-clamp-1 leading-[12px]">
                            {data.title}
                        </p>
                        <p className="text-[13px] md:text-[10px] font-bold text-[#4d4d4d]">
                            {data.singer}
                        </p>
                        <div className="flex items-center gap-x-4">
                            <p className="text-[11px] md:text-[8px] text-[#27b129]">
                                {data.plays === 0 ? "not played yet" : `${data.plays} played`}
                            </p>
                            <p className="text-[11px] md:text-[8px] text-[#27b129]">
                                {data.saves === 0 ? "not saved yet" : `${data.saves} saved`}
                            </p>
                        </div>
                    </div>
                    <div className="col-span-1 flex justify-end items-start">
                        {showDelete && (
                            <MdDelete
                                onClick={() => handleDelete(data.id)}
                                className="text-[22px] md:text-[16px] text-[#9b9a9a] hover:text-[#ffffff] transition-all duration-300 cursor-pointer"
                            />
                        )}
                        {showSaved && (
                            savedMusic[data.id] ? (
                                <IoBookmark
                                    onClick={() => toggleSaved(data.id)}
                                    className="text-[22px] md:text-[16px] text-[#9b9a9a] hover:text-[#ffffff] transition-all duration-300 cursor-pointer"
                                />
                            ) : (
                                <IoBookmarkOutline
                                    onClick={() => toggleSaved(data.id)}
                                    className="text-[22px] md:text-[16px] text-[#9b9a9a] hover:text-[#ffffff] transition-all duration-300 cursor-pointer"
                                />
                            )
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MusicCard;