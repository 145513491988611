import React, { Fragment, useEffect, useState, useRef } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoMdSend, IoMdArrowBack, IoMdClose } from "react-icons/io";
import { HiMiniBars3 } from "react-icons/hi2";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import BeatLoader from "react-spinners/BeatLoader";
import { debounce } from "lodash";

import {
    addFeedback,
    helpAllCategories,
    likeUnlikeArticle,
    helpSearchArticles,
    helpAllArticlesList,
    helpFilterArticlesByCategory,
    dislikeRemovedislikeArticle,
} from "../../redux/apislice/help/helpSlice";

const HelpPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data, categories, loading, error } = useSelector((state) => state.help);

    const [open, setOpen] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [openFeedback, setOpenFeedback] = useState(false);
    const [openDisklikeFeedback, setOpenDisklikeFeedback] = useState({});
    const [mainMenuPopup, setMainMenuPopup] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [screenshot, setScreenshot] = useState(null);
    const [likeStatus, setLikeStatus] = useState({});
    const [dislikeStatus, setDislikeStatus] = useState({});
    const fileInputRef = useRef(null);
    const reasonRef = useRef(null);
    const reviewRef = useRef(null);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);

    const handleDislikeClick = (id) => {
        setOpenDisklikeFeedback((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const filteredData = Array.isArray(data) ? data.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    useEffect(() => {
        dispatch(helpAllCategories());
    }, [dispatch]);

    // Initialize likeStatus and dislikeStatus state with the like and dislike values from the data
    useEffect(() => {
        const initialLikeStatus = {};
        const initialDislikeStatus = {};
        data.forEach((item) => {
            initialLikeStatus[item.id] = item.like;
            initialDislikeStatus[item.id] = item.dislike;
        });
        setLikeStatus(initialLikeStatus);
        setDislikeStatus(initialDislikeStatus);
    }, [data]);

    // Handle search term change
    useEffect(() => {
        const debouncedSearch = debounce((term) => {
            if (term) {
                dispatch(helpSearchArticles(term));
            } else {
                dispatch(helpAllArticlesList());
            }
        }, 500);

        debouncedSearch(searchTerm);
        return debouncedSearch.cancel; // Cleanup on unmount
    }, [searchTerm, dispatch]);

    const handleCategoryClick = (categoryId) => {
        dispatch(helpFilterArticlesByCategory(categoryId));
        setMainMenuPopup(false); // Close the menu after selecting a category
    };

    const handleFeedbackSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("message", feedbackMessage);
        if (screenshot) {
            formData.append("screenshot", screenshot);
        }
        dispatch(addFeedback(formData));
        setOpenFeedback(false);
    };

    const handleFileChange = (e) => {
        setScreenshot(e.target.files[0]);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleLikeClick = (articleId) => {
        const article = data.find((item) => item.id === articleId);
        setLikeStatus((prevState) => ({
            ...prevState,
            [articleId]: !prevState[articleId],
        }));
        dispatch(likeUnlikeArticle(articleId));
    };

    const handleDislikeAddRemove = (articleId) => {
        const article = data.find((item) => item.id === articleId);
        if (dislikeStatus[articleId]) {
            dispatch(dislikeRemovedislikeArticle({ articleId }));
            setDislikeStatus((prevState) => ({
                ...prevState,
                [articleId]: false,
            }));
        } else {
            handleDislikeClick(articleId); // Open the popup
        }
    };

    const handleDislikeSubmit = (articleId) => {
        const reason = reasonRef.current.value;
        const review = reviewRef.current.value;

        if (!reason || !review) {
            alert("Both reason and review are required.");
            return;
        }

        const dislikeData = {
            reason,
            review,
        };
        dispatch(dislikeRemovedislikeArticle({ articleId, dislikeData }));
        setDislikeStatus((prevState) => ({
            ...prevState,
            [articleId]: true,
        }));
        handleDislikeClick(articleId); // Close the popup
    };

    return (
        <Fragment>
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Help / V1 / 02 Nov, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="sticky top-0 grid grid-cols-12 items-center px-2 py-[6px] z-10 bg-[#1e1e1e]">
                <div className="col-span-6 flex items-center justify-start gap-3">
                    <IoMdArrowBack
                        onClick={() => navigate(-1)}
                        className="text-white h-5 w-5 cursor-pointer ml-[-3px]"
                    />
                    <p className="text-white text-[16px] font-medium">Help</p>
                </div>
                <div className="col-span-6 flex items-center justify-end gap-3 relative">
                    {mainMenuPopup ?
                        <IoMdClose className="text-white h-5 w-5 cursor-pointer mr-[-1px]" onClick={() => setMainMenuPopup(!mainMenuPopup)} /> :
                        <HiMiniBars3 className="text-white h-5 w-5 cursor-pointer mr-[-1px]" onClick={() => setMainMenuPopup(!mainMenuPopup)} />
                    }

                    {mainMenuPopup &&
                        <div className="absolute top-8 right-[-4px] bg-white z-50 rounded">
                            {categories.map((item, index) => (
                                <p
                                    key={item.id}
                                    className={`text-[15px] md:text-[11px] px-3 md:px-2 py-[5px] cursor-pointer hover:text-[#f75555]
                                        ${index !== data.length - 1 ? 'border-b border-[#e3e3e3]' : ''}`}
                                    onClick={() => handleCategoryClick(item.id)}
                                >
                                    {item.name}
                                </p>
                            ))}
                        </div>
                    }
                </div>
            </div>

            <div className="px-2">
                <input
                    type="text"
                    value={searchTerm}
                    placeholder="Search help"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="px-2 py-[8px] md:py-[4px] text-[16px] md:text-[14px] w-full mt-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                />
            </div>

            <div className="px-2 mt-3">
                {loading ? (
                    <div className="flex w-full justify-center items-center mt-2">
                        <BeatLoader
                            color={"#fff"}
                            loading={true}
                            size={15}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                ) : error ? (
                    <p className="text-center text-[14px] text-red-500">{error}</p>
                ) : filteredData.length > 0 ? (
                    filteredData.map((item) => (
                        <Accordion
                            key={item.id}
                            open={open === item.id}
                            className="mb-2 rounded-lg border border-blue-gray-100 px-4"
                        >
                            <AccordionHeader
                                onClick={() => handleOpen(item.id)}
                                className={`border-b-0 transition-colors text-[15px] md:text-[13px] text-[#dddddd] hover:text-[#cacaca] ${open === item.id ? "text-white hover:!text-[#e5e5e5]" : ""}`}
                            >
                                {item.title}
                            </AccordionHeader>

                            <AccordionBody className={`pt-0 mt-[-4px] ${openDisklikeFeedback[item.id] ? "h-[20vh]" : ""} text-base font-normal text-[14px] md:text-[12px] relative`}>
                                {item.content}
                                <div className="flex flex-col items-center justify-center gap-y-1 mt-2">
                                    <p className="text-[12px] text-gray-300">Did you find this helpful?</p>
                                    <div className="flex items-center justify-center gap-2">
                                        <div
                                            onClick={() => handleLikeClick(item.id)}
                                            className={`${likeStatus[item.id] ? "bg-green-600 hover:bg-green-500" : "bg-white"} 
                                                rounded-full p-1 cursor-pointer`
                                            }
                                        >
                                            <AiFillLike className={`${likeStatus[item.id] ? "text-white" : "text-green-600"}`} />
                                        </div>
                                        <div
                                            onClick={() => handleDislikeAddRemove(item.id)}
                                            className={`${dislikeStatus[item.id] ? "bg-red-600 hover:bg-red-500" : "bg-white"} 
                                                rounded-full p-1 cursor-pointer`
                                            }
                                        >
                                            <AiFillDislike className={`${dislikeStatus[item.id] ? "text-white" : "text-red-600"}`} />
                                        </div>
                                    </div>
                                </div>

                                {openDisklikeFeedback[item.id] &&
                                    <div className="absolute top-1 right-0 bg-white w-[80%] p-2 rounded">
                                        <p className="text-[9px] font-bold text-black">Add your review</p>
                                        <p className="text-[7px] text-black leading-[8px] mt-1">
                                            We will fix our article according to your suggestion.
                                            Please select from the options or write your suggestions.
                                        </p>
                                        <select
                                            name="reason"
                                            className="border border-[#bebebe] rounded text-black text-[10px] w-full py-[2px] my-1 outline-none"
                                            defaultValue=""
                                            ref={reasonRef}
                                        >
                                            <option value="" disabled>Select a reason</option>
                                            <option value="Text1">Text1</option>
                                            <option value="Data is not clear">Data is not clear</option>
                                        </select>
                                        <textarea
                                            rows={2}
                                            name="review"
                                            placeholder="Type your feedback"
                                            className="w-full mt-[2px] px-1 py-[2px] resize-none text-black placeholder:text-[10px] text-[10px] border border-[#bebebe] rounded bg-[#ffffff] focus:outline-none"
                                            ref={reviewRef}
                                        />
                                        <div className="flex items-center justify-evenly">
                                            <button
                                                onClick={() => handleDislikeSubmit(item.id)}
                                                className="text-[8px] w-10 border border-gray-500 rounded py-[2px]"
                                            >
                                                Send
                                            </button>
                                            <button
                                                onClick={() => handleDislikeClick(item.id)}
                                                className="text-[8px] w-10 border-none bg-[#0DD315] text-white rounded py-[2px]"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                }
                            </AccordionBody>
                        </Accordion>
                    ))
                ) : (
                    <p className="text-center text-[14px] text-gray-500">No help topics found.</p>
                )}
            </div>

            <div className="my-4 px-2">
                <p className="text-[16px] md:text-[14px] text-white">
                    Give Feedback
                </p>

                {!openFeedback &&
                    <div
                        onClick={() => setOpenFeedback(true)}
                        className="bg-white py-[8px] md:py-[6px] px-1 mt-1 flex items-center justify-end rounded"
                    >
                        <IoMdSend className="text-[20px] md:text-[18px] cursor-pointer" />
                    </div>
                }

                {openFeedback &&
                    <form className="bg-white p-2 mt-2" onSubmit={handleFeedbackSubmit}>
                        <div className="flex justify-end">
                            <IoMdClose
                                onClick={() => setOpenFeedback(false)}
                                className="text-[18px] text-[#2a2a2a] hover:text-[#d84141] cursor-pointer font-bold"
                            />
                        </div>
                        <textarea
                            type="text"
                            rows={5}
                            placeholder="Type your feedback"
                            value={feedbackMessage}
                            onChange={(e) => setFeedbackMessage(e.target.value)}
                            className="w-full mt-[2px] px-2 py-2 resize-none text-white text-[14px] rounded border-none bg-[#2a2a2a] focus:outline-none"
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="hidden"
                        />
                        <div className="flex items-center justify-between mt-2">
                            <button
                                type="button"
                                onClick={handleUploadClick}
                                className="w-[9rem] md:w-[8rem] bg-[#2a2a2a] rounded text-white text-[14px] md:text-[12px] py-2 md:1"
                            >
                                Upload Screenshot
                            </button>
                            <button
                                type="submit"
                                className="w-[9rem] md:w-[8rem] bg-[#2a2a2a] rounded text-white text-[14px] md:text-[12px] py-2 md:1"
                            >
                                Send
                            </button>
                        </div>
                    </form>
                }
            </div>
        </Fragment>
    );
};

export default HelpPage;