import React, { Fragment, useRef, useState } from "react";
import Carousel from "react-multi-carousel";

import { RiTvFill } from "react-icons/ri";
import { LuStarOff } from "react-icons/lu";
import { BiSolidHide } from "react-icons/bi";
import { FaVolumeMute } from "react-icons/fa";
import { MdOutlineReport } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3.5,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3.5,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3.5,
        slidesToSlide: 1
    }
};

const responsivePost = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
        slidesToSlide: 1
    }
}

const SubscribedCard = ({ subscribedData, subVideos, handleClickSub, subscribeList, subLoading, handleHideChannel }) => {
    const navigate = useNavigate();
    const [menuId, setMenuId] = useState(null);
    const menuRef = useRef(null);

    const toggleMenu = (id) => {
        setMenuId(menuId === id ? null : id);
    };
    console.log(subscribedData, "subscribedData", subVideos)
    return (
        <Fragment>
            {subscribedData?.length > 0 && !subLoading &&
                <div className="px-1 pb-2 border-b-[0.4px] border-[#3e3e3e]">
                    <Carousel
                        ssr={true}
                        arrows={false}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        className="select-none"
                    >
                        {subscribedData?.map((item) => (
                            <div className="mx-1" key={item.id}>
                                <img
                                    alt="post"
                                    src={item.channel.profile_picture}
                                    className="w-full h-[3.5rem] object-cover rounded-[4px] cursor-pointer"
                                />
                            </div>
                        ))}
                    </Carousel>
                </div>}
            {subscribedData?.length === 0 && !subLoading && (
                <p className="text-[#4d4d4d] px-1 text-[14px] mt-2 flex justify-center items-center">No Subscribed Channels</p>
            )}
            {/* Subscribed Card */}
            {!subVideos.detail && !subLoading && Object.values(subVideos)?.map((data) => (
                <div className="px-2 grid grid-cols-12 gap-x-1 py-2 border-b-[0.4px] border-[#3e3e3e]" key={data?.subscription_id}>
                    <div className="col-span-2 relative">
                        <img
                            alt={data.channel?.full_name}
                            src={data.channel?.profile_picture}
                            className="w-[3rem] h-[3rem] object-cover rounded-[4px] cursor-pointer"
                        />
                    </div>
                    <div className="col-span-9 ml-1">
                        <p className="text-[15px] mt-[-3px] font-bold text-[#ffffff] line-clamp-1 cursor-pointer">
                            {data.channel?.full_name}
                        </p>

                        <p className="text-[10px] mt-[3px] text-[#8f8f8f] leading-3 line-clamp-2">
                            {data.channel?.description}
                        </p>
                    </div>
                    <div className="col-span-1 flex justify-end relative">
                        <p
                            onClick={() => toggleMenu(data?.subscription_id)}
                            className="text-[14px] text-[#ffffff] -mt-[6px] font-semibold cursor-pointer"
                        >
                            : :
                        </p>
                        {menuId === data?.subscription_id && (
                            <div ref={menuRef} className="absolute top-4 right-0 bg-white px-2 py-1 rounded-lg z-[1]">
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <RiTvFill className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap" onClick={() => navigate(`/otheruser/account/${data?.channel.id}`)}>View Channel</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <FaVolumeMute className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Mute</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <LuStarOff className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap" onClick={() => handleClickSub(data?.channel?.id)}> {subscribeList?.results?.some((item) => item.channel.id === data.channel.id) ? "Unsubscribe" : "Subscribe"}</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <BiSolidHide className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap" onClick={() => handleHideChannel(data?.channel?.id)}>Hide</p>
                                </div>
                                <div className="flex items-center pb-[3px] gap-2 text-[#3e3e3e] hover:text-[#00ff48e4]">
                                    <MdOutlineReport className="text-[14px]" />
                                    <p className="text-[11px] cursor-pointer whitespace-nowrap">Report</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-12 mt-2 px-3">
                        {data?.videos?.length && <Carousel
                            ssr={true}
                            arrows={false}
                            infinite={true}
                            swipeable={true}
                            draggable={true}
                            responsive={responsivePost}
                            className="select-none"
                        >
                            {data?.videos?.map((item) => (
                                <div className="flex flex-col px-1" key={item.id}>
                                    <img
                                        alt="videos"
                                        src={item.thumbnail}
                                        onClick={() => navigate(`/video/${item.id}`)}
                                        className="w-full h-[3.5rem] object-cover rounded-[4px] cursor-pointer"
                                    />
                                    <p className="text-[8px] text-[#8f8f8f] leading-[9px] line-clamp-2 mt-1">
                                        {item.title}
                                    </p>
                                </div>
                            ))}
                        </Carousel>}
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

export default SubscribedCard;
