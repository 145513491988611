import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../../config";

const mainUrl = REACT_APP_MAIN_URL;

export const createPodcastCommentReply = createAsyncThunk(
    "createPodcastCommentReply",
    async ({ data, commentId }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/comments/${commentId}/replies/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const getPodcastCommentReplies = createAsyncThunk(
    "getPodcastCommentReplies",
    async (commentId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.get(`${mainUrl}api/podcast/comment/${commentId}/replies/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updatePodcastCommentReply = createAsyncThunk(
    "updatePodcastCommentReply",
    async ({ replyId, data }, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.patch(`${mainUrl}/api/podcast/replies/${replyId}/`, data, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deletePodcastCommentReply = createAsyncThunk(
    "deletePodcastCommentReply",
    async (replyId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.delete(`${mainUrl}api/podcast/replies/${replyId}/`, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const likePodcastCommentReply = createAsyncThunk(
    "likePodcastCommentReply",
    async (replyId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/replies/${replyId}/like/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const dislikePodcastCommentReply = createAsyncThunk(
    "dislikePodcastCommentReply",
    async (replyId, { rejectWithValue }) => {
        try {
            const config = getAccessConfig();
            const request = await axios.post(`${mainUrl}api/podcast/replies/${replyId}/dislike/`, {}, config);
            const response = await request.data;
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const podcastReplySlice = createSlice({
    name: "podcastSlice",
    initialState: {
        replyLoading: false,
        podcastCommentReplies: [],
        error: null,
    },
    extraReducers: (builder) => {
        // Create post
        builder.addCase(createPodcastCommentReply.pending, (state) => {
            state.replyLoading = true;
            state.error = null;
        });
        builder.addCase(createPodcastCommentReply.fulfilled, (state, action) => {
            state.replyLoading = false;
            state.error = null;
        });
        builder.addCase(createPodcastCommentReply.rejected, (state) => {
            state.replyLoading = false;
            state.error = true;
        });
        // Get all  podcast
        builder.addCase(getPodcastCommentReplies.pending, (state) => {
            state.replyLoading = true;
            state.error = null;
        });
        builder.addCase(getPodcastCommentReplies.fulfilled, (state, action) => {
            state.replyLoading = false;
            state.podcastCommentReplies = action.payload;
            state.error = null;
        });
        builder.addCase(getPodcastCommentReplies.rejected, (state) => {
            state.replyLoading = false;
            state.error = true;
        });
    }
});

export default podcastReplySlice.reducer;