import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL

export const uploadVideo = createAsyncThunk(
    "uploadVideo",
    async () => {
        const config = getAccessConfig();
        const request = await axios.post(`${mainUrl}api/videos/`, config);
        const response = await request.data;
        return response;
    }
);

const uploadVideoSlice = createSlice({
    name: "profileDetails",
    initialState: {
        loading: false,
        videoData: null,
        error: null,
    },
    extraReducers: (builder) => {
        //profileDetails
        builder.addCase(uploadVideo.pending, (state) => {
            state.loading = true;
            state.videoData = null;
            state.error = null;
        });
        builder.addCase(uploadVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.videoData = action.payload;
            state.error = null;
        });
        builder.addCase(uploadVideo.rejected, (state) => {
            state.loading = false;
            state.videoData = null;
            state.error = true;
        });
    }
});

export default uploadVideoSlice.reducer;
