import axios from "axios";
import { REACT_APP_MAIN_URL } from "../../utils/mainUrl";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAccessConfig from "../config";

const mainUrl = REACT_APP_MAIN_URL
//home page video list api
export const trendingVideoList = createAsyncThunk(
    "trendingVideoList",
    async () => {
        const config = getAccessConfig();
        const request = await axios.get(`${mainUrl}api/videos_trending/`, config);
        const response = await request.data;
        return response;
    }
);

const trendingVideoListSlice = createSlice({
    name: "videoListSlice",
    initialState: {
        isLoading: false,
        trendingVideoData: null,
        error: null,
    },
    extraReducers: (builder) => {
        // get trendingVideoData 
        builder.addCase(trendingVideoList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(trendingVideoList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.trendingVideoData = action.payload;
            state.error = null;
        });
        builder.addCase(trendingVideoList.rejected, (state) => {
            state.isLoading = false;
            state.trendingVideoData = null;
            state.error = true;
        });
    }
});

export default trendingVideoListSlice.reducer;
