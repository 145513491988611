import React, { Fragment, useEffect, useRef, useState } from "react";
import { copyright } from "../../utils/copyright";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux"; // Import useDispatch
import { createClips, setUploadingData } from "../../redux/apislice/clips/clipsSlice"; // Import your thunk
import { setUploadProgress } from "../../redux/apislice/clips/clipsSlice";

import { Line } from "rc-progress";
import { IoTimer } from "react-icons/io5";
import toast, { Toaster } from "react-hot-toast";
import { Typography } from "@material-tailwind/react";
import { MdAddPhotoAlternate, MdMovieFilter } from "react-icons/md";
// import RecordRTC from "recordrtc";

const CreateClips = () => {
    const videoRef = useRef(null);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch(); // Initialize useDispatch
    const [videoSrc, setVideoSrc] = useState(null);
    const [videoFile, setVideoFile] = useState(null); // Store the actual video file
    const [description, setDescription] = useState(""); // State to handle description

    const [isRecording, setIsRecording] = useState(false);
    // const [videoBlob, setVideoBlob] = useState(null);
    const [stream, setStream] = useState(null);
    const mediaRecorderRef = useRef(null);
    const videoChunksRef = useRef(null);
    const streamRef = useRef(null);

    const { progress, loading } = useSelector((state) => state.clips);
    const [isRecordingTime, setIsRecordingTime] = useState(0);

    useEffect(() => {
        // setVideoSrc(null); // Reset the video source
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current && !videoSrc) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play().catch(error => console.error("Error playing the video: ", error));
                    };
                }
            } catch (err) {
                console.error("Error accessing the camera: ", err);
            }
        };

        if (!videoSrc) {
            startCamera();
        }

        const videoElement = videoRef.current;
        return () => {
            if (videoElement && videoElement.srcObject) {
                const stream = videoElement.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [videoSrc]);

    useEffect(() => {
        let interval;
        if (isRecording) {
            interval = setInterval(() => {
                setIsRecordingTime((prev) => prev + 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRecording]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);

    // const handleStartRecording = async () => {
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    //         setStream(stream);
    //         mediaRecorderRef.current = new MediaRecorder(stream);
    //         mediaRecorderRef.current.ondataavailable = (event) => {
    //             if (event.data.size > 0) {
    //                 // setVideoBlob(event.data);
    //                 const url = URL.createObjectURL(event.data);
    //                 if (videoRef.current) {
    //                     videoRef.current.src = url;
    //                     const videoElement = document.createElement('video');
    //                     videoElement.src = url;
    //                     setVideoSrc(url);
    //                 }
    //             }
    //         };
    //         mediaRecorderRef.current.start();
    //         setIsRecording(true);
    //     } catch (error) {
    //         console.error('Could not start video source:', error);
    //         toast.error('Could not start video source. Please check your webcam permissions and try again.');
    //     }
    // };

    // const blobUrlToFile = async (blobUrl, fileName) => {
    //     const response = await fetch(blobUrl);
    //     const blob = await response.blob();
    //     return new File([blob], fileName, { type: blob.type });
    // };


    // const handleStopRecording = async () => {
    //     mediaRecorderRef.current.stop();
    //     stream.getTracks().forEach(track => track.stop());
    //     setIsRecording(false);
    //     setIsRecordingTime(0);



    //     // Convert Blob URL to File
    //     if (videoRef.current && videoRef.current.src) {
    //         const file = await blobUrlToFile(videoRef.current.src, 'recorded-video.webm');
    //         setVideoFile(file);
    //     }
    // };




    const handleStartRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setStream(stream);
            streamRef.current = stream;
            mediaRecorderRef.current = new MediaRecorder(stream);
            videoChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    videoChunksRef.current.push(event.data);
                }
            };

            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(videoChunksRef.current, { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                if (videoRef.current) {

                    const videoElement = document.createElement('video');
                    videoElement.src = url;
                    // videoRef.current.src = url;
                }

            };

            mediaRecorderRef.current.start();
            setIsRecording(true);

        } catch (error) {
            console.error('Could not start video source:', error);
            toast.error('Could not start video source. Please check your webcam permissions and try again.');
        }
    };

    const blobUrlToFile = async (blobUrl, fileName) => {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
    };

    const handleStopRecording = async () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
        setIsRecording(false);

        // Convert Blob URL to File
        if (videoRef.current && videoRef.current.src) {
            const file = await blobUrlToFile(videoRef.current.src, 'recorded-video.webm');
            setVideoFile(file);
            setVideoSrc(videoRef.current.src);
        }
    };


    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('video/')) {
            const videoURL = URL.createObjectURL(file);
            const videoElement = document.createElement('video');

            videoElement.src = videoURL;
            videoElement.onloadedmetadata = () => {
                if (videoElement.duration <= 60) {
                    setVideoSrc(videoURL);
                    setVideoFile(file);
                } else {
                    toast.error('The video must be at maximum 1 minute long.');
                }
                URL.revokeObjectURL(videoURL);
            };
        }
    };
    const handleCancel = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setVideoSrc(null);
        // setVideoBlob(null);
        setVideoFile(null);
        setIsRecording(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (videoFile && description) {
            const formData = new FormData();
            formData.append('video', videoFile);
            formData.append('description', description);

            dispatch(setUploadingData({ videoData: videoSrc, videoDescription: description }));
            const res = await dispatch(createClips(formData));
            if (res?.payload?.message) {
                toast.success(res?.payload?.message);
                setTimeout(() => {
                    navigate("/home");
                    dispatch(setUploadProgress(0));
                }, [2000])
            }
            else {
                toast.error("Failed to upload clip.");
            }
        } else {
            toast.error("Please provide both video and description.");
            console.log("Please provide both video and description.");
        }
    };







    // const [isRecording, setIsRecording] = useState(false);
    // const [videoSrc, setVideoSrc] = useState(null);
    // const [stream, setStream] = useState(null);
    // const videoRef = useRef(null);
    // const mediaRecorderRef = useRef(null);

    // // Initialize the camera stream when the component mounts
    // useEffect(() => {
    //     const getVideoStream = async () => {
    //         try {
    //             const mediaStream = await navigator.mediaDevices.getUserMedia({
    //                 video: true,
    //                 audio: true,
    //             });
    //             setStream(mediaStream);

    //             // Only set the stream to the video element if available
    //             if (videoRef.current) {
    //                 videoRef.current.srcObject = mediaStream;
    //             }
    //         } catch (err) {
    //             console.error("Error accessing media devices.", err);
    //         }
    //     };

    //     getVideoStream();

    //     // Clean up the stream when the component unmounts
    //     return () => {
    //         if (stream) {
    //             stream.getTracks().forEach((track) => track.stop());
    //         }
    //     };
    // }, []); // Run once when the component mounts

    // const startRecording = () => {
    //     if (!stream) return;

    //     const recorder = new RecordRTC(stream, {
    //         type: "video",
    //     });
    //     recorder.startRecording();
    //     setIsRecording(true);
    //     mediaRecorderRef.current = recorder;
    // };

    // const stopRecording = () => {
    //     mediaRecorderRef.current.stopRecording(() => {
    //         const blob = mediaRecorderRef.current.getBlob();
    //         const videoURL = URL.createObjectURL(blob);
    //         setVideoSrc(videoURL);
    //         setIsRecording(false);
    //         setStream(null);
    //     });
    // };

    // const uploadVideo = async () => {
    //     const blob = mediaRecorderRef.current.getBlob();
    //     const formData = new FormData();
    //     formData.append("video", blob);

    //     try {
    //         await fetch("YOUR_API_URL", {
    //             method: "POST",
    //             body: formData,
    //         });
    //         alert("Video uploaded successfully!");
    //     } catch (err) {
    //         console.error("Error uploading video.", err);
    //     }
    // };


    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file && file.type.startsWith('video/')) {
    //         const videoURL = URL.createObjectURL(file);
    //         const videoElement = document.createElement('video');

    //         videoElement.src = videoURL;
    //         videoElement.onloadedmetadata = () => {
    //             if (videoElement.duration <= 60) {
    //                 setVideoSrc(videoURL);
    //                 // setVideoFile(file);
    //             } else {
    //                 toast.error('The video must be at maximum 1 minute long.');
    //             }
    //             URL.revokeObjectURL(videoURL);
    //         };
    //     }
    // };






    return (
        <Fragment>
            <Toaster position="right-top" />
            <div className="bg-[#d9d9d9] text-center">
                <p className="text-red-600 text-[9px] uppercase font-bold">
                    KV / FE / Create Clips / V3 / 28 Sep, 2024
                </p>
                <p className="text-black text-[9px] mt-[-2px]">
                    {copyright}
                </p>
            </div>

            <div className="relative">
                <div className="grid grid-cols-12 items-center bg-white px-2">
                    <div className="col-span-3 flex items-center justify-start">
                        <h1
                            onClick={() => navigate("/home")}
                            className="text-[1.6rem] font-bold uppercase text-black cursor-pointer"
                        >
                            kv
                        </h1>
                    </div>
                    <div className="col-span-6 flex items-center justify-center">
                        <p className="font-semibold text-[16px]">Create Clips</p>
                    </div>
                    <div className="col-span-3 flex items-center justify-end">
                        <p>Clips</p>
                    </div>
                </div>
            </div>

            <div className={`w-full h-screen md:h-[67vh] relative ${videoSrc && "flex flex-col items-center mt-4"}`}>

                {isRecording && (
                    <div className="col-span-8 absolute z-50 flex items-center justify-center">
                        <p className="text-white">{formatTime(isRecordingTime)}</p>
                    </div>
                )}

                <video
                    muted
                    loop
                    autoPlay
                    ref={videoRef}
                    disablePictureInPicture
                    src={videoSrc}
                    controls={false}
                    className={`object-cover ${videoSrc ? " h-[24rem] md:h-[16rem]" : "h-full w-full"} bg-black`}
                />

                {/* {

                    videoSrc && <video src={videoSrc} controls />
                } */}

                {videoSrc && (
                    <form onSubmit={handleSubmit} className="mt-4 px-2 w-full">
                        <div className="mb-1">
                            <textarea
                                rows={3}
                                value={description}
                                placeholder="Add a description..."
                                onChange={(e) => setDescription(e.target.value)}
                                className="w-full p-2 border outline-none resize-none rounded-md placeholder:text-[14px]"
                            />
                        </div>
                        {!loading && <div className="flex justify-between gap-6">
                            <button
                                onClick={() => handleCancel()}
                                className="bg-gray-800 text-white w-full px-4 py-1 text-[14px] rounded-md hover:bg-gray-900 transition-all duration-300"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-gray-800 text-white w-full px-4 py-1 text-[14px] rounded-md hover:bg-gray-900 transition-all duration-300"
                            >
                                Upload
                            </button>
                        </div>}
                        {progress > 0 && loading && <div className="w-full">
                            <div className="mb-2 flex items-center justify-between gap-4">
                                <Typography className="text-white" color="blue-gray" variant="h6">
                                    Completed
                                </Typography>
                                <Typography className="text-white" color="blue-gray" variant="h6">
                                    {progress}%
                                </Typography>
                            </div>
                            <Line percent={progress} strokeWidth={4} strokeLinecap="round" strokeColor="rgb(67,60,187)" trailColor="#D9D9D9" style={{ height: "10px", width: "100%" }} />
                        </div>}
                    </form>
                )}

                <div className="absolute bottom-2 w-full grid grid-cols-12 px-2 z-10">
                    <div className="col-span-2 flex items-center justify-start">
                        {!videoSrc &&
                            <MdAddPhotoAlternate
                                onClick={handleFileInputClick}
                                className="text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer"
                            />
                        }
                        <input
                            type="file"
                            accept="video/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                    </div>

                    {!videoSrc && (
                        <>
                            <div className="col-span-8 flex items-center justify-center relative">
                                <div
                                    onClick={isRecording ? handleStopRecording : handleStartRecording}
                                    className="h-10 w-10 rounded-full border-[2px] cursor-pointer border-white bg-red-600 hover:bg-[#c73737]"
                                />
                                <MdMovieFilter className="absolute right-8 text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                                <IoTimer className="absolute right-0 text-[25px] bg-white hover:text-green-500 p-[5px] rounded-full cursor-pointer" />
                            </div>
                            <div className="col-span-2 flex items-center justify-end">
                                <button
                                    className="text-[12px] bg-white hover:text-green-500 px-2 py-[2px] rounded-full font-semibold"
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default CreateClips;
