import React, { useState } from "react"
import { commentData } from "../../data"
import { IoClose, IoSend } from "react-icons/io5";
import { IoMdHeart, IoMdHeartDislike } from "react-icons/io";

const Comment = ({handleComment}) => {
    const [openReplyId, setOpenReplyId] = useState(null);
    const [comment, setComment] = useState("");

    const toggleReplies = (id) => {
        setOpenReplyId((prev) => (prev === id ? null : id));
    };

    return (
        <div className="absolute w-full overflow-y-scroll bottom-[7.1rem] h-[60vh] bg-black rounded-t-2xl">
            <div className="px-2 sticky top-0 bg-black z-10 flex items-center justify-between pt-2 pb-1 border-b-[0.4px] border-[#262626]">
                <div className="flex items-center gap-2">
                    <p className="text-white text-[14px]">Comments</p>
                    <p className="text-[#727272] text-[12px]">16</p>
                </div>
                <IoClose
                    onClick={handleComment}
                    className="text-white text-[20px] cursor-pointer"
                />
            </div>
            {commentData.map((data) => (
                    <div className="grid grid-cols-12 bg-[#242424] rounded-lg p-2 my-1 mx-1" key={data.id}>
                        <div className="col-span-2">
                            <img
                                alt={data.name}
                                src={data.image}
                                className="h-7 w-7 rounded-full object-cover cursor-pointer"
                            />
                        </div>
                        <div className="col-span-10 -ml-3">
                            <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                            <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                {data.message}
                            </p>
                            <div className="flex items-center mt-1">
                                <p className="text-cyan-500 text-[10px] font-semibold cursor-pointer">Reply</p>
                                <IoMdHeart className="h-3 w-3 ml-4 text-white cursor-pointer hover:text-lime-500" />
                                <IoMdHeartDislike className="h-3 w-3 ml-2 text-white cursor-pointer hover:text-red-600" />
                                <p 
                                    onClick={() => toggleReplies(data.id)}
                                    className="text-light-blue-900 hover:text-light-blue-800 text-[10px] ml-6 font-semibold cursor-pointer" 
                                >
                                    {openReplyId === data.id ? "Hide" : "View"} Replies 
                                </p>
                            </div>
                            {openReplyId === data.id &&
                                <div className="grid grid-cols-12 mt-2">
                                    <div className="col-span-2">
                                        <img
                                            alt={data.name}
                                            src={data.image}
                                            className="h-7 w-7 rounded-full object-cover cursor-pointer"
                                        />
                                    </div>
                                    <div className="col-span-10">
                                        <div className="flex items-center gap-x-4">
                                            <p className="text-white text-[12px] font-semibold mt-[-2px]">{data.name}</p>
                                            <p className="text-[10px] text-[#636363]">{data.daysAgo} Days Ago</p>
                                        </div>
                                        <p className="text-[#b2b2b2] text-[10px] leading-[12px]">
                                            {data.message}
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            <div className="px-2 sticky bottom-0 bg-black py-2 border-t-[0.4px] border-[#262626]">
                <div className="flex items-center justify-between gap-2">
                    <img
                        alt="account"
                        className="h-7 w-7 rounded-full object-cover bg-black cursor-pointer"
                        src="https://cdn.pixabay.com/photo/2017/09/23/11/43/football-2778583_1280.jpg"
                    />
                    <input
                        type="text"
                        value={comment}
                        placeholder="Add a comment..."
                        onChange={(e) => setComment(e.target.value)}
                        className="w-full bg-[#1e1e1e] text-white text-[14px] px-2 py-[6px] rounded focus:outline-none"
                    />
                    {comment.length > 0 && (
                        <IoSend className="text-white text-[25px] cursor-pointer" />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Comment